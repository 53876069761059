<template>
    <div class="container">
        <div class="or-spacer">
            <div class="mask"></div>
            <span>
                <i><slot></slot></i>
            </span>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    horizontal: { required: false, type: Boolean, default: false }
  }
}
</script>

<style scoped>
.or-spacer {
  display: block;
  height: 100%;
  position: relative;
}
.or-spacer .mask {
  overflow: hidden;
  height: 2px;
  transform: rotate(90deg);
}
.or-spacer .mask:after {
  content: '';
  display: block;
  border: 3px solid #ccc;
}
.or-spacer span {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 100%;
  margin-bottom: - 25px;
  left: 50%;
  margin-left: - 25px;
  top: 100px;
  border-radius: 100%;
  background: #eee;
}
.or-spacer span i {
  display: inline-block;
  border-radius: 100%;
  text-align: center;
  width: 100%;
  line-height: 50px;
  font-style: normal;
  color: #999;
}

</style>