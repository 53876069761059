import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        all: [],
        section: null,
        loading: false,
        courseSection: null,
        schedules: []
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET_SECTION: (state, section) => state.section = section,
        SET_SECTIONS: (state, sections) => state.all = sections,
        DROP_SECTION: (state, index) => state.pagination.data.splice(index, 1),
        TOGGLE_LOADING: (state) => state.loading = !state.loading,
        SET_COURSE_SECTION: (state, courseSection) => state.courseSection = courseSection,
        SET_SCHEDULES: (state, schedules) => state.schedules = schedules
    },
    actions: {
        fetchAll({ commit }, query = {}) {
            commit('TOGGLE_LOADING')
            return this.$repository.sections
                .index(query)
                .then(({ data }) => {
                    commit(query?.limit === 0 ? 'SET_SECTIONS' : 'SET_PAGINATION', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        fetch({ commit }, id) {
            commit('TOGGLE_LOADING')
            return this.$repository.sections
                .show(id)
                .then(({ data }) => {
                    commit('SET_SECTION', data)
                    return data
                }).finally(() => commit('TOGGLE_LOADING'))
        },
        fetchScehdules({ commit }, {id, filters = {}}) {
            return this.$repository.sections
                .schedules(id, filters)
                .then(({ data }) => {
                    commit('SET_SCHEDULE', data)
                })
        },
        store({ commit }, section) {
            commit('TOGGLE_LOADING')
            return this.$repository.sections
                .store(section)
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado la sección con éxito`,

                    })

                    return data
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        update({ commit }, section) {
            commit('TOGGLE_LOADING')
            return this.$repository.sections
                .update(section.id, section)
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        message: `Se ha actualizado la sección con éxito`,

                    })
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        delete({ commit, state }, id) {
            Vue.prototype.$confirm({
                message: `¿Desea eliminar esta sección?`,
                button: {
                    no: 'No',
                    yes: 'Si'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$repository.sections
                            .delete(id)
                            .then(() => {
                                const index = state.pagination.data.findIndex(section => section.id === id)
                                commit('DROP_SECTION', index)
                            })
                    }
                }
            })
        },
        assignTeacherToSectionCourse({ commit }, { section_id, course_id, teacher_id }) {
            commit('TOGGLE_LOADING')
            return this.$repository.sections
                .course(course_id)
                .assignTeacher(section_id, { teacher_id })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        fetchCourseSections({ commit }, { section_id, course_id, filters = {} }) {
            commit('TOGGLE_LOADING')
            return this.$repository.courseSections
                .show(section_id, course_id, filters)
                .then((res) => {
                    commit('SET_COURSE_SECTION', res.data)
                    return res
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        schedule({ commit }, { id, filters = {}}) {
            commit('TOGGLE_LOADING')
            this.$repository.sections
                .schedules(id, filters)
                .then(({ data }) => {
                    commit('SET_SCHEDULES', data)
                })
                .finally(() => commit('TOGGLE_LOADING'))
        }
    },
    getters: {
        columns: () => [],
        detailColumns: (_, __, ___, { lang }) => [
            { title: lang.section.table.code, key: 'code' },
            { title: lang.section.table.title, key: 'title' },
            { title: lang.section.table.teachers, slot: 'teacher' },
            { title: lang.section.table.actions.title, slot: 'actions', }
        ],
    }
}
