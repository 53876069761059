<template>
    <div class="sim-button-group">
        <slot />
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.sim-button-group {
  display: flex;
}
.sim-button-group .sim-button:not(:first-child):not(:last-child):not(:only-child) {
  border-radius: 0;
}
.sim-button-group .sim-button:first-child:not(:only-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.sim-button-group .sim-button:last-child:not(:only-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

</style>