import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [...routes,
        {
            path: '*',
            name: 'error',
            component: () => import(/* webpackChunkName: "error" */ '../views/404.vue'),
        }
    ]
})

export default router
