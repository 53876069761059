export default (val, positive = 'Yes', negative = 'No', other = null) => {
  if (val === true) {
    return positive
  }

  if (val === false) {
    return negative
  }

  return other ?? positive
}