import {Authenticated, AllowedForTypes} from '@/router/middlewares';

const base = {
    index: { url: () => ({ name: 'preinscriptions' }), title: 'Listado de inscripciones'},
    create: { url: () => ({ name: 'create_preinscription' }), title: 'Crear inscripción'},
    edit: { url: (route) => route, title: 'Editar inscripción' }
}


export default ({ admin, lowAdmin }) => [
    {
        path: `/inscriptions`,
        name: 'preinscriptions',
        component: () => import(/* webpackChunkName: "preinscriptions" */ '../../views/preinscriptions/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, lowAdmin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title }
            ]
        }
    },
    {
        path: '/inscriptions/create',
        name: 'create_preinscription',
        component: () => import(/* webpackChunkName: "create_preinscription" */ '../../views/preinscriptions/create.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
                { route: base.create.url, text: () => base.create.title }
            ],
        }
    },
    {
        path: '/inscriptions/:preinscription_id',
        name: 'preinscription',
        component: () => import(/* webpackChunkName: "preinscription" */ '../../views/preinscriptions/preinscription.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, lowAdmin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
                { route: (route) => route, text: ({ store: { state: { preinscriptions: { current } } } }) => current?.id ? `${current.first_name} ${current.last_name}` : '', loading: ({ store: { state: { preinscriptions: { loading } } } }) => loading }
            ]
        }
    },
    {
        path: '/reinscriptions',
        name: 'reinscriptions.index',
        component: () => import('../../views/reinscriptions/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, lowAdmin])],
            breadcrumbs: [
                { route: base.index.url, text: () => 'Reinscripciones' },
            ]
        }
    },
    {
        path: '/reinscriptions/create',
        name: 'reinscriptions.create',
        component: () => import(/* webpackChunkName: "create_preinscription" */ '../../views/reinscriptions/create.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: base.index.url, text: () => 'Reinscripciones' },
                { route: base.create.url, text: () => 'Crear' }
            ],
        }
    },
    {
        path: '/reinscriptions/:id',
        name: 'reinscriptions.edit',
        component: () => import(/* webpackChunkName: "create_preinscription" */ '../../views/reinscriptions/create.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: base.index.url, text: () => 'Reinscripciones' },
                { route: base.create.url, text: () => 'Editar' }
            ],
        }
    },
]
