Array.prototype.first = function() {
    if (this.length > 0) {
        return this[0]
    }

    return null
}

Array.prototype.last = function() {
    if (this.length > 0) {
        return this[this.length - 1]
    }

    return null
}

Array.prototype.toggle = function (item, comparator, replacing) {
    const func = comparator ?? (() => this.findIndex($0 => $0 === item))
    
    let foundIndex = func(this.findIndex)
    if (foundIndex >= 0) {
        this.splice(foundIndex, 1, replacing)
    } else {
        this.push(item)
    }
}

Array.prototype.uniqueBy = function(reduce) {
    return this.filter(($0, index, self) => self.findIndex($1 => reduce($0, $1, index)) === index)
}