<template>
  <component
    ref="dropdown"
    :disabled="disabled"
    :is="to ? 'router-link' : href ? 'a' : 'button'" :to="to" :href="href" :class="[$style['dropdown-item-default'],
    { 'dark:text-gray-500 text-gray-400 cursor-not-allowed': disabled }
    ]" @click="handleClick">
    <a-icon name="arrow-down" class="transform rotate-90" v-if="back && !forward" />
    <span class="flex-grow">
      <slot />
    </span>
    <a-icon name="arrow-down" class="transform -rotate-90" v-if="forward && !back" />
  </component>
</template>

<script>
export default {
    name: "DropdownItem",
    props: {
        to: { required: false, type: [String, Object], default: undefined },
        href: { required: false, type: String, default: undefined },
        disabled: { required: false, type: Boolean, default: false },
        preventClose: { required: false, type: Boolean, default: false },
        back: { required: false, type: Boolean, default: false },
        forward: { required: false, type: Boolean, default: false },
        download: { required: false, type: Boolean, default: false }
    },
    methods: {
        handleClick(e) {
            this.$emit('click', e)
            if (!this.preventClose) {
                this.$parent.close?.()
            }
        },
        downloadAttributes () {
          if (this.download) {
            this.$refs.dropdown.setAttribute('download', '')
          }
        }
    },
    mounted () {
        this.downloadAttributes ()
    }
}
</script>

<style module>
.baseDropdownItem {
    @apply flex w-full flex-shrink-0 items-center space-x-2 flex-grow focus:outline-none text-left text-sm cursor-pointer px-4 py-2;
}

.dropdown-item-default {
    @apply baseDropdownItem;
    @apply text-gray-800 hover:bg-gray-100;
}

.dropdown-item-dangerous {
    @apply baseDropdownItem;
    @apply text-red-500 hover:bg-red-100 hover:bg-opacity-50;
}

</style>
