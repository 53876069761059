import Vue from 'vue'
import Vuex from 'vuex'
import languages from '@/assets/lang'
import * as modules from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
    modules,
    state: {
        lang: localStorage.getItem(process.env.VUE_APP_USER_PREFERRED_LANGUAGE) || 'es',
        errors: {},
        navbarTitle: [],
        cancelTokens: [],
        showSidebar: true
    },
    mutations: {
        TRANSLATE: (state, lang) => state.lang = lang,
        CLEAR_ERRORS: state => state.errors = {},
        REMOVE_ERROR: (state, error) => delete state.errors[error],
        SET_ERRORS: (state, errors) => state.errors = errors,
        SET_NAVBAR_TITLE: (state, title) => state.navbarTitle = title,
        ADD_CANCEL_TOKEN: (state, token) => state.cancelTokens.push(token),
        CLEAR_CANCEL_TOKENS: (state) => state.cancelTokens = [],
        TOGGLE_SIDEBAR: state => state.showSidebar = !state.showSidebar
    },
    actions: {
        translate({ commit }, lang = 'es') {
            if (lang) {
                if (lang.length) {
                    localStorage.setItem(process.env.VUE_APP_USER_PREFERRED_LANGUAGE, lang)
                    commit('TRANSLATE', lang)
                }
            }
        },
        setErrors({ commit }, errors) {
            commit('SET_ERRORS', errors)
        },
        removeError({ commit }, error) {
            commit('REMOVE_ERROR', error)
        },
        vanishErrors({ commit }) {
            commit('SET_ERRORS', {})
        },
        cancelPendingRequest({ state, commit }) {
            // Cancel all request where a token exists
            state.cancelTokens.forEach(request => {
                if (request.cancel){
                    request.cancel();
                }
            });

            // Reset the cancelTokens store
            commit('CLEAR_CANCEL_TOKENS');
        }
    },
    getters: {
        // lang: ({ lang }) => languages[lang],
        lang: () => languages.es,
        currentLang: (state, getters) => getters.lang.app.languages.find(lang => state.lang === lang.iso).name,
        hasError: state => error => state.errors.hasOwnProperty(error),
        hasOneOfErrors: state => (...errors) => !Object.keys(state.errors).some(error => errors.some(_error => error === _error)),
        getError: state => error => state.errors[error] ? state.errors[error][0] : "",
        error: (_, { getError }) => (error, rules = {}) => {
            const lang = languages.es

            let _error = getError(error) // error passed through the function (usually same from response or field)
            let translated = lang.errors[_error?.split(':')[0]] // translated version of the error found on errors.json files
            let extra = _error.split(':')[1]; // argument found in the error. Eg: [low_performance_note: min:1]. where 1 is the extra

            let globalReg = /:attribute|:value|:before|:after|:min|:max|:date|:values|:format|:digits|:size|:other/g

            if (translated) {
                let matchs = translated.match(globalReg)

                // Eg. [:attribute, :value, :format]
                for (let match of matchs) {

                    let replacing = null
                    let occurrence = match.replace(':', '')

                    if (typeof rules[occurrence] == 'function') {
                        replacing = rules[occurrence](extra) // this allows to use [it]: () => it syntax to give custom translations
                    } else {
                        replacing = rules[occurrence] ?? lang.errors.user_attributes[occurrence]?.[error] ?? "[unhandled_translation_1]"

                        if (extra && occurrence !== 'attribute') {
                            replacing = rules[occurrence] // this will only be triggered if the occurrence exists in the first level of json (error translations)
                                        ?? lang.errors.user_attributes[occurrence]?.[extra]
                                        ?? extra // if there is no found item lets just use extra
                                        ?? "[unhandled_translation]" // this is just a safe point
                        }
                    }

                    translated = translated.replace(match, replacing || "")
                }
            }

            return translated
        },
        sidebarSections: ($0) => {
            const { session } = $0, { user } = session

            const lang = languages.es

            return {
                personal: {
                    title: lang.app.sidebar.personal.title,
                    items: [
                        { to: { name: 'administrators' }, icon: 'user-shield', label: 'Administradores', visible: user.isAdmin },
                        { to: '/coordinators', icon: 'teacher', label: 'Coordinadores', visible: user.isAdmin || user.isCoordinator || user.isLowAdmin, alias: '/coordinators' },
                        { to: { name: 'teachers.index' }, icon: 'teacher', label: 'Profesores', visible: user.isAdmin || user.isCoordinator || user.isLowAdmin },
                        { to: { name: 'students' }, icon: 'briefcase', label: 'Estudiantes', visible: user.isAdmin || user.isTeacher || user.isCoordinator || user.isLowAdmin },
                    ].sort((a, b) => a.label > b.label ? 1 : -1),
                    visible: user.isAdmin || user.isTeacher || user.isCoordinator || user.isLowAdmin
                },
                inscriptions: {
                    title: lang.app.sidebar.inscriptions.title,
                    items: [
                        { to: { name: 'preinscriptions' }, icon: 'note', label: 'Inscripciones', visible: user.isAdmin },
                        { to: { name: 'reinscriptions.index' }, icon: 'user-shield', label: 'Reincorporaciones', visible: user.isAdmin },
                    ].sort((a, b) => a.label > b.label ? 1 : -1),
                    visible: user.isAdmin || user.isLowAdmin
                },
                academic: {
                    title: lang.app.sidebar.academic.title,
                    items: [
                        { to: { name: 'final_deliveries' }, icon: 'award', label: 'Entregas finales', visible: true },
                        { to: { name: 'periods' }, icon: 'timer', label: 'Períodos', visible: user.isAdmin || user.isLowAdmin },
                        // { to: { name: 'preinscriptions' }, icon: 'note', label: 'Inscripciones', visible: user.isAdmin || user.isLowAdmin },
                        { to: { name: 'payments' }, icon: 'credit-cards-exchange', label: 'Pagos', visible: user.isAdmin || user.isLowAdmin },
                        { to: { name: 'classhours' }, icon: 'clock', label: 'Horas de clase', visible: user.isAdmin || user.isLowAdmin || user.isLowAdmin },
                        { to: { name: 'schedules' }, icon: 'calendar', label: 'Horarios', visible: user.isAdmin || user.isLowAdmin },
                        { to: { name: 'activities' }, icon: 'calendar-search', label: 'Cronograma de actividades', visible: true },
                        { to: { name: 'holidays' }, icon: 'calendar', label: 'Días feriados', visible: true },
                        { to: { name: 'classrooms' }, icon: 'grid', label: 'Aulas', visible: user.isAdmin || user.isLowAdmin || user.isLowAdmin },
                        { to: { name: 'specializations' }, icon: 'arrow-up-double', label: 'Especialidades', visible: user.isAdmin || user.isLowAdmin },
                        { to: { name: 'courses' }, icon: 'books', label: 'Materias', visible: user.isAdmin || user.isLowAdmin },
                        { to: { name: 'prelations' }, icon: 'ban', label: 'Prelaciones', visible: user.isAdmin || user.isLowAdmin || user.isCoordinator },
                        { to: { name: 'pensums.index' }, icon: 'tasks-square', label: 'Pensums', visible: user.isAdmin || user.isLowAdmin },
                        { to: { name: 'sections' }, icon: 'row-horizontal', label: 'Secciones', visible: user.isAdmin || user.isLowAdmin || user.isCoordinator },
                        { to: { name: 'documents' }, icon: 'book', label: 'Biblioteca virtual', visible: true },
                        { to: { name: 'binnacles' }, icon: 'bookmark', label: 'Bitácoras', visible: true },
                        { to: { name: 'document-requests.index' }, icon: 'note-2', label: 'Solicitudes de documentos', visible: user.isStudent || user.isAdmin || user.isLowAdmin },
                        { to: { name: 'notifications' }, icon: 'bell', label: 'Notificaciones', visible: true }
                    ].sort((a, b) => a.label > b.label ? 1 : -1),
                    visible: true
                },
                configuration: {
                    title: lang.app.sidebar.configurations.title,
                    items: [
                        // { to: { name: 'reports' }, icon: 'wrench', label: 'Datos generales', visible: true }
                    ],
                    visible: false
                }
            }
        },
        cancelTokens(state) {
            return state.cancelTokens;
        }
    }
})
