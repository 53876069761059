import {Authenticated, AllowedForTypes} from '@/router/middlewares';

const base = {
    index: { url: () => ({ name: 'sections' }), title: 'Listado de secciones' },
    create: { url: () => ({ name: 'create_section' }), title: ({ route, store: { state: { sections } } }) => {
        if (route.query.update) {
            return `Actualizar sección ${sections.section?.letter_code ?? ''}`
        }

        return 'Crear sección'
    }, loading: ({ store: { state: { sections } } }) => sections.loading },
    current: { url: () => ({ name: 'section' }), title: ({ store: { state: { sections } } }) => `Sección ${sections.section?.letter_code}` },
    schedule: { url: () => ({ name: 'section_schedules' }), title: () => `Horario` },
    set_schedule: { url: ({route}) => route, title: () => `Asignar horario` },
    create_final_delivery: { url: ({route}) => route, title: () => `Crear entrega final` },
    edit: { url: ({route}) => route, title: 'Editar período' }
}

export default ({ admin, coordinator, lowAdmin }) => [
	{
        path: '/sections',
        name: 'sections',
        component: () => import(/* webpackChunkName: "sections" */ '../../views/sections/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, coordinator, lowAdmin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title }
            ]
        }
    },
    {
        path: '/sections/create',
        name: 'create_section',
        component: () => import(/* webpackChunkName: "create_section" */ '../../views/sections/create.vue'),
        meta: {
            middleware: [Authenticated],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
                { route: base.create.url, text: base.create.title, loading: base.create.loading },
            ]
        }
    },
    {
        path: '/sections/:section_id',
        component: () => import(/* webpackChunkName: "section" */ '../../views/sections/section/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, coordinator, lowAdmin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
                { route: base.current.url, text: base.current.title, loading: ({ store: { state } }) => state.sections.loading }
            ]
        },
        children: [
            {
                path: '',
                name: 'section',
                component: () => import(/* webpackChunkName: "section" */ '../../views/sections/section/details.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, coordinator])],
                    breadcrumbs: [
                        { route: base.index.url, text: () => base.index.title },
                        { route: base.current.url, text: base.current.title, loading: ({ store: { state } }) => state.sections.loading }
                    ]
                },
            },
            {
                path: '/sections/:section_id/students',
                name: 'section_students',
                component: () => import(/* webpackChunkName: "section_course_set_schedule" */ '../../views/sections/section/students.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin])],
                    breadcrumbs: [
                        { route: base.index.url, text: () => base.index.title },
                        { route: ({ store }) => ({ name: 'section', params: { section_id: store.state.sections.section?.id } }), text: ({ store }) => `Seccion ${store.state.sections.section?.letter_code}`, loading: ({ store: { state } }) => state.sections.loading },
                        { route: ({ route }) => route, text: () => 'Estudiantes' }
                    ]
                }
            },
            {
                path: '/sections/:section_id/courses/:course_id/set-shcedule',
                name: 'section_course_set_schedule',
                component: () => import(/* webpackChunkName: "section_course_set_schedule" */ '../../views/sections/setSchedule.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin])],
                    breadcrumbs: [
                        { route: base.index.url, text: () => base.index.title },
                        { route: ({ store }) => ({ name: 'section', params: { section_id: store.state.sections.section?.id } }), text: ({ store }) => `Seccion ${store.state.sections.section?.letter_code}`, loading: ({ store: { state } }) => state.sections.loading },
                        { route: base.set_schedule.url, text: base.set_schedule.title, loading: ({ store: { state } }) => state.sections.loading }
                    ]
                }
            },
            {
                path: '/sections/:section_id/students-courses-notes',
                name: 'section_students_courses_notes',
                component: () => import(/* webpackChunkName: "section_course_set_schedule" */ '../../views/sections/studentsCoursesNotes.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, coordinator])],
                    breadcrumbs: [
                        { route: base.index.url, text: () => base.index.title },
                        { route: ({ store }) => ({ name: 'section', params: { section_id: store.state.sections.section?.id } }), text: ({ store }) => `Seccion ${store.state.sections.section?.letter_code}`, loading: ({ store: { state } }) => state.sections.loading },
                        { route: base.set_schedule.url, text: base.set_schedule.title, loading: ({ store: { state } }) => state.sections.loading }
                    ]
                }
            },
        ]
    },
    // {
    //     path: '/sections/:section_id/schedules',
    //     name: 'section_schedules',
    //     component: () => import(/* webpackChunkName: "section_schedules" */ '../../views/sections/schedules.vue'),
    //     meta: {
    //         requiresAuthentication: true,
    //         allowedFor: [admin],
    //         breadcrumbs: [
    //             { route: base.index.url, text: () => base.index.title },
    //             { route: ({ store }) => ({ name: 'section', params: { section_id: store.state.sections.section?.id } }), text: ({ store }) => `Seccion ${store.state.sections.section?.letter_code}`, loading: ({ store: { state } }) => state.sections.loading },
    //             { route: base.schedule.url, text: base.schedule.title, loading: ({ store: { state } }) => state.sections.loading }
    //         ]
    //     }
    // },
    {
        path: '/sections/:section_id/courses/:course_id/create-final-delivery',
        name: 'section_course_create_final_delivery',
        component: () => import(/* webpackChunkName: "section_course_create_final_delivery" */ '../../views/sections/finalDelivery.vue'),
        meta: {
            requiresAuthentication: true,
            allowedFor: [admin, coordinator],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
                { route: ({ store }) => ({ name: 'section', params: { section_id: store.state.sections.section?.id } }), text: ({ store }) => `Seccion ${store.state.sections.section?.letter_code}`, loading: ({ store: { state } }) => state.sections.loading },
                { route: base.create_final_delivery.url, text: base.create_final_delivery.title, loading: ({ store: { state } }) => state.sections.loading }
            ]
        }
    }
]
