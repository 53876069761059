<template>
  <svg viewBox="0 0 18 9">
    <g id="Linear" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Arrow" transform="translate(-239.000000, -260.000000)">
        <g id="arrow-down" transform="translate(236.000000, 252.000000)">
          <path d="M19.9200783,8.94995117 L13.4000778,15.4699516 C12.6300778,16.2399516 11.3700786,16.2399516 10.6000786,15.4699516 L4.08007812,8.94995117" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
          <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" id="Vector" opacity="0" transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) "></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "arrow-down",
  data: () => ({})
}
</script>