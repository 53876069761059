import { Authenticated } from '@/router/middlewares';

export default () => [
    {
        path: '/soon',
        name: 'soon',
        component: () => import(/* webpackChunkName: "soon" */ '../../views/soon.vue'),
        meta: {
            middleware: [Authenticated],
        }
    },
    {
        path: '/error',
        name: 'code_error',
        component: () => import(/* webpackChunkName: "soon" */ '../../views/Error.vue'),
        meta: {
            middleware: [Authenticated],
        }
    }
]
