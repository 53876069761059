<template>
  <a-dropdown width="w-96" keep-open-on-click>
    <div class="relative">
      <span class="h-2 w-2 rounded-full absolute bg-green-400 inline-block right-2" v-if="notifications.length" />
      <button
        v-tooltip="loading ? 'Cargando...' : notifications.length > 0 ? notifications.length === 1 ? '1 notificación nueva' : `${notifications.length} notificaciones nuevas` : 'No hay notificaciones nuevas'"
        class="bg-transparent h-12 rounded-full transition-colors hover:bg-opacity-75 w-12 text-lg flex items-center justify-center focus:outline-none hover:bg-gray-100 p-2 hover:ring-2 hover:ring-gray-200"
        :class="{'animate-pulse': loading}">
        <a-icon class="h-5 w-5" name="bell" :class="{'text-gray-300': !loading && !notifications.length}" />
      </button>
    </div>
    <template #menu>
      <template v-if="loading">
        <div class="flex space-x2 py-1">
          <a-skeleton class="w-16 h-16 rounded-full" />
          <div class="ml-2 flex flex-col items-start space-y-2">
            <a-skeleton class="w-24 h-2 mb-2" />
            <a-skeleton class="w-32 h-2" />
            <a-skeleton class="w-64 h-2" />
            <span class="flex-grow" />
          </div>
        </div>
      </template>
      <template v-else-if="notifications.length">
        <div class="max-h-96 overflow-y-hidden -mr-2">
          <div class="divide-y pr-2">
            <a-dropdown-item v-for="notification in notifications" :key="notification.id" @click="$emit('selection', notification)">
              <component :is="switchNotification(notification)" :notification="notification" />
            </a-dropdown-item>
          </div>
        </div>
      </template>
      <template v-else>
        <p class="text-center select-none cursor-default w-full justify-center flex text-xl py-4">
          No hay notificaciones
        </p>
      </template>
      <a-dropdown-item :to="{ name: 'notifications' }">
        <p class="text-center flex justify-center w-full py-1 border-t">
          Ver notificaciones
        </p>
      </a-dropdown-item>
    </template>
  </a-dropdown>
</template>

<script>
import UserNotification from './user-notification'
import AutomaticNotification from './automatic-notification'
export default {
  components: {
    UserNotification,
    AutomaticNotification
  },
  props: {
    notifications: { required: false, type: Array, default: () => [] },
    user: { required: true, type: Object },
    loading: { required: false, type: Boolean, default: false }
  },
  methods: {
    switchNotification(notification) {
      return notification.sender ? 'user-notification' : 'automatic-notification'
    }
  }
}
</script>