<template>
    <div style="display: inline-block">
        <div v-if="!hasPicture" :class="[`shadow-md sim-avatar`, `gradient-${gradient}`]" :style="{ height: `${parseInt(size)}px`, width: `${parseInt(size)}px` }">
            <img v-if="image" src="/image.png" alt="" :style="{width: `${parseInt(size) / 2}px`, height: `${parseInt(size) / 2}px`, borderRadius: `6px` }" @error="hasPicture = false">  
            <span v-else class="avatar-letter" :style="{ fontSize: `${parseInt(size) / 2}px` }">{{ text.charAt(0).toUpperCase() }}</span>
        </div>
        <img v-else :src="picture" alt="" :style="{width: `${parseInt(size)}px`, height: `${parseInt(size)}px`, borderRadius: `100px` }" class="sim-avatar-img shadow-md" @error="hasPicture = false">
    </div>
</template>

<script>

export default {
  props: {
    size: { required: false, type: [String, Number], default: 30 },
    text: { required: false, type: String, default: 'a' },
    picture: { required: false, type: String, default: null },
    gradient: { required: false, type: String, default: 'blue' },
    image: { required: false, type: Boolean, default: false },
  },
  data: () => ({
    hasPicture: false
  }),
  created() {
    this.hasPicture = this.picture != null
  }
}
</script>

<style scoped>
.sim-avatar {
    align-items: center;
    border-radius: 9999px;
    display: inline-flex;
    justify-content: center;
}

.gradient-blue {
    background: radial-gradient(circle at 0 0, #009cde, #003087);
}

.gradient-sim {
    background: radial-gradient(circle at 0 0, rgb(32 82 171), #020B28);
}

.avatar-letter {
    color: #fff;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
}

.sim-avatar-img {
    display: inline-block;
}
</style>