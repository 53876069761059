import router from '@/router';
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        current: null,
        all: [],
        loading: false,
        schedules: []
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET_TEACHER: (state, teacher) => state.current = teacher,
        SET_TEACHERS: (state, teachers) => state.all = teachers,
        DROP_TEACHER: (state, index) => state.pagination.data.splice(index, 1),
        TOGGLE_LOADING: state => state.loading = !state.loading,
        SET_SCHEDULES: (state, schedules) => state.schedules = schedules
    },
    actions: {
        index({ commit }, query = {}) {
            commit('TOGGLE_LOADING')
            return this.$repository.teachers
                .index(query, { cancellable: false })
                .then(response => {
                    commit(query?.limit === 0 ? 'SET_TEACHERS' : 'SET_PAGINATION', response.data)
                    return response
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        show({ commit }, {id, filters}) {
            commit('TOGGLE_LOADING')

            return this.$repository.teachers
                .show(id, filters)
                .then(({ data }) => {
                    commit('SET_TEACHER', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        store({ commit }, teacher) {

            commit('TOGGLE_LOADING')
            this.$repository.teachers
                .store(teacher)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado el ${teacher.is_coordinator ? 'coordinador' : 'profesor'} con éxito`,

                    })
                    router.push(teacher.is_coordinator ? { path: '/coordinators' } : { name: 'teachers.index' })
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        update({ commit, dispatch }, teacher) {
            commit('TOGGLE_LOADING')

            if (teacher.profile_picture instanceof File) {
                dispatch('updateProfilePicture', teacher.profile_picture)
            }

            if (!teacher.is_coordinator) {
                delete teacher.specialization_id
            }

            this.$repository.teachers
                .update(teacher.id, teacher)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha actualizado el ${teacher.is_coordinator ? 'coordinador' : 'profesor'} con éxito`,

                    })
                    router.push(teacher.is_coordinator ? { path: '/coordinators' } : { name: 'teachers.index' })
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        updateProfilePicture({ state }, profile_picture) {
            return this.$repository.users
                .updatePicture(state.current.user_id, { profile_picture })
        },
        schedule({ commit }, { id, filters = {}}) {
            commit('TOGGLE_LOADING')
            return this.$repository.teachers
                .schedules(id, filters)
                .then(({ data }) => {
                    commit('SET_SCHEDULES', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
    },
    getters: {
        scheduleColumns: state => {

            let classrooms = []

            state.all.forEach(it => {
                let found = classrooms.find(c => c.id === it.classroom.id)
                if (!found) {
                    classrooms.push(it.classroom)
                }
            })

            return [
                { title: 'Hora', slot: 'hour', enabled: true, style: () => ({ minWidth: '120px' }) },
                ...classrooms.map(it => ({ title: `Salón ${it.code}`, key: 'course_section.course.title', enabled: true }))
            ]
        }
    }
}
