import { Authenticated, AllowedForTypes } from '@/router/middlewares';

export default ({ student, admin, lowAdmin }) => [
	{
        path: '/document-requests',
        name: 'document-requests.index',
        component: () => import(/* webpackChunkName: "document-requests" */ '../../views/documentRequests/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([student, admin, lowAdmin])],
            breadcrumbs: [
                { route: () => ({ name: 'document-requests' }), text: () => 'Solicitudes de documentos' }
            ]
        }
    }
]
