<template>
    <div :class="[
        `p-4`,
        `rounded-2xl bg-white dark:bg-gray-900 shadow-md`,
        $listeners.click && 'cursor-pointer hover:shadow-lg transition ease-in-out duration-300 hover:scale-105 transform'
        ]" @click="$emit('click', $event)">
        <div v-if="$slots.header">
            <slot name="header" />
        </div>
        <div>
            <slot v-if="!loading"/>
            <a-skeleton height="60px" v-else :count="2"/>
        </div>
         <div :class="[`space-x-2 inline-flex`]" v-if="$slots.footer">
            <slot name="footer" />
        </div>
    </div>
</template>


<script>
export default {
    name: `card`,
    props: {
        rounded: { required: false, type: Boolean, default: false },
        loading: { required: false, type: Boolean, default: false }
    }
}
</script>