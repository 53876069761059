import {Authenticated, AllowedForTypes} from '@/router/middlewares';

export default ({ admin, lowAdmin }) => [
	{
        path: '/courses',
        component: () => import(/* webpackChunkName: "courses" */ '../../views/courses/new/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, lowAdmin])],
        },
        children: [
            {
                path: '',
                name: 'courses',
                component: () => import(/* webpackChunkName: "courses" */ '../../views/courses/new/specializations.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, lowAdmin])],
                    breadcrumbs: [
                        { route: () => ({ name: 'courses' }), text: () => 'Materias por especialidad' },
                    ]
                },
            },
            {
                path: '/courses/periods/:period/specializations/:specialization/pensum/:pensum',
                name: 'courses.details',
                component: () => import(/* webpackChunkName: "courses" */ '../../views/courses/new/details.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, lowAdmin])],
                    breadcrumbs: [
                        { route: ({ route }) => ({ name: 'courses', query: { period: route.query.period } }), text: () => 'Materias por especialidad' },
                        // specialization_id
                        { route: () => ({ name: 'courses' }), text: ({ store, route }) => {
                            const { specialization_id } = route.params
                            const { state: { periods: { specializations } } } = store

                            const found = specializations.find($0 => $0.id === parseInt(specialization_id))

                            if (found) {
                                return `Materias de ${found.title}`
                            }

                            return found
                        } },
                    ]
                },
            },
            {
                path: '/courses/periods/:period/specializations/:specialization/pensum/:pensum/show',
                name: 'courses.show',
                component: () => import(/* webpackChunkName: "courses" */ '../../views/courses/new/show.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, lowAdmin])],
                    breadcrumbs: [
                        { route: () => ({ name: 'courses' }), text: () => 'Materias por especialidad' },
                        // specialization_id
                        { route: () => ({ name: 'courses' }), text: ({ store, route }) => {
                                const { specialization_id } = route.params
                                const { state: { periods: { specializations } } } = store

                                const found = specializations.find($0 => $0.id === parseInt(specialization_id))

                                if (found) {
                                    return `Materias de ${found.title}`
                                }

                                return found
                            } },
                    ]
                },
            },
        ]
    },
    {
        path: '/courses/all',
        name: 'courses.all',
        component: () => import(/* webpackChunkName: "courses.create" */ '../../views/courses/all.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, lowAdmin])],
            breadcrumbs: [
                { route: () => ({ name: 'courses' }), text: () => 'Materias' },
                { route: () => ({ name: 'courses.all' }), text: () => 'Materias sin asignar', loading: ({ store }) => store.state.courses.loading },
            ]
        }
    },
    {
        path: '/courses/make',
        name: 'courses.create',
        component: () => import(/* webpackChunkName: "courses.create" */ '../../views/courses/create.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: () => ({ name: 'courses' }), text: () => 'Materias' },
                { route: () => ({ name: 'courses.create' }), text: ({ route, store }) => {
                    if (route.query.update) {
                        return `Actualizar ${store.state.courses.course?.title}`
                    }

                    return 'Crear materia'
                }, loading: ({ store }) => store.state.courses.loading },
            ]
        }
    },
    {
        path: '/courses/:id/assign',
        name: 'courses.assign',
        component: () => import(/* webpackChunkName: "courses.assign" */ '../../views/courses/assign.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: () => ({ name: 'courses' }), text: () => 'Materias' },
                // { route: () => ({ name: 'courses.create' }), text: ({ route, store }) => {
                //     if (route.query.update) {
                //         return `Actualizar ${store.state.courses.course?.title}`
                //     }

                //     return 'Crear materia'
                // }, loading: ({ store }) => store.state.courses.loading },
            ]
        }
    },
    {
        path: '/courses/periods/:period/section/:section_id/course/:course_id/teacher/:teacher/lessons',
        name: 'courses.lessons',
        component: () => import(/* webpackChunkName: "courses" */ '../../views/courses/new/lessons.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, lowAdmin])],
            breadcrumbs: [
                { route: () => ({ name: 'courses' }), text: () => 'Materias por especialidad' },
                // specialization_id
                { route: () => ({ name: 'courses' }), text: ({ store, route }) => {
                        const { specialization_id } = route.params
                        const { state: { periods: { specializations } } } = store

                        const found = specializations.find($0 => $0.id === parseInt(specialization_id))

                        if (found) {
                            return `Materias de ${found.title}`
                        }

                        return found
                    } },
            ]
        },
    }
]
