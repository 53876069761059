import Message from './message.js'

Message.install = (Vue, options) => {
  Vue.prototype.$message = Message
  if (options.store) {
    options.store.$message = Message
  }
}

export default Message
