export default ({ axios, mapQueryString, baseURL }) => ({
    index: (filters = {}) => axios.get(`/api/course-sections${mapQueryString(filters)}`),
    show: (section, course, filters = {}) => axios.get(`/api/sections/${section}/courses/${course}${mapQueryString(filters)}`),
    forStudent: (section, course, student, filters = {}) => axios.get(`/api/sections/${section}/courses/${course}/students/${student}${mapQueryString(filters)}`),
    assignTeacher: (section_id, course_id, body) => axios.patch(`/api/sections/${section_id}/courses/${course_id}/assign-teacher`, body),
    /**
     * @description allowd just for teachers
     */
    students: ({ course_id, section_id, filters = {} }) => axios.get(`/api/sections/${section_id}/courses/${course_id}/students${mapQueryString(filters)}`),
    studentAbsences: ({ student, course, section }, filters = {}) => axios.get(`/api/sections/${section}/courses/${course}/students/${student}/absences${mapQueryString(filters)}`),
    studentLessons: ({ student, course, section }, filters = {}) => axios.get(`/api/sections/${section}/courses/${course}/students/${student}/lessons${mapQueryString(filters)}`),
    reports: {
        lessonRegister: ({section_id, course_id}) => `${baseURL}/api/sections/${section_id}/courses/${course_id}/lessons-register-report`
    },
    updateCourseSection: (section_id, course_id, body) => axios.patch(`/api/sections/${section_id}/courses/${course_id}`, body)
// studentsNotes: () => axios.get(),
})
