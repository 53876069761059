import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        all: [],
        classroom: null,
        loading: false,
        classhours: [],
        schedules: []
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET_CLASSROOM: (state, classroom) => state.classroom = classroom,
        SET_CLASSROOMS: (state, sections) => state.all = sections,
        DROP_CLASSROOM: (state, index) => state.pagination.data.splice(index, 1),
        TOGGLE_LOADING: state => state.loading = !state.loading,
        SET_CLASSHOURS: (state, hours) => state.classhours = hours,
        SET_SCHEDULES: (state, schedules) => state.schedules = schedules
    },
    actions: {
        index({ commit }, filters = {}) {
            commit('TOGGLE_LOADING')
            return this.$repository.classrooms
                .index(filters)
                .then(response => {
                    commit(filters?.limit === 0 ? 'SET_CLASSROOMS' : 'SET_PAGINATION', response.data)
                    return response
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        show({ commit }, { id }) {
            commit('TOGGLE_LOADING')
            return this.$repository.classrooms
                .show(id)
                .then(response => {
                    commit('SET_CLASSROOM', response.data)
                    commit('SET_NAVBAR_TITLE', ['Listado de aulas', response.data.code, 'Editar aula'], { root: true })
                    return response
                }).finally(() => commit('TOGGLE_LOADING'))
        },
        schedules({ commit }, { id, filters = {} }) {
            commit('TOGGLE_LOADING')
            return this.$repository.classrooms
                .schedules(id, filters)
                .then(({ data }) => {
                    commit('SET_SCHEDULES', data)
                    return data
                }).finally(() => commit('TOGGLE_LOADING'))
        },
        store({ commit }, classroom) {
            commit('TOGGLE_LOADING')
            return this.$repository.classrooms
                .store(classroom)
                .then(response => {
                    Vue.prototype.$message.success({
                        title: 'Éxito',
                        message: `El aula de clases ha sido creada`,
                    })
                    
                    return response
                }).finally(() => commit('TOGGLE_LOADING'))
        },
        update({ commit }, classroom) {
            commit('TOGGLE_LOADING')
            return this.$repository.classrooms
                .update(classroom.id, classroom)
                .then(response => {
                    Vue.prototype.$message.success({
                        message: `Se ha actualizado el aula con éxito`,
                        
                    })
                    
                    return response
                }).finally(() => commit('TOGGLE_LOADING'))
        },
        delete({ commit, state }, id) {
            return new Promise((resolve, reject) => {
                Vue.prototype.$confirm({
                    message: `¿Desea eliminar esta aula?`,
                    button: {
                        no: 'No',
                        yes: 'Si'
                    },
                    callback: confirm => {
                        if (confirm) {
                            this.$repository.classrooms
                                .delete(id)
                                .then(response => {
                                    const index = state.pagination.data.findIndex(classroom => classroom.id === id)
                                    commit('DROP_CLASSROOM', index)
                                    resolve(response)
                                }).catch(err => reject(err))
                        }
                    }
                })
            })
        },
        schedulesAvailability({ commit }, filters = {}) {
            commit('TOGGLE_LOADING')
            return this.$repository.classrooms
                .schedulesAvailability(filters)
                .then(response => {
                    commit(filters?.limit === 0 ? 'SET_CLASSROOMS' : 'SET_PAGINATION', response.data)
                    return response
                }).finally(() => commit('TOGGLE_LOADING'))
        }
    },
    getters: {
        scheduleColumns: state => {

            let classrooms = []

            state.all.forEach(it => {
                let found = classrooms.find(c => c.id === it.classroom.id)
                if (!found) {
                    classrooms.push(it.classroom)
                }
            })

            return [
                { title: 'Hora', slot: 'hour', enabled: true, style: () => ({ minWidth: '120px' }) },
                ...classrooms.map(it => ({ title: `Salón ${it.code}`, key: 'course_section.course.title', enabled: true }))
            ]
        }
    }
}
