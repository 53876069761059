import router from '@/router';
import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        all: [],
        specialization: null,
        loading: false
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET: (state, specialization) => state.specialization = specialization,
        SET_ALL: (state, specializations) => state.all = specializations,
        DROP_SPECIALIZATION: (state, index) => state.pagination.data.splice(index, 1),
        TOGGLE_LOADING: state => state.loading = !state.loading
    },
    actions: {
        fetchSpecializations({ commit }, query = {}) {
            commit('TOGGLE_LOADING')
            this.$repository.specializations
                .index(query)
                .then(res => commit(query?.limit === 0 ? 'SET_ALL' : 'SET_PAGINATION', res.data))
                .then(() => query?.limit !== 0 && router.replace({ query }).catch(() => null))
                .finally(() => commit('TOGGLE_LOADING'))
            },
        fetchSpecialization({ commit }, id) {
            commit('TOGGLE_LOADING')
            this.$repository.specializations
                .show(id)
                .then(res => {
                    commit('SET', res.data)
                    commit('SET_NAVBAR_TITLE', ['Listado de especialidades', res.data.title, 'Editar especialidad'], { root: true })
                }).finally(() => commit('TOGGLE_LOADING'))
            },
        store({ commit }, specialization) {
            commit('TOGGLE_LOADING')
            this.$repository.specializations
                .store(specialization)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado la especialidad con éxito`,
                        
                    })
                    router.push({ name: 'specializations' })
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        update({ commit }, specialization) {
            commit('TOGGLE_LOADING')
            this.$repository.specializations
                .update(specialization.id, specialization)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha actualizado la especialidad con éxito`,
                        
                    })
                    router.push({ name: 'specializations' })
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        delete({ commit, state }, id) {
            Vue.prototype.$confirm({
                message: `¿Desea eliminar esta Especialidad?`,
                button: {
                    no: 'No',
                    yes: 'Si'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$repository.specializations
                            .delete(id)
                            .then(() => {
                                const index = state.pagination.data.findIndex(specialization => specialization.id === id)
                                commit('DROP_SPECIALIZATION', index)
                            })
                    }
                }
            })
        }
    }
}
