export default ({ axios, mapQueryString, toFormData }) => ({
    index: (filters = {}) => axios.get(`/api/users${mapQueryString(filters)}`), // filters -> type: Int, search: String [email, name]
    show: (id) => axios.get(`/api/users/${id}`),
    store: (user) => axios.post(`/api/users`, user),
    update: (id, user) => axios.patch(`/api/users/${id}`, user),
    updateEmail: (id, body) => axios.patch(`/api/users/${id}/email`, body), // body -> { new_email: String, password: String }
    updatePassword: (id, body) => axios.patch(`/api/users/${id}/password`, body), // body -> { password: String, new_password: String, new_password_confirmation: String }
    updatePicture: (id, body) => axios.post(`/api/users/${id}/profile-picture`, toFormData(body), { cancellable: false }), // body: FormData -> { profile_picture: File }
    delete: (id) => axios.delete(`/api/users/${id}`),
    sendConfirmationEmail: (id) => axios.post(`/api/users/${id}/send-account-confirm`),
})
