export default (val) => {
  const resources = [['I', 'V', 'X'],['X', 'L', 'C'],['C', 'D', 'M'], ['M']]
  let numStr = val.toString()
  let arr
  let arrReversed
  let singles = []
  let convertedReversed = []
  let converted

  arr = numStr.split('')

  arrReversed = arr.reverse()


  for (let num of arrReversed) {
    let converted
    switch(num) {
      case "0":
        converted = ''
        break
      case "1":
        converted = "i"
        break
      case "2":
        converted = "ii"
        break
      case "3":
        converted = "iii"
        break
      case "4":
        converted = "iv"
        break
      case "5":
        converted = "v"
        break
      case "6":
        converted = "vi"
        break
      case "7":
        converted = "vii"
        break
      case "8":
        converted = "viii"
        break
      case "9":
        converted = "ix"
    }

    singles.push(converted)
  }

  for (let i = 0; i < singles.length; i++) {
    let converted
    converted = singles[i].replace(/i/g, resources[i][0]).replace(/v/g, resources[i][1]).replace(/x/g, resources[i][2])
    convertedReversed.push(converted)
  }
  converted = convertedReversed.reverse()
  return converted.join('')
}