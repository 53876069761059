<template>
  <label :for="id" class="inline-flex space-x-2 items-center cursor-pointer select-none">
    <input
      :disabled="disabled"
      type="checkbox"
      :id="id"
      :name="name"
      :value="value"
      @input="emit"
      class="appearance-none cursor-pointer border checked:ring-2 checked:ring-primary w-4 h-4 checked:border-transparent ring-offset-2 border rounded border-gray-200 active:scale-95 transition-all transform checked:bg-primary">
    <span>
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  name: `checkbox`,
  props: {
    disabled: { required: false, type: Boolean, default: false },
    value: { required: false, type: [Boolean, String, Number, Array] },
    name: { required: false, type: String, default: null },
    id: { required: false, type: String, default: null },
  },
  methods: {
    emit(event) {
      if (Array.isArray(this.value)) {
        const copy = [...this.value]
        const index = copy.indexOf(event.target.value)
        if (index >= 0) {
          copy.splice(index, 1)
        } else {
          copy.push(event.target.value)
        }

      console.log(copy)

        this.$emit('input', copy)
      } else {
        this.$emit('input', event.target.value)
      }
      // this.$emit('input', !this.value)
    }
  }
}
</script>