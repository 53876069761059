<template>
  <div :class="$style.containerWrapper">
    <label :for="id" v-if="label && id">
      {{ label }}
      <span v-if="required">*</span>
    </label>
    <small v-if="hint">{{ hint }}</small>
    <section :class="$style[`wrapper-${computeStatus}`]">
      <slot name="prefix">
        <a-icon class="ml-2 text-gray-400" name="search" v-if="isSearch" />
      </slot>
      <component
        :is="type === 'textarea' ? 'textarea' : 'input'"
        :type="type"
        :id="id"
        :name="name"
        :required="required"
        :disabled="disabled"
        :class="inputClass"
        v-debounce:600="event => $emit('search', event)"
        :placeholder="placeholder"
        :value="value"
        :autofocus="autofocus"
        :min="min"
        :max="max"
        @input="$emit('input', $event.target.value)">{{value}}</component>
      <slot name="suffix" />
    </section>
    <section>
      <span>
          <template v-if="error">{{ error }}</template>
      </span>
      <span class="" v-if="counter">{{ value.length }}</span>
    </section>
  </div>
</template>
<script>
export default {
  props: {
    name: { required: false, type: String, default: '' },
    id: { required: false, type: String, default: '' },
    type: { required: false, type: String, default: 'text' },
    placeholder: { required: false, type: String, default: '' },
    value: { required: false, type: [String, Number], default: undefined },
    inputClass: { required: false, type: String, default: '' },
    autofocus: { required: false, type: Boolean, default: false },
    required: { required: false, type: Boolean, default: false },
    disabled: { required: false, type: Boolean, default: false },
    isSearch: { required: false, type: Boolean, default: false },

    /** @type {import('vue').PropOptions<string>} */
    label: { required: false, type: String, default: undefined },
    hint: { required: false, type: String, default: undefined },
    counter: { required: false, type: Boolean, default: false },
    error: { required: false, type: String, default: undefined },
    help: { required: false, type: String, default: undefined },
    status: { required: false, type: String, default: undefined },
    min: { required: false, default: undefined },
    max: { required: false, default: undefined }
  },
  computed: {
    computeStatus() {
      if (this.error) {
        return 'error'
      }

      return this.status ?? 'default'
    }
  }
}
</script>

<style module>
.containerWrapper {
  @apply flex flex-col;
}

.containerWrapper > label {
  @apply font-medium;
}

.containerWrapper > label > span {
  @apply text-red-600 font-bold;
}

.containerWrapper > small {
  @apply text-gray-600;
}

.wrapper {
  @apply flex rounded-lg items-center mt-2 border w-full focus-within:ring-2 focus-within:ring-opacity-60;
  @apply focus-within:border-transparent transition;
}

.wrapper-default {
  @apply wrapper;
  @apply focus-within:ring-primary bg-white;
}

.wrapper-error {
  @apply wrapper;
  @apply focus-within:ring-red-500 bg-red-500 bg-opacity-10 border-red-500 text-red-600;
}

.wrapper-error input {
  @apply placeholder-white;
}

.wrapper-success {
  @apply wrapper;
  @apply focus-within:ring-green-500 bg-green-400 bg-opacity-75 border-green-500 text-white;
}

.wrapper-success input {
  @apply placeholder-white;
}

.containerWrapper > section:nth-child(1) input,
.containerWrapper > section:nth-child(2) input,
.containerWrapper > section:nth-child(1) textarea,
.containerWrapper > section:nth-child(2) textarea {
  @apply px-2 py-2 rounded-lg outline-none transition-all bg-transparent w-full;
  @apply flex-grow disabled:bg-gray-200;
}

.containerWrapper > section:last-child {
  @apply flex justify-between;
}

.containerWrapper > section:last-child > span:first-child {
  @apply text-red-500 mt-1;
}

.containerWrapper > section:last-child > span:last-child {
  @apply text-sm;
}
</style>
