import { Role } from '@/constants';
import Vue from 'vue';

const { teacher } = Role

export default {
    namespaced: true,
    state: {
        pagination: { data: [], current_page: 1 },
        all: [],
        user: {},
        loading: false
    },
    mutations: {
        SET_PAGINATED_USERS: (state, pagination) => state.pagination = pagination,
        SET_USERS: (state, users) => state.all = users,
        SET_USER: (state, user) => state.user = user,
        TOGGLE_LOADING: state => state.loading = !state.loading
    },
    actions: {
        fetchUsers({ commit }, query = {}) {
            return this.$repository.users
                .index(query)
                .then(res => commit(query?.limit === 0 ? 'SET_USERS' : 'SET_PAGINATION', res.data))
        },
        fetchUser({ commit }, id) {
            return this.$repository.users
                .show(id)
                .then(res => commit('SET_USER', res.data))
        },
        password({ commit }, data){
            commit('TOGGLE_LOADING')
            return this.$repository.users
                .updatePassword(data.id, data.body).then(() => {
                    commit('TOGGLE_LOADING')
                    Vue.prototype.$message.success({
                        message: `Se ha actualizado la Clave con éxito`,
                    })
                });
        },
        updateUserPicture({ commit }, file){
            commit('TOGGLE_LOADING')
            // let data = new FormData()
            // data.append('profile_picture', file.body)
            return this.$repository.users
                .updatePicture(file.id, { profile_picture: file.body }).then(() => {
                commit('TOGGLE_LOADING')
                Vue.prototype.$message.success({
                    message: `Se ha actualizado la foto de perfil con éxito`,
                })
            });
        }
    },
    getters: {
        teachers: state => state.pagination.data.filter(user => user.type === teacher),
        userCanDo: (state, getters, rootState, rootGetters) => action => {
            return rootGetters["session/isAuthenticated"]
            && rootState.session.user.permissions.hasOwnProperty(action)
            && rootState.session.user.permissions[action]
        }
    }
}