<template>
  <div>
    <div class="flex" v-if="(label && id) || $slots.label">
      <slot name="label">
        <label :for="id" class="font-medium block mb-2">
          {{ label }}
        </label>
      </slot>
      <span class="text-red-600 inline-block ml-1" v-if="required">
          *
      </span>
    </div>
    <div class="w-full">
      <div class="flex">
        <input type="text" class="border flex-grow border-gray-200 rounded-l-md p-2 focus:outline-none text-gray-500" v-model="names" :readonly="true">
        <label :for="id" class="bg-primary rounded-r-md flex items-center justify-center px-4 text-white">
          <slot name="browse">
            Buscar
          </slot>
        </label>
        <input
          class="hidden"
          :required="required"
          type="file"
          :id="id"
          :disabled="disabled"
          :multiple="multiple"
          :readonly="readonly"
          @change="handleChange">
      </div>
      <div class="feedback-zone">
        <span>
            <p :class="[`${prefix}-input-error-message`]" v-if="error">{{ error }}</p>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { prefix } from '@/components/utils';
export default {
  props: {
    label: { required: false, type: String, default: undefined },
    required: { required: false, type: Boolean, default: false },
    id: { required: false, type: [String, Number], default: function() { return `${prefix}-upload-inpu-${this._uid}` } },
    disabled: { required: false, type: Boolean, default: false },
    readonly: { required: false, type: Boolean, default: false },
    alignment: { required: false, type: String, default: 'left' },
    error: { required: false, type: String, default: null },
    format: { required: false, type: String, default: 'file', validator: (prop) => ['file', 'base64'].includes(prop) },
    value: { required: false, type: [String, File], default: null },
    multiple: { required: false, type: Boolean, default: false }
  },
  data: () => ({
    prefix,
    names: ''
  }),
  methods: {
    handleChange(event) {
      this.$emit('change', event)
      let files = event.target.files

      if (this.format === 'file') {
        this.$emit('input', this.multiple ? files : files[0])
        if (this.multiple) {
          let names = [];
          for (const file of files) {
            names.push(file.name)
          }

          this.names = names.join(', ')
        } else {
          this.names = files[0].name
        }
      }
    }
  }
}
</script>