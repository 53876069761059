<template>
  <nav :class="$style.wrapper">
    <div>
      <div>
        <ul>
         <li v-for="({ route, text, loading }, key) in breadcrumbs" :key="key">
            <div v-if="!loading">
              <router-link class="text-sm text-primary" :to="route" v-if="key !== breadcrumbs.length - 1">
                  {{ text }}
              </router-link>
              <span v-else class="text-gray-500 text-sm">
                  {{ text }}
              </span>
              <template v-if="key !== breadcrumbs.length - 1">
                  <span class="inline-block px-1">»</span>
              </template>
            </div>
            <a-loader v-else class="text-primary h-4 w-4" />
          </li>
        </ul>
      </div>
    </div>
    <div>

      <notifications-dropdown :user="user" :notifications="notifications" :loading="loading" @selection="handleNotificationSelection($event)" />

      <a-dropdown>
        <div :class="$style.dropdown">
          <div class="items-start flex flex-col">
            <div class="flex justify-between -my-1 items-center">
              <b>{{ fullName | truncate(16) }}</b>
            </div>
            <span class="text-sm text-gray-700" v-if="user.isStudent">Estudiante</span>
            <span class="text-sm text-gray-700" v-if="user.isTeacher">Profesor</span>
            <span class="text-sm text-gray-700" v-if="user.isCoordinator">Coordinador</span>
            <span class="text-sm text-gray-700" v-if="user.isAdmin">Administrador</span>
            <span class="text-sm text-gray-700" v-if="user.isLowAdmin">Asistente</span>
          </div>

          <a-icon name="arrow-down" size="xs" />

          <a-avatar :text="user.name" :picture="user.profile_picture" size="40px;" class="bg-white rounded-full" />
        </div>

        <template #menu>
          <a-dropdown-item :to="{ name: 'profile' }">
            <span class="flex space-x-2 items-center">
              Perfil
            </span>
          </a-dropdown-item>
          <a-dropdown-item @click="handleLogout">
            Cerrar sesión
          </a-dropdown-item>
        </template>
      </a-dropdown>
    </div>
  </nav>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import NotificationsDropdown from './componnets/notifications-dropdown'
export default {
  components: {
    NotificationsDropdown
  },
  data: () => ({
    notifications: [],
    loading: false
  }),
  computed: {
    ...mapGetters('session', ['fullName']),
    ...mapState({
      user: state => state.session.user,
    }),
    breadcrumbs() {
      return (this.$route.meta.breadcrumbs ?? []).map($0 => ({
        route: $0.route({ route: this.$route, store: this.$store }),
        text: $0.text({ store: this.$store, route: this.$route }),
        bold: $0.bold,
        loading: $0.loading?.({ store: this.$store })
      }))
    }
  },
  methods: {
    ...mapActions({
      logout: 'session/logout',
      fetchNotifications: 'notifications/fetchAll'
    }),
    ...mapMutations({
      toggleSidebar: 'TOGGLE_SIDEBAR'
    }),
    markNotificationRead(notification) {
      this.$repository.notifications
        .markAsRead(notification)
        .then(() => {
          this.handleNotificationsFetch(true)
        })
    },
    handleNotificationSelection(notification) {
      this.$emit('notification', {
        notification,
        reload: () => {
          this.handleNotificationsFetch(true)
        }
      })
    },
    handleNotificationsFetch(isOpen) {
      if (isOpen) {
        this.loading = !this.loading
        this.$repository.notifications
          .index({ page: 1, limit: 0, with: 'sender', unread: true }, { cancellable: false })
          .then(({ data: notifications }) => {
            let data = [...notifications];
            data.reverse()
            this.notifications = data
            // this.$refs.scroll.refresh()
          })
          .finally(() => this.loading = !this.loading)
      }
    },
    handleLogout() {
      this.$emit('logout')
      this.logout()
    }
  },
  mounted() {
    this.handleNotificationsFetch(true)
  }
}
</script>

<style module>
.wrapper {
  @apply bg-white fixed right-0 flex flex-wrap justify-between items-center z-10 px-2 py-2 bg-opacity-50 backdrop-filter backdrop-blur;
}

.wrapper > div:nth-child(1) {
  @apply my-0 inline-flex items-center;
}

.wrapper > div:nth-child(1) > span:nth-child(1) {
  @apply inline-block cursor-pointer p-2 mr-2;
}

.wrapper > div:nth-child(1) > div {
  @apply hidden md:inline-flex lg:inline-flex;
}

.wrapper > div:nth-child(1) > div > ul {
  @apply flex items-center space-x-2;
}

.wrapper > div:nth-child(1) > div > ul > li {
  @apply float-left flex justify-center items-center;
}

.wrapper > div:nth-child(2) {
  @apply flex items-center;
}

.dropdown {
  @apply flex transition-colors items-start space-x-3 px-2 pl-4 py-0.5;
}
</style>