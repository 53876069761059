<template>
    <div>
        <div :class="[`w-full flex items-center text-sm space-x-2 leading-none select-none justify-between text-white cursor-pointer`, to ? 'is-link' : 'px-4', !to && isOpen && 'bg-gray-600 bg-opacity-40 rounded-md']" @click="to ? () => {} : toggleOpen($event)">
            <component
                :is="to ? 'router-link' : 'span'"
                :to="to"
                :class="['inline-flex items-start leading-none space-x-2 w-full py-2 px-4 rounded-xl', {
                    'disabled': disabled,
                    'hover:bg-opacity-10 hover:bg-white': to
                }]"
                class="rounded-md"
                active-class="bg-primary">
                <slot>{{ label }}</slot>
            </component>
            <template v-if="hasSubMenu">
              <span class="text-gray-600">
                <a-icon name="arrow-down" :class="[`transition-all w-2 h-2`, isOpen && 'text-white transform rotate-90']"/>
              </span>
            </template>
        </div>
        <transition name="slide">
            <div v-if="hasSubMenu && isOpen" class="space-y-1 mt-1">
                <slot name="menu" />
            </div>
        </transition>
    </div>
</template>

<script>
import { prefix } from '@/components/utils';
export default {
    name: `sidebar-item`,
    props: {
        to: { required: false, type: [String, Object], default: null },
        label: { required: false, type: String, default: '' },
        disabled: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        prefix,
        isOpen: false
    }),
    computed: {
        hasSubMenu() {
            return this.$slots.hasOwnProperty('menu')
        }
    },
    methods: {
        toggleOpen(event) {
            
            this.toggle()

            this.isOpen = !this.isOpen;

            this.$emit('click', event)
        },
        toggle(toggle = false) {
            if (this.$parent.accordion) {
                for(let child of this.$parent.$children) {
                    if (child.isOpen && child !== this) {
                        child.isOpen = toggle
                    }
                }
            }
        },
    },
    mounted() {
        const $menu = this.$slots.menu
        if ($menu) {
            setTimeout(() => {
                this.isOpen = this.$slots.menu?.some($0 => $0.componentOptions?.propsData.to?.name === this.$route.name)
            }, 500)
        }
    }
}
</script>