<template>
  <svg viewBox="0 0 20 22">
    <g id="Linear" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Essetional" transform="translate(-110.000000, -189.000000)">
        <g id="trash" transform="translate(108.000000, 188.000000)">
          <path d="M21,5.97998047 C17.6700001,5.64998046 14.3199995,5.47998047 10.9799995,5.47998047 C9,5.47998047 7.01999998,5.57998066 5.03999996,5.77998066 L3,5.97998047" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
          <path d="M8.5,4.96999979 L8.72000027,3.66000009 C8.88000026,2.7100001 9,2 10.6899996,2 L13.3100004,2 C15,2 15.1299997,2.75000006 15.2799997,3.67000008 L15.5,4.96999979" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
          <path d="M18.8500252,9.14001465 L18.2000256,19.2100134 C18.0900256,20.7800134 18.0000238,22.0000143 15.2100239,22.0000143 L8.79002428,22.0000143 C6.00002432,22.0000143 5.91002451,20.7800134 5.80002451,19.2100134 L5.15002441,9.14001465" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
          <line x1="10.3300171" y1="16.5" x2="13.660017" y2="16.5" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></line>
          <line x1="9.5" y1="12.5" x2="14.5" y2="12.5" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></line>
          <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" id="Vector" opacity="0"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "trash",
  data: () => ({})
}
</script>