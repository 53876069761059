import router from '@/router';
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        all: [],
        pensum: null,
        loading: false
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET_PENSUM: (state, pensum) => state.pensum = pensum,
        SET_PENSUMS: (state, pensums) => state.all = pensums,
        DROP_PENSUM: (state, index) => state.pagination.data.splice(index, 1),
        TOGGLE_LOADING: state => state.loading = !state.loading
    },
    actions: {
        index({ commit }, query = {}) {
            commit('TOGGLE_LOADING')
            return this.$repository.pensums
                .index(query)
                .then(res => {
                    commit(query?.limit === 0 ? 'SET_PENSUMS' : 'SET_PAGINATION', res.data)
                    return res.data
                    // .then(() => router.replace({ query }))
                })
                .finally(() => commit('TOGGLE_LOADING'))
            },
        show({ commit }, { id, query }) {
            commit('TOGGLE_LOADING')
            return this.$repository.pensums
                .show(id, query)
                .then(res => {
                    commit('SET_PENSUM', res.data)
                    return res.data
                }).finally(() => commit('TOGGLE_LOADING'))
        },
        store({ commit }, pensum) {
            commit('TOGGLE_LOADING')
            this.$repository.pensums
                .store(pensum)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado el pensum con éxito`,
                        
                    })
                    router.push({ name: 'pensums.index' })
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        update({ commit }, pensum) {
            commit('TOGGLE_LOADING')
            this.$repository.pensums
                .update(pensum.id, pensum)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha actualizado el pensum con éxito`,
                        
                    })
                    router.push({ name: 'pensums.index' })
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        updateSemester({ dispatch }, semester) {
            dispatch('semesters/updateSemesterCourses', semester, { root: true })
            dispatch('semesters/update', semester, { root: true })
        },
        deleteSemester(_, semester) {
            Vue.prototype.$message.info({
                message: 'Intentando eliminar el semestre...',
                position: 'top-right'
            })
            this.$repository.semesters
                .delete(semester.id)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: 'Se ha eliminado el semestre con éxito',
                        position: 'top-right'
                    })
                    semester.completion()
                })
        },
        delete({ commit, state }, id) {
            return new Promise((resolve, reject) => {
                Vue.prototype.$confirm({
                    message: `¿Desea eliminar este pensum?`,
                    button: {
                        no: 'No',
                        yes: 'Si'
                    },
                    callback: confirm => {
                        if (confirm) {
                            this.$repository.pensums
                                .delete(id)
                                .then(() => {
                                    const index = state.pagination.data.findIndex(pensum => pensum.id === id)
                                    commit('DROP_PENSUM', index)
                                    resolve(id)
                                })
                                .catch(err => reject(err))
                        }
                    }
                })
            })
        }
    }
}
