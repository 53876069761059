<template>
  <svg viewBox="0 0 18 22">
    <g id="Linear" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="School,-Learning" transform="translate(-431.000000, -189.000000)">
        <g id="award" transform="translate(428.000000, 188.000000)">
          <path d="M4.26000977,15.9899416 C4.26000977,17.8099416 4.26000977,17.8099412 5.98000956,18.9699411 L10.7100096,21.6999416 C11.4200096,22.1099416 12.5800095,22.1099416 13.2900095,21.6999416 L18.02001,18.9699411 C19.7400093,17.8099412 19.7400093,17.8099416 19.7400093,15.9899416 L19.7400093,11.0199418 C19.7400093,9.19994175 19.7400093,9.19994128 18.02001,8.03994131 L13.2900095,5.30994141 C12.5800095,4.89994141 11.4200096,4.89994141 10.7100096,5.30994141 L5.98000956,8.03994131 C4.26000977,9.19994128 4.26000977,9.19994175 4.26000977,11.0199418 L4.26000977,15.9899416 Z" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
          <path d="M17.5,7.63000011 L17.5,5 C17.5,3 16.5,2 14.5,2 L9.5,2 C7.5,2 6.5,3 6.5,5 L6.5,7.55999994" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
          <path d="M13.2000241,11.8799613 C13.2900241,12.0199613 13.4900247,12.1599609 13.6400247,12.1999609 L14.6600242,12.4599612 C15.2900242,12.6199612 15.4600245,13.159961 15.0500245,13.659961 L14.3800244,14.4699614 C14.2800244,14.5999614 14.2000244,14.8299612 14.2100244,14.9899611 L14.2700248,16.0399623 C14.3100248,16.6899623 13.8500243,17.0199621 13.2500243,16.7799621 L12.270025,16.3899608 C12.120025,16.3299608 11.8700248,16.3299608 11.7200248,16.3899608 L10.7400243,16.7799621 C10.1400243,17.0199621 9.68002471,16.6799623 9.7200247,16.0399623 L9.78002417,14.9899611 C9.79002417,14.8299612 9.7100241,14.5899614 9.61002409,14.4699614 L8.94002402,13.659961 C8.53002402,13.159961 8.70002437,12.6199612 9.33002436,12.4599612 L10.3500248,12.1999609 C10.5100248,12.1599609 10.7100245,12.0099612 10.7900245,11.8799613 L11.3600242,10.989961 C11.7200242,10.4499609 12.2800247,10.4499609 12.6300247,10.989961 L13.2000241,11.8799613 Z" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
          <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" id="Vector" opacity="0" transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) "></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "award",
  data: () => ({})
}
</script>