import Vue from 'vue';
export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        all: [],
        current: null,
        loading: false
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET: (state, current) => state.current = current,
        SET_ALL: (state, sections) => state.all = sections,
        DROP: (state, index) => state.pagination.data.splice(index, 1),
        TOGGLE_LOADING: state => state.loading = !state.loading
    },
    actions: {
        index({ commit }, filters = {}) {
            commit('TOGGLE_LOADING')
            return this.$repository.activities
                .index(filters)
                .then(({data}) => {
                    commit(filters?.limit === 0 ? 'SET_ALL' : 'SET_PAGINATION', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        show({ commit }, id) {
            commit('TOGGLE_LOADING')
            return this.$repository.activities
                .show(id)
                .then(({data}) => {
                    commit('SET', data)
                    return data
                }).finally(() => commit('TOGGLE_LOADING'))
        },
        store({ commit }, item) {
            commit('TOGGLE_LOADING')

            return this.$repository.activities
                .store(item)
                .then(({data}) => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado la actividad con éxito`,
                        
                    })
                    
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        update({ commit }, item) {
            commit('TOGGLE_LOADING')

            Object.entries(item)
                .forEach(it => {
                    if (it[1] === '') {
                        delete item[it[0]]
                    }
                })
            return this.$repository.activities
                .update(item.id, item)
                .then(({data}) => {
                    Vue.prototype.$message.success({
                        message: `Se ha actualizado la actividad con éxito`,
                        
                    })

                    return data
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        delete({ commit, state }, id) {
            return new Promise((resolve, reject) => {
                Vue.prototype.$confirm({
                    message: `¿Desea eliminar esta actividad?`,
                    button: {
                        no: 'No',
                        yes: 'Si'
                    },
                    callback: confirm => {
                        if (confirm) {
                            this.$repository.activities
                                .delete(id)
                                .then(response => {
                                    const index = state.pagination.data.findIndex(item => item.id === id)
                                    commit('DROP', index)

                                    resolve(response)
                                }).catch(err => reject(err))
                        }
                    }
                })
            })
        }
    }
}
