<template>
  <div class="py-1 w-full">
    <div class="flex align-top space-x-2 w-full">
      <!-- <img :src="notification.sender.profile_picture" alt="" class="rounded-full h-12 w-12 ring-2 ring-gray-100"> -->
      <div class="flex flex-col items-start group w-full flex-grow">
        <div class="flex w-full justify-between mb-2">
          <div class="text-left">
            <span class="text-sm">SISTEMA</span>
            <p class="text-gray-500 text-xs">{{ notification.created_at | date('YYYY-MM-DD [a las] HH:mm') }}</p>
          </div>
          <a-dropdown class="text-center">
            <template #menu>
              <a-dropdown-item close-on-select @click.stop="markNotificationRead(notification.id)">
                Marcar como leida
              </a-dropdown-item>
            </template>
          </a-dropdown>
        </div>
        <p class="text-left line-clamp-2 text-justify">
          {{ notification.content }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    notification: { required: false, type: Object, default: () => ({ sender: {}, content: '' }) }
  },
  computed: {
    link() {
      const expression = /(https?:\/\/(?:www\.|(?!www))[^\s]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi
      const matches = this.notification.content.match(expression)
      return matches.first()
    }
  }
}
</script>