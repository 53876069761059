export default ({ axios, mapQueryString }) => ({
    index: (filters = {}, configuration = {}) => axios.get(`/api/sections${mapQueryString(filters)}`, configuration),
    show: (id) => axios.get(`/api/sections/${id}?with=courses,specialization`),
    store: (body) => axios.post(`/api/sections`, body),
    update: (id, body) => axios.patch(`/api/sections/${id}`, body),
    delete: (id) => axios.delete(`/api/sections/${id}`),
    schedules: (section_id, filters = {}) => axios.get(`/api/sections/${section_id}/schedules${mapQueryString(filters)}`),
    report: (section_id, course_id) => axios.get(`/api/sections/${section_id}/courses/${course_id}/reports/students`),
    courseSection: (section_id, course_id) => axios.get(`/api/sections/${section_id}/courses/${course_id}`),
    sectionReport: (section_id) => axios.get(`/api/sections/${section_id}/reports/students`),
    course: course_id => ({
        assignTeacher: (section_id, body) => axios.patch(`/api/sections/${section_id}/courses/${course_id}/assign-teacher`, body),
        students: (section_id) => axios.get(`/api/sections/${section_id}/courses/${course_id}/students`)
    }),
    studentCourses: (section_id) => axios.get(`/api/sections/${section_id}/students-courses-notes`),
    studentsNotes: (section_id, course_id, filters = {}) => axios.get(`/api/sections/${section_id}/courses/${course_id}/students-notes${mapQueryString(filters)}`),
    courseSectionStudent: (section_id, course_id, student_id, filters = {}) => axios.get(`api/sections/${section_id}/courses/${course_id}/students/${student_id}/lessons${mapQueryString(filters)}`)
})
