import moment from 'moment'

export default (val, format = 'YYYY-MM-DD', fallback = '-', utc = false) => {
    let date

    if (utc) {
        date = moment(val).utc()
    } else {
        date = moment(val)
    }

    if (!date.isValid()) {
        return fallback
    }

    return date.format(format)
}
