export const storeObject = (key, object) => {
    localStorage.setItem(key, JSON.stringify(object))
}

export const getObject = (key) => localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null

export const removeObject = (key) => localStorage.removeItem(key)

export const setCookie = (name, value, expiration = null) => {
    let string = `${name}=${value}`
    if (expiration) { string += `;expires=${expiration}`}
    string += `;path=/`
    document.cookie = string
}

export const getCookie = (_name) => {
    let name = _name + "="
    let splitted = document.cookie.split(';').map(cookie => cookie.trim())

    for (let i in splitted) {
        let c = splitted[i]
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
        }
    }

    return null;
}

export const removeCookie = (name) => {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

// export const checkCookie = (cookie) => {
    // let string = getCookie(cookie);
    // if (string != "") {
        // alert("Welcome again " + string);
    // } else {
        // string = prompt("Please enter your name:", "");
        // if (string != "" && string != null) {
            // setCookie("username", string, 365);
        // }
    // }
// }