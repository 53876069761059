import {Authenticated, AllowedForTypes} from '@/router/middlewares';

export default ({ admin, lowAdmin }) => [
	{
        path: '/pensums',
        name: 'pensums.index',
        component: () => import(/* webpackChunkName: "pensums.index" */ '../../views/pensums/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, lowAdmin])],
            breadcrumbs: [
                { route: () => ({ name: 'pensums.index' }), text: () => 'Listado de pensums' }
            ]
        }
    },
    {
        path: '/pensums/create',
        name: 'pensums.create',
        component: () => import(/* webpackChunkName: "pensums.create" */ '../../views/pensums/create.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: () => ({ name: 'pensums.index' }), text: () => 'Listado de pensums' },
                { route: () => ({ name: 'pensums.create' }), text: () => `Crear pensum` },
            ]
        }
    },
    {
        path: '/pensums/:id',
        name: 'pensums.show',
        component: () => import(/* webpackChunkName: "pensums.show" */ '../../views/pensums/pensum.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, lowAdmin])],
            breadcrumbs: [
                { route: () => ({ name: 'pensums.index' }), text: () => 'Listado de pensums' },
                { route: ({ route }) => ({ name: 'pensums.show', params: route.params }), text: ({ store: { state: { pensums } } }) => pensums.pensum?.name ?? 'No encontrado', loading: ({ store }) => store.state.pensums.loading },
            ]
        }
    },
    {
        path: '/pensums/:id/edit',
        name: 'pensums.edit',
        component: () => import(/* webpackChunkName: "pensums.edit" */ '../../views/pensums/edit.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: () => ({ name: 'pensums.index' }), text: () => 'Listado de pensums' },
                { route: ({ route }) => ({ name: 'pensums.show', params: route.params }), text: ({ store: { state: { pensums } } }) => pensums.pensum?.name ?? 'No encontrado', loading: ({ store }) => store.state.pensums.loading },
                { route: ({ route }) => ({ name: 'pensums.edit', params: route.params }), text: () => 'Editar' },
            ]
        }
    },
]
