import { Authenticated, AllowedForTypes } from '@/router/middlewares';

export default ({ admin, teacher, coordinator, student, lowAdmin }) => [
	{
        path: '/binnacles',
        name: 'binnacles',
        component: () => import(/* webpackChunkName: "binnacles" */ '../../views/binnacles/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, teacher, coordinator, student, lowAdmin])],
            breadcrumbs: [
                { route: () => ({ name: 'binnacles' }), text: () => 'Listado de bitácoras' }
            ]
        }
    },
    {
        path: '/binnacles/create',
        name: 'create_binnacle',
        component: () => import(/* webpackChunkName: "create_binnacle" */ '../../views/binnacles/create.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, teacher, coordinator])],
            breadcrumbs: [
                { route: () => ({ name: 'binnacles' }), text: () => 'Listado de bitácoras' },
                { route: (route) => route, text: () => "crear bitácora" },
            ]
        }
    },
    {
        path: '/binnacles/:id/edit',
        name: 'edit_binnacle',
        component: () => import(/* webpackChunkName: "edit_binnacle" */ '../../views/binnacles/edit.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, teacher, coordinator])],
            breadcrumbs: [
                { route: () => ({ name: 'binnacles' }), text: () => 'Listado de bitácoras' },
                { route: (route) => route, text: ({ store }) => `${store.state.binnacles.current?.incidence_date}`, loading: ({ store }) => store.state.binnacles.loading },
                { route: (route) => route, text: () => "Editar bitácora" },
            ]
        }
    }
]
