import { Authenticated, AllowedForTypes } from '@/router/middlewares';

export default ({ admin, teacher, coordinator, lowAdmin }) => [
    {
        path: `/students`,
        name: 'students',
        component: () => import(/* webpackChunkName: "students" */ '../../views/students/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, coordinator, teacher, lowAdmin])],
            breadcrumbs: [
                { route: () => ({ name: 'students' }), text: () => 'Listado de estudiantes' },
            ],
        }
    },
    {
        path: `/students/:student/edit`,
        name: 'edit_students',
        component: () => import(/* webpackChunkName: "edit_students" */ '../../views/students/edit.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, coordinator, teacher])],
            breadcrumbs: [
                { route: () => ({ name: 'students' }), text: () => 'Listado de estudiantes' },
                { route: ({ params }) => ({ name: 'student', params }), text: ({ store }) => `${store.state.students.current?.first_name} ${store.state.students.current?.last_name}`, loading: ({ store }) => store.state.students.loading },
                { route: (route) => route, text: () => 'Editar' },
            ]
        }
    },
    {
        path: '/students/:student',
        component: () => import(/* webpackChunkName: "student" */ '../../views/students/student/index.vue'),
        children: [
            // ADMIN ROUTES
            {
                path: '',
                name: 'student',
                component: () => import(/* webpackChunkName: "student" */ '../../views/students/student/student.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, coordinator, teacher, lowAdmin])],
                    breadcrumbs: [
                        { route: () => ({ name: 'students' }), text: () => 'Listado de estudiantes' },
                        { route: ({ params }) => ({ name: 'student', params }), text: ({ store }) => `${store.state.students.current?.first_name} ${store.state.students.current?.last_name}`, loading: ({ store }) => store.state.students.loading },
                    ]
                },
            },
            {
                path: '/students/:student/history',
                name: 'student_history',
                component: () => import(/* webpackChunkName: "student_history" */ '../../views/students/student/history.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, lowAdmin, coordinator])],
                    breadcrumbs: [
                        { route: () => ({ name: 'students' }), text: () => 'Listado de estudiantes' },
                        { route: ({ params }) => ({ name: 'student', params }), text: ({ store }) => `${store.state.students.current?.first_name} ${store.state.students.current?.last_name}`, loading: ({ store }) => store.state.students.loading },
                        { route: () => ({ name: 'students' }), text: () => 'historial académico' },
                    ]
                }
            },
            {
                path: '/students/:student/notifications',
                name: 'student_notifications',
                component: () => import(/* webpackChunkName: "student_notifications" */ '../../views/students/student/notifications.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, teacher, lowAdmin, coordinator])],
                    breadcrumbs: [
                        { route: () => ({ name: 'students' }), text: () => 'Listado de estudiantes' },
                        { route: ({ params }) => ({ name: 'student', params }), text: ({ store }) => `${store.state.students.current?.first_name} ${store.state.students.current?.last_name}`, loading: ({ store }) => store.state.students.loading },
                        { route: () => ({ name: 'students' }), text: () => 'notificaciones' },
                    ]
                }
            },
            {
                path: '/students/:student/binnacles',
                name: 'student_binnacles',
                component: () => import(/* webpackChunkName: "student_binnacles" */ '../../views/students/student/binnacles.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, teacher, lowAdmin, coordinator])],
                    breadcrumbs: [
                        { route: () => ({ name: 'students' }), text: () => 'Listado de estudiantes' },
                        { route: ({ params }) => ({ name: 'student', params }), text: ({ store }) => `${store.state.students.current?.first_name} ${store.state.students.current?.last_name}`, loading: ({ store }) => store.state.students.loading },
                        { route: () => ({ name: 'students' }), text: () => 'bitácoras' },
                    ]
                }
            },
            {
                path: '/students/:student/courses',
                name: 'student_courses',
                component: () => import(/* webpackChunkName: "student_courses" */ '../../views/students/student/courses.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, coordinator, lowAdmin, coordinator])],
                    breadcrumbs: [
                        { route: () => ({ name: 'students' }), text: () => 'Listado de estudiantes' },
                        { route: ({ params }) => ({ name: 'student', params }), text: ({ store }) => `${store.state.students.current?.first_name} ${store.state.students.current?.last_name}`, loading: ({ store }) => store.state.students.loading },
                        { route: () => ({ name: 'students' }), text: () => 'materias' },
                    ]
                }
            },
            {
                path: '/students/:student/absences',
                name: 'student_unattendances',
                component: () => import(/* webpackChunkName: "student_unattendances" */ '../../views/students/student/unattendances.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, coordinator, teacher, lowAdmin, coordinator])],
                    breadcrumbs: [
                        { route: () => ({ name: 'students' }), text: () => 'Listado de estudiantes' },
                        { route: ({ params }) => ({ name: 'student', params }), text: ({ store }) => `${store.state.students.current?.first_name} ${store.state.students.current?.last_name}`, loading: ({ store }) => store.state.students.loading },
                        { route: () => ({ name: 'students' }), text: () => 'Inasistencias' },
                    ]
                }
            },
            {
                path: '/students/:student/delays',
                name: 'student_delays',
                component: () => import('../../views/students/student/delayed.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, coordinator, teacher, lowAdmin, coordinator])],
                    breadcrumbs: [
                        { route: () => ({ name: 'students' }), text: () => 'Listado de estudiantes' },
                        { route: ({ params }) => ({ name: 'student', params }), text: ({ store }) => `${store.state.students.current?.first_name} ${store.state.students.current?.last_name}`, loading: ({ store }) => store.state.students.loading },
                        { route: () => ({ name: 'students' }), text: () => 'retrasos' },
                    ]
                }
            },
            {
                path: '/students/:student/lessons',
                name: 'student_lessons',
                component: () => import(/* webpackChunkName: "student_lessons" */ '../../views/students/student/lessons.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, coordinator, teacher, lowAdmin, coordinator])],
                    breadcrumbs: [
                        { route: () => ({ name: 'students' }), text: () => 'Listado de estudiantes' },
                        { route: ({ params }) => ({ name: 'student', params }), text: ({ store }) => `${store.state.students.current?.first_name} ${store.state.students.current?.last_name}`, loading: ({ store }) => store.state.students.loading },
                        { route: () => ({ name: 'students' }), text: () => 'Clases' },
                    ]
                }
            },
            // TEACHER ROUTES
            {
                path: '/students/:student/:section/:course',
                name: 'section_course_student',
                component: () => import(/* webpackChunkName: "student" */ '../../views/students/student/student.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, coordinator, teacher, lowAdmin])],
                    breadcrumbs: [
                        { route: () => ({ name: 'students' }), text: () => 'Listado de estudiantes' },
                        { route: ({ params }) => ({ name: 'student', params }), text: ({ store }) => `${store.state.students.current?.first_name || 'Cargando'} ${store.state.students.current?.last_name || '...'}` },
                    ]
                }
            },
            {
                path: '/students/:student/:section/:course/history',
                name: 'section_course_student_history',
                component: () => import(/* webpackChunkName: "student_history" */ '../../views/students/student/history.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, lowAdmin])],
                    breadcrumbs: [
                        { route: () => ({ name: 'students' }), text: () => 'Listado de estudiantes' },
                        { route: ({ params }) => ({ name: 'student', params }), text: ({ store }) => `${store.state.students.current?.first_name} ${store.state.students.current?.last_name}`, loading: ({ store }) => store.state.students.loading },
                        { route: () => ({ name: 'students' }), text: () => 'historial académico' },
                    ]
                }
            },
            {
                path: '/students/:student/:section/:course/notifications',
                name: 'section_course_student_notifications',
                component: () => import(/* webpackChunkName: "student_history" */ '../../views/students/student/notifications.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, teacher, lowAdmin])],
                    breadcrumbs: [
                        { route: () => ({ name: 'students' }), text: () => 'Listado de estudiantes' },
                        { route: ({ params }) => ({ name: 'student', params }), text: ({ store }) => `${store.state.students.current?.first_name} ${store.state.students.current?.last_name}`, loading: ({ store }) => store.state.students.loading },
                        { route: () => ({ name: 'students' }), text: () => 'notificaciones' },
                    ]
                }
            },
            {
                path: '/students/:student/:section/:course/binnacles',
                name: 'section_course_student_binnacles',
                component: () => import(/* webpackChunkName: "student_history" */ '../../views/students/student/binnacles.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, teacher, lowAdmin])],
                    breadcrumbs: [
                        { route: () => ({ name: 'students' }), text: () => 'Listado de estudiantes' },
                        { route: ({ params }) => ({ name: 'student', params }), text: ({ store }) => `${store.state.students.current?.first_name} ${store.state.students.current?.last_name}`, loading: ({ store }) => store.state.students.loading },
                        { route: () => ({ name: 'students' }), text: () => 'bitácoras' },
                    ]
                }
            },
            {
                path: '/students/:student/:section/:course/courses',
                name: 'section_course_student_courses',
                component: () => import(/* webpackChunkName: "student_courses" */ '../../views/students/student/courses.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, coordinator, lowAdmin])],
                    breadcrumbs: [
                        { route: () => ({ name: 'students' }), text: () => 'Listado de estudiantes' },
                        { route: ({ params }) => ({ name: 'student', params }), text: ({ store }) => `${store.state.students.current?.first_name} ${store.state.students.current?.last_name}`, loading: ({ store }) => store.state.students.loading },
                        { route: () => ({ name: 'students' }), text: () => 'materias' },
                    ]
                }
            },
            {
                path: '/students/:student/:section/:course/absences',
                name: 'section_course_student_unattendances',
                component: () => import(/* webpackChunkName: "section_course_student_unattendances" */ '../../views/students/student/unattendances.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, coordinator, teacher, lowAdmin])],
                    breadcrumbs: [
                        { route: () => ({ name: 'students' }), text: () => 'Listado de estudiantes' },
                        { route: ({ params }) => ({ name: 'student', params }), text: ({ store }) => `${store.state.students.current?.first_name} ${store.state.students.current?.last_name}`, loading: ({ store }) => store.state.students.loading },
                        { route: () => ({ name: 'students' }), text: () => 'Inasistencias' },
                    ]
                }
            },
            {
                path: '/students/:student/:section/:course/lessons',
                name: 'section_course_student_lessons',
                component: () => import(/* webpackChunkName: "section_course_student_lessons" */ '../../views/students/student/lessons.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, coordinator, teacher, lowAdmin])],
                    breadcrumbs: [
                        { route: () => ({ name: 'students' }), text: () => 'Listado de estudiantes' },
                        { route: ({ params }) => ({ name: 'student', params }), text: ({ store }) => `${store.state.students.current?.first_name} ${store.state.students.current?.last_name}`, loading: ({ store }) => store.state.students.loading },
                        { route: () => ({ name: 'students' }), text: () => 'Clases' },
                    ]
                }
            },
            {
                path: '/students/:student/:section/:course/delays',
                name: 'section_course_student_delays',
                component: () => import('../../views/students/student/delayed.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, coordinator, teacher, lowAdmin, coordinator])],
                    breadcrumbs: [
                        { route: () => ({ name: 'students' }), text: () => 'Listado de estudiantes' },
                        { route: ({ params }) => ({ name: 'student', params }), text: ({ store }) => `${store.state.students.current?.first_name} ${store.state.students.current?.last_name}`, loading: ({ store }) => store.state.students.loading },
                        { route: () => ({ name: 'students' }), text: () => 'retrasos' },
                    ]
                }
            },
        ]
    }
]
