import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as configurations from './config'
import axios from 'axios'
import Api from './api'
import Directives from './directives'
import Filters from './filters'
import Components from './components'
import Plugins from './config/plugins'
import 'tailwindcss/tailwind.css'
import './assets/message.css'
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css'
import 'vue-select/dist/vue-select.css'
import 'verte/dist/verte.css'
import './prototypes'

Vue.use(Api, { store })
Vue.use(Components)
Vue.use(Directives)
Vue.use(Filters)
Vue.use(Plugins)

const configure = configuration => {
  Object.values(configuration)
    .forEach(configuration => configuration({
      vue: Vue,
      axios,
      router,
      store,
      lang: store.getters['lang'],
      notification: Vue.prototype.$message
    }))
}

/**
 * complete app configuration.
 * This will setup the router view render and access,
 * axios requests handlers, plugins installations
 * and will give access to the store, languages and every
 * needed instance to work
 */
configure(configurations)

Vue.prototype.$pick = function (object, ...props) {
    return props.reduce(function(result, prop) {
        result[prop] = object[prop];
        return result;
    }, {});
}

Vue.prototype.$coursesection = function (object) {
    return {
        id: object
    }
}

new Vue({
 router,
 store,
 render: h => h(App)
}).$mount('#app')

/**
 * while true, the devtools inspector plugin
 * will be enabled, set false in environment variables
 * to disable it
 */
let isConsoleEnabled = process.env.VUE_APP_ENABLE_DEV_CONSOLE === 'true';

Vue.config.productionTip = false
Vue.config.devtools = isConsoleEnabled;
Vue.config.debug = isConsoleEnabled;
Vue.config.silent = !isConsoleEnabled;
