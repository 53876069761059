export const URL = process.env.VUE_APP_NODE_ENV !== 'dev'
    ? process.env.VUE_APP_PRODUCTION_API_URL
    : process.env.VUE_APP_DEVELOPMENT_API_URL

export const Role = {
    student: 1,
    teacher: 2,
    coordinator: 3,
    lowAdmin: 4,
    admin: 5,
    all: 9999,
    none: -1
}

export const notificationTypes = {
    all: { value: 1, description: 'Enviar a todos', receiversText: 'todos' },
    admins: { value: 2, description: 'Enviar a administradores', receiversText: 'todos los administradores' },
    coordinators: { value: 3, description: 'Enviar a coordinadores', receiversText: 'todos los coordinadores' },
    teachers: { value: 4, description: 'Enviar a profesores', receiversText: 'todos los profesores' },
    students: { value: 5, description: 'Enviar a estudiantes', receiversText: 'todos los estudiantes' },
    auto: { value: 6, description: 'Envío automático', receiversText: 'envío automático' },
    single: { value: 7, description: 'Envía a:', receiversText: null },
    sectionStudents: { value: 8, description: 'Estudiantes de una sección' },
    courseSections: { value: 9, description: 'Estudiantes de una materia/seccion:' }
}

export const Errors = {
    route: {
        userIsUnauthenticated: 1,
        userIsUnauthorized: 2,
        userDoesntHasPermission: 3
    }
}