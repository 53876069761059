<template>
  <div>
    <template v-if="$route.meta.layout">
      <component :is="`${$route.meta.layout}-layout`" />
    </template>
    <template v-else>
      <transition name="fade-route" mode="out-in">
        <authenticated-layout v-if="isAuthenticated" @logout="sessionMessage = `Nos vemos luego ${userName}`"/>
        <guest-layout v-else />
      </transition>
    </template>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex'
import AuthenticatedLayout from '@/layouts/Authenticated/authenticated'
import GuestLayout from '@/layouts/Guest/guest'
export default {
    components: {
        AuthenticatedLayout,
        GuestLayout,
    },
    data: () => ({
        sessionMessage: 'Iniciando sesión',
        cancelling: false
    }),
    computed: {
        ...mapState({
            user: ({ session }) => session.user,
            transitioning: ({ session }) => session.transitioning
        }),
        ...mapGetters({
           isAuthenticated: 'session/isAuthenticated'
        }),
        userName() {

            const user = this.user

            if (user.isAuthenticated) {
              if (user.isAdmin || user.isLowAdmin) {
                return user.name
              }

              if (user.isTeacher || user.isCoordinator) {
                return `${user.teacher.first_name}`
              }

              return `${user.student.first_name}`
            } else {
              return ''
            }
        }
    },
    methods: {
        cancel() {
            let tokens = this.$store.state.cancelTokens

            if (tokens.length) {
                let last = tokens.slice(-1)

                if (last.length && last[0].cancel) {
                    last[0].cancel()
                    this.cancelling = !this.cancelling

                    setTimeout(() => {
                        this.cancelling = !this.cancelling
                    }, 2000)
                }
            }
        }
    },
    watch: {
        transitioning(val) {
            if (this.isAuthenticated) {
                setTimeout(() => {
                    this.sessionMessage = `Nos vemos luego ${this.userName}`
                }, !val ? 0 : 500)
            } else {
                this.sessionMessage = 'Iniciando sesión'
            }
        }
    }
};
</script>

<style scoped>
  .transitioning-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 10;
    backdrop-filter: blur(20px);
  }
</style>

<style>
.fade-route-enter-active, .fade-route-leave-active {
  transition: all .7s;
}

.fade-route-enter, .fade-route-leave-to {
  opacity: 0;
  //transform: scale(5);
  //transform: translateY(100vh);
}
</style>
