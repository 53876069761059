export default ({ axios, mapQueryString, withoutInvalidValues, toFormData }) => ({
    index: (filters = {}, config = {}) => axios.get(`/api/teachers${mapQueryString(filters)}`, config ),
    show: (id, filters = {}) => axios.get(`/api/teachers/${id}${mapQueryString(filters)}`),
    store: (body) => axios.post(`/api/teachers`, toFormData(withoutInvalidValues(body))),
    update: (id, body) => axios.post(`/api/teachers/${id}`, toFormData({
        ...withoutInvalidValues(body),
        '_method': 'PATCH',
    })),
    delete: (id) => axios.delete(`/api/teachers/${id}`),
    setCoordinator: (id, body) => axios.patch(`/api/teachers/${id}/is-coordinator`, body),
    setSpecialization: (id, body) => axios.patch(`/api/teachers/${id}`, withoutInvalidValues(body)),
    schedules: (id, filters = {}) => axios.get(`/api/teachers/${id}/schedules${mapQueryString(filters)}`),
    toggleEnabled: (id, body) => axios.patch(`/api/teachers/${id}`, body), // body -> { enabled: Bool }
    lessons: (id, filters = {}) => axios.get(`/api/teachers/${id}/lessons${mapQueryString(filters)}`),
    courses: (id, filters = {}) => axios.get(`/api/teachers/${id}/courses${mapQueryString(filters)}`), // this is course_sections, not courses,
    historic: (id, filters = {}) => axios.get(`/api/teachers/${id}/historic${mapQueryString(filters)}`),
    assign: (id, body) => axios.post(`/api/teachers/${id}/assign-teachers`, body), // for coordinators
    coursesReports: (teacherId) => axios.get(`/api/teachers/${teacherId}/reports/courses`)
})
