import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        all: [],
        course: null,
        loading: false
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET_COURSE: (state, course) => state.course = course,
        SET_COURSES: (state, sections) => state.all = sections,
        DROP_COURSE: (state, index) => state.pagination.data.splice(index, 1),
        TOGGLE_LOADING: (state) => state.loading = !state.loading
    },
    actions: {
        index({ commit }, query) {
            commit('TOGGLE_LOADING')
            return this.$repository.courses
                .index(query)
                .then(({ data }) => {
                    commit(query?.limit === 0 ? 'SET_COURSES' : 'SET_PAGINATION', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        show({ commit }, {id, filters}) {
            commit('TOGGLE_LOADING')
            return this.$repository.courses
                .show(id, filters)
                .then(({ data }) => {
                    commit('SET_COURSE', data)
                    return data
                }).finally(() => commit('TOGGLE_LOADING'))
        },
        store({ commit }, course) {
            commit('TOGGLE_LOADING')

            return this.$repository.courses
                .store(course)
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado la materia con éxito`,
                        
                    })

                    return data
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        update({ commit }, course) {
            commit('TOGGLE_LOADING')
            return this.$repository.courses
                .update(course.id, course)
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        message: `Se ha actualizado la materia con éxito`,
                        
                    })
                    
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        delete({ commit, state }, id) {
            if (confirm('¿Desea eliminar esta materia?')) {
                return this.$repository.courses
                    .delete(id)
                    .then(() => {
                        const index = state.pagination.data.findIndex(course => course.id === id)
                        commit('DROP_COURSE', index)
                    })
            }
        }
    }
}
