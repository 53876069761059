import router from '@/router';
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        all: [],
        current: null,
        students: [],
        loading: false
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET: (state, current) => state.current = current,
        SET_STUDENTS: (state, students) => state.students = students,
        SET_ALL: (state, sections) => state.all = sections,
        DROP: (state, index) => state.pagination.data.splice(index, 1),
        TOGGLE_LOADING: state => state.loading = !state.loading
    },
    actions: {
        fetchAll({ commit }, filters = {}) {
            commit('TOGGLE_LOADING')
            this.$repository.finalDeliveries
                .index(filters)
                .then(res => commit(filters?.limit === 0 ? 'SET_ALL' : 'SET_PAGINATION', res.data))
                // .then(() => filters?.limit !== 0 && router.push({ name: 'final_deliveries', query: { page: filters.page } }))
                .finally(() => commit('TOGGLE_LOADING'))
        },
        fetch({ commit }, { id, filters = {}}) {
            commit('TOGGLE_LOADING')
            this.$repository.finalDeliveries
                .show(id, filters)
                .then(res => {
                    commit('SET', res.data)
                    commit('SET_NAVBAR_TITLE', ['Listado de entregas finales', res.data.code, 'Editar entrega final'], { root: true })
                }).finally(() => commit('TOGGLE_LOADING'))
        },
        fetchStudents({ commit }, { id, filters = {}}) {
            commit('TOGGLE_LOADING')
            this.$repository.finalDeliveries
                .showStudents(id, filters)
                .then(res => {
                    commit('SET_STUDENTS', res.data)
                }).finally(() => commit('TOGGLE_LOADING'))
        },
        store({ commit }, item) {
            commit('TOGGLE_LOADING')

            this.$repository.finalDeliveries
                .store(item)
                .then((res) => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado la entrega final con éxito`,

                    })

                    router.push({ name: 'update_final_delivery', params: { delivery_id: res.data.id } })
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        update({ commit }, item) {
            commit('TOGGLE_LOADING')
            this.$repository.finalDeliveries
                .update(item.id, item)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha actualizado la entrega final con éxito`,

                    })
                    router.push({ name: 'final_deliveries' })
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        delete({ commit, state }, id) {
            Vue.prototype.$confirm({
                message: `¿Desea eliminar esta entrega final?`,
                button: {
                    no: 'No',
                    yes: 'Si'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$repository.finalDeliveries
                            .delete(id)
                            .then(() => {
                                const index = state.pagination.data.findIndex(item => item.id === id)
                                commit('DROP', index)
                            })
                    }
                }
            })
        }
    }
}
