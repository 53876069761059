<template>
    <div class="flex flex-col items-center">
        <label for="file_input" :class="size" class="shadow-md relative flex rounded-full bg-gradient-to-br from-blue-400 to-blue-700 items-center justify-center group cursor-pointer ring-2 ring-gray-200">
            <a-icon v-if="!isLoading" name="camera" class="text-2xl absolute object-center text-white group-hover:scale-150 transition-transform transform" :class="picture && 'text-opacity-50'" />
            <template>
                <div class="flex items-center justify-center rounded-full bg-center" :class="size" :style="{
                    'background-image': `url(${picture})`,
                    'background-size': '100%',
                }">
                    <div class="backdrop-animation" v-if="isLoading">
                        <div class="lds-roller">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </template>
        </label>

        <transition name="fade" mode="out-in">
          <button class="mt-2 text-red-400" type="button" v-if="value" @click="removePhoto">Remover foto</button>
        </transition>

        <input type="file" v-show="false" ref="input" :disabled="loading" id="file_input" @change="handlePhotoSelection">
    </div>
</template>

<script>
export default {
    props: {
        value: { required: false, type: [String, File], default: undefined },
        size: { required: false, type: String, default: 'w-32 h-32' },
        loading: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        picture: undefined,
        reading: false
    }),
    computed: {
        isLoading() {
            return this.reading || this.loading
        }
    },
    watch: {
        value(val) {
            if (typeof val === 'string') {
                this.load(val, (base64) => {
                    this.picture = base64
                })
            }
        }
    },
    methods: {
        handlePhotoSelection(event) {

            const reader = new FileReader()
            
            reader.onload = () => {
                this.picture = reader.result
                this.$emit('input', event.target.files[0])
            }

            if (event.target.files.length) {
                reader.readAsDataURL(event.target.files[0])
            }
        },
        removePhoto() {
            this.$refs.input?.clear?.()
            this.$refs.input.type = 'text'
            this.$refs.input.type = 'file'
            this.picture = undefined
            this.$emit('input', null)
        },
        async load(url, cb) {
            this.reading = !this.reading
            const response = await fetch(`https://cors-anywhere.herokuapp.com/${url}`);
            // here image is url/location of image
            const blob = await response.blob();
            
            const reader = new FileReader()

            const file = new File([blob], 'image.jpg', {type: blob.type})
            this.reading = !this.reading

            reader.onload = () => {
                cb(reader.result)    
            }

            reader.readAsDataURL(file)
        }
    }
}
</script>

<style scoped>

.backdrop-animation {
    background-color: rgba(0, 0, 0, .2);
    backdrop-filter: blur(5px);
    width: 128px;
    height: 128px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.transitioning-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 10;
    backdrop-filter: blur(20px);
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}
.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}
.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}
.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}
.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}
.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}
.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}
.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}
.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}
@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
