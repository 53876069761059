import { Role } from '@/constants'

import activities from './activities'
import administrators from './administrators'
import app from './app'
import binnacles from './binnacles'
import classhours from './classhours'
import classrooms from './classrooms'
import courses from './courses'
import documentRequests from './documentRequests'
import documents from './documents'
import finalDeliveries from './finalDeliveries'
import holidays from './holidays'
import misc from './misc'
import notifications from './notifications'
import payments from './payments'
import pensums from './pensums'
import periods from './periods'
import preinscriptions from './preinscriptions'
import prelations from './prelations'
import publicRoutes from './public'
import schedules from './schedules'
import sections from './sections'
import specializations from './specializations'
import students from './students'
import teachers from './teachers'
import lessons from './lessons'
// import models from './models'
import withdrawals from './withdrawals'

export default [
  activities,
  administrators,
  app,
  binnacles,
  classhours,
  classrooms,
  courses,
  documentRequests,
  documents,
  finalDeliveries,
  holidays,
  misc,
  notifications,
  payments,
  pensums,
  periods,
  preinscriptions,
  prelations,
  publicRoutes,
  schedules,
  sections,
  specializations,
  students,
  teachers,
  lessons,
  // models,
  withdrawals
].reduce((routes, route) => [...routes, ...route(Role)], [])