const repository = ({ axios, mapQueryString, withoutInvalidValues }) => ({
    index: (query = {}) => axios.get(`/api/activities${mapQueryString(query)}`),
    show: (id) => axios.get(`/api/activities/${id}`),
    store: (body) => axios.post(`/api/activities`, withoutInvalidValues(body)),
    update: (id, body) => axios.patch(`/api/activities/${id}`, withoutInvalidValues(body)),
    delete: (id) => axios.delete(`/api/activities/${id}`),
})

/**
 * @module activities
 */
export default repository
