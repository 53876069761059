import {Authenticated, AllowedForTypes} from '@/router/middlewares';

const base = {
    index: { url: () => ({ name: 'schedules' }), title: 'Listado de horarios'},
    create: { url: () => ({ name: 'update_schedule' }), title: 'Crear horario'},
    edit: { url: (route) => route, title: 'Editar horario' }
}

export default ({ admin, lowAdmin }) => [
	{
        path: '/schedules',
        name: 'schedules',
        component: () => import(/* webpackChunkName: "schedules" */ '../../views/schedules/new-index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, lowAdmin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title }
            ]
        }
    },
    {
        path: '/schedules/show',
        name: 'show_schedules',
        component: () => import(/* webpackChunkName: "schedules" */ '../../views/schedules/old.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title }
            ]
        }
    },
    {
        path: '/schedules/disponibilities',
        name: 'disponibility_schedules',
        component: () => import('../../views/schedules/disponibility.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],

        }
    },
    // {
    //     path: '/schedules/make',
    //     name: 'create_schedule',
    //     component: () => import(/* webpackChunkName: "create_schedule" */ '../../views/schedules/create.vue'),
    //     meta: {
    //         requiresAuthentication: true,
    //         allowedFor: [admin]
    //     }
    // },

    {
        path: '/schedules/edit/:section_id/:course_id/:schedule_id',
        name: 'update_schedule',
        component: () => import(/* webpackChunkName: "create_schedule" */ '../../views/schedules/update.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
                { route: (route) => route, text: ({ store }) => `${store.state.schedules.current?.id || 'Cargando...'}` },
                { route: base.edit.url, text: () => base.edit.title }
            ]
        }
    }
]
