import {Authenticated, AllowedForTypes} from '@/router/middlewares';

const base = {
    index: { url: () => ({ name: 'periods' }), title: 'Listado de períodos'},
    create: { url: () => ({ name: 'create_period' }), title: 'Crear período'},
    edit: { url: (route) => route, title: 'Editar período' }
}

export default ({ admin, lowAdmin }) => [
    {
        path: '/periods',
        name: 'periods',
        component: () => import(/* webpackChunkName: "periods" */ '../../views/periods/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, lowAdmin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
            ]
        }
    },
    {
        path: '/periods/make',
        name: 'create_period',
        component: () => import(/* webpackChunkName: "create_period" */ '../../views/periods/create.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
                { route: () => ({ name: 'create_period' }), text: ({ route, store }) => {
                    if (route.query.update) {
                        return `Actualizar período ${store.state.periods.period?.year}-${store.state.periods.period?.number}`
                    }

                    return 'Crear período'
                }, loading: ({ store }) => store.state.periods.loading },
            ]
        }
    },
    {
        path: '/periods/:id',
        name: 'periods.show',
        component: () => import(/* webpackChunkName: "periods.show" */ '../../views/periods/show.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, lowAdmin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
                { route: ({ route }) => ({ name: 'periods.show', params: route.params }), text: ({store:{state:{periods:{period}}}}) => period ? `${period.year}-${period.number}` : 'No encontrado', loading: ({store:{state:{periods:{loading}}}}) => loading }
            ]
        }
    }
]
