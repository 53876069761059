import {default as boolean} from './boolean'
import {default as byteReadable} from './byteReadable'
import {default as capitalize} from './capitalize'
import {default as date} from './date'
import {default as h24} from './h24'
import {default as initials} from './initials'
import {default as json} from './json'
import {default as roman} from './roman'
import {default as timePadding} from './timePadding'
import {default as truncate} from './truncate'
import {default as intl} from './intl'
import {default as moment} from './moment'

export default {
  install: (Vue) => {
    Vue.filter('boolean', boolean)
    Vue.filter('byteReadable', byteReadable)
    Vue.filter('capitalize', capitalize)
    Vue.filter('date', date)
    Vue.filter('h24', h24)
    Vue.filter('initials', initials)
    Vue.filter('json', json)
    Vue.filter('roman', roman)
    Vue.filter('timePadding', timePadding)
    Vue.filter('truncate', truncate)
    Vue.filter('intl', intl)
    Vue.filter('moment', moment)
  }
}
