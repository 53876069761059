export default (types = []) => ({ next, store: { state } }) => {

    const user = state.session.user?.user ?? state.session.user

    if (!user) {
        return next({ name: 'empty' })
    }

    if (!types.includes(user.type)) {
        return next({ name: 'access-denied' })
    }

    return next()
}
