<template>
    <div class="rounded-lg bg-gray-100 items-center flex py-1 px-4" @click="$emit('click', $event)">
      <span>
        <slot />
      </span>

      <button type="button" v-if="$listeners.close" class="focus:outline-none border-l border-black border-opacity-10 -mr-4 -my-1 ml-2 px-1 rounded-r-lg" @click="$emit('close')">
        <slot name="icon">
          <svg viewBox="0 0 35 35" width="25" height="25" fill="currentColor">
            <path d="M19.5,17.5l5.1,5.1l-2,2l-5.1-5.1l-5.1,5.1l-2-2l5.1-5.1l-5.1-5.1l2-2l5.1,5.1l5.1-5.1l2,2L19.5,17.5z"></path>
          </svg>
        </slot>
      </button>
    </div>
</template>

<script>
export default {
  name: `badge`,
  props: {
      dot: { required: false, type: Boolean, default: null },
      scheme: { required: false, type: String, default: 'default' }
  }
}
</script>