<template>
  <svg viewBox="0 0 20 22">
    <g id="Linear" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Users" transform="translate(-110.000000, -189.000000)">
        <g id="user" transform="translate(108.000000, 188.000000)">
          <path d="M12,12 C9.23857617,12 7,9.76142383 7,7 C7,4.23857617 9.23857617,2 12,2 C14.7614238,2 17,4.23857617 17,7 C17,9.76142383 14.7614238,12 12,12 Z" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0"></path>
          <path d="M20.5899048,22 C20.5899048,18.1300001 16.7399082,15 11.9999084,15 C7.25990868,15 3.40991211,18.1300001 3.40991211,22" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
          <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" id="Vector" opacity="0"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "user"
}
</script>