import { AllowedForTypes } from '@/router/middlewares'

export default({ admin }) => [
    {
        path: '/withdrawals',
        name: 'students.withdrawals.index',
        component: () => import(/* webpackChunkName: "students.withdrawals.index"*/ '../../views/students/withdrawals/index.vue'),
        meta: {
            middleware: AllowedForTypes([admin]),
            breadcrumbs: [
                { route: () => ({ name: 'students' }), text: () => 'Listado de estudiantes' }
            ]
        }
    }
]