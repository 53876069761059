import router from '@/router';
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        administrator: null,
        all: [],
        loading: false
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET_ADMINISTRATOR: (state, administrator) => state.administrator = administrator,
        SET_ADMINISTRATORS: (state, administrators) => state.all = administrators,
        DROP_ADMINISTRATOR: (state, index) => state.pagination.data.splice(index, 1),
        TOGGLE_LOADING: state => state.loading = !state.loading
    },
    actions: {
        index({ commit }, filters = {}) {
            commit('TOGGLE_LOADING')
            return this.$repository.users
                .index(filters)
                .then(res => {
                    commit(filters?.limit === 0 ? 'SET_ADMINISTRATORS' : 'SET_PAGINATION', res.data)
                    return res
                })
                // .then(res => {
                //     if (router.history.current.name === 'administrators' && filters?.limit !== 0) {
                //         router.push({ name: 'administrators', query: { page: filters.page } })
                //     }
                //     return res
                // })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        show({ commit }, { id }) {
            commit('TOGGLE_LOADING')
            return this.$repository.users
                .show(id)
                .then(res => {
                    commit('SET_ADMINISTRATOR', res.data)
                    return res
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        store({ commit }, administrator) {

            const data = new FormData()
            Object.entries(administrator).forEach(entry => data.append(entry[0], entry[1]))

            commit('TOGGLE_LOADING')
            return this.$repository.users
                .store(data)
                .then(res => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado el administrador con éxito`,
                        
                    })
                    router.push({ name: 'administrators' })
                    return res
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        update({ commit }, administrator) {
            commit('TOGGLE_LOADING')
            return this.$repository.users
                .update(administrator.id, administrator)
                .then(res => {
                    Vue.prototype.$message.success({
                        message: `Se ha actualizado el administrador con éxito`,
                        
                    })
                    router.push({ name: 'administrators' })
                    return res
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        delete({ commit, state }, { id, back = false }) {
            if (confirm('¿Desea eliminar este administrador?')) {
                return this.$repository.users
                    .delete(id)
                    .then(res => {
                        if (back) {
                            router.push({ name: 'administrators' })
                        } else {
                            const index = state.pagination.data.findIndex(administrator => administrator.id === id)
                            if (index !== -1) {
                                commit('DROP_ADMINISTRATOR', index)
                            }
                        }
                        return res
                    })
            }
        }
    }
}
