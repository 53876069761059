<template>
    <div class="flex flex-col">
        <span v-if="label" class="font-medium block">
            {{ label }}
            <span v-if="required" class="text-red-600 font-bold">*</span>
        </span>
        <small v-if="hint" class="text-gray-600">{{ hint }}</small>
        <v-select
            class="bg-white"
            :class="{
                'mt-2': hint || label || required || $slots.label
            }"
            :options="source"
            :reduce="reduce"
            :value="value"
            :loading="loading"
            :disabled="disabled"
            :placeholder="placeholder"
            :multiple="multiple"
            :clearable="clearable"
            :selectable="selectable"
            :closeOnSelect="!keepOpenOnSelect"
            @change="$emit('change', $event)"
            @input="$emit('input', $event)"
            />
        <div class="flex justify-between">
            <span class="text-red-500 mt-1" v-if="error">
                {{ error }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    required: { required: false, type: Boolean, default: false },
    placeholder: { required: false, type: String, default: '' },
    source: { required: false, type: Array, default: () => [] },
    value: { required: false, type: [String, Number, Array, Object], default: null },
    loading: { required: false, type: Boolean, default: false },
    multiple: { required: false, type: Boolean, default: false },
    disabled: { required: false, type: Boolean, default: false },
    clearable: { required: false, type: Boolean, default: false },
    reduce: { required: false, type: Function, default: $0 => $0.value },
    label: { required: false, type: String, default: undefined },
    hint: { required: false, type: String, default: undefined },
    error: { required: false, type: String, default: undefined },
    help: { required: false, type: String, default: undefined },
    keepOpenOnSelect: { required: false, type: Boolean, default: false },
    selectable: { required: false, type: Function, default: () => true }
  }
}
</script>
