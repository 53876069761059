import { storeObject, getObject, removeObject } from '@/helpers/storage'
import router from '@/router'
import Vue from 'vue'
import { Role } from '@/constants';
import axios from 'axios';

const getProfilePicture = (user) => {
  const path = user.profile_picture

  if (path) {
    if (path.split('/').slice(-1)[0] === 'default-profile-picture.png') {
      return null
    }

    return path
  }

  return null
}

export default {
  namespaced: true,
  state: {
    user: getObject('session') ? getObject('session') : {},
    loading: false,
    /**
     * @description this values is used just to display a transitioning overlay
     * in the page while the users is logging in or logging out
     */
    transitioning: false
  },
  mutations: {
    SET_LOADING: state => state.loading = !state.loading,
    SET_USER: (state, user) => state.user = user,
    TOGGLE_TRANSITIONING: (state) => state.transitioning = !state.transitioning
  },
  actions: {
    async login({ commit, dispatch }, user) {
      commit('SET_LOADING')
      commit('TOGGLE_TRANSITIONING')

      try {
        const response = await this.$repository.authentication.login(user)

        return new Promise((resolve, reject) => {
            const user = response?.data?.user

            if (user.type === Role.student) {

              return this.$repository.students
                  .show(user?.student?.id, {
                    token: response?.data?.access_token,
                    with: `latestInscription.specialization,latestInscription.period,latestInscription.semester,latestInscription.payments`
                  })
                  .then(({ data }) => {
                    response.data.user.student = data
                    dispatch('configure', response)
                    resolve(response?.data)
                  })
                  .catch(reject)
            }

            dispatch('configure', response)
            resolve(response.data)
          })
      } catch(error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING')
      }
    },
    logout({ dispatch, commit }) {
      commit('TOGGLE_TRANSITIONING')
      this.$repository.authentication
          .logout()
          .finally(() => {
            dispatch('cancelPendingRequest', null, { root: true });
            dispatch('vanish')
          })
    },
    passwordReset({ commit }, email) {
      if (email) {
        commit('SET_LOADING')
        this.$repository.authentication
            .passwordReset(email)
            .finally(() => commit('SET_LOADING'))
      }
    },
    passwordConfirm({ commit }, user) {
      commit('SET_LOADING')
      this.$repository.authentication
          .passwordConfirm(user, { cancellable: false })
          .then(res => {
            Vue.prototype.$message.success({
              message: `Su contraseña ha sido cambiada`,

            })
            return res
          })
          .finally(() => {
            commit('SET_LOADING')
            router.push({ name: 'empty' })
          })
    },
    confirmAccount({ commit }, guest) {
      commit('SET_LOADING')
      this.$repository.authentication
          .confirmAccount(guest)
          .then(res => {
            Vue.prototype.$message.success({
              message: `Su cuenta ha sido confirmada`,

            })

            return res
          })
          .finally(() => {
            commit('SET_LOADING')
            router.push({ name: 'empty' })
          })
    },
    vanish({ commit }) {
      commit('SET_USER', {})
      removeObject('session')
      removeObject(process.env.VUE_APP_USER_TOKEN_NAME)
      removeObject(process.env.VUE_APP_USER_TOKEN_EXPIRATION_DATE)
      router.push({ name: 'empty' })
    },
    configure({ commit }, response) {

      let token = response.data.access_token

      localStorage.setItem(process.env.VUE_APP_USER_TOKEN_NAME, token)
      localStorage.setItem(process.env.VUE_APP_USER_TOKEN_EXPIRATION_DATE, response.data.expires_in_datetime)

      let user = response.data.user
      user = {
        ...user,
        isAdmin: user.type === Role.admin,
        isTeacher: user.type === Role.teacher,
        isCoordinator: user.type === Role.coordinator,
        isStudent: user.type === Role.student,
        isLowAdmin: user.type === Role.lowAdmin,
        isAdminGroup: [Role.lowAdmin, Role.admin].includes(user.type),
        isTeacherGroup: [Role.teacher, Role.coordinator].includes(user.type),
        profile_picture: getProfilePicture(user)
      }

      commit('SET_USER', user)
      storeObject('session', user)
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      return user
    },
    setNewToken(_, token) {
      localStorage.setItem(process.env.VUE_APP_USER_TOKEN_NAME, token)
    },
    update({ state, dispatch }, form) {

      const fireNotification = () => {
        Vue.prototype.$message.success({
          message: `Sus datos han sido actualizados`,

        })
      }

      if (state.user.isAdmin) {
        return this.$repository.users
            .update(state.user.id, form)
            .then(res => {

              dispatch('configure', {
                data: {
                  access_token: localStorage.getItem(process.env.VUE_APP_USER_TOKEN_NAME),
                  user: {
                    ...state.user,
                    ...res.data
                  }
                }
              })

              fireNotification()
            })
      }

      if (state.user.isTeacher || this.user.isCoordinator) {
        return this.$repository.teachers
            .update(state.user.teacher.id, form)
            .then(res => {
              dispatch('configure', {
                data: {
                  access_token: localStorage.getItem(process.env.VUE_APP_USER_TOKEN_NAME),
                  user: {
                    ...state.user,
                    teacher: res.data
                  }
                }
              })

              fireNotification()
            })
      }

      return new Promise((resolve, reject) => reject(`Unhandled update for user type ${state.user.type}`))
    },
    sendRecoveryEmail(_, guest) {
      this.$repository.authentication
        .passwordReset(guest.email)
        .then(() => {
          Vue.prototype.$message.success({
            message: `Se ha enviado su correo de recuperación`,
          })
        })
      // axios.post(`/api/password-reset/${guest.email}`)
      //     .then(() => {
      //       Vue.prototype.$message.success({
      //         message: `Se ha enviado su correo de recuperación`,
      //
      //       })
      //     })
    },
    updatePicture({ state, dispatch }, profile_picture) {

      return this.$repository.users
          .updatePicture(state.user.id, { profile_picture })
          .then(res => {

            dispatch('configure', {
              data: {
                access_token: localStorage.getItem(process.env.VUE_APP_USER_TOKEN_NAME),
                user: {
                  ...state.user,
                  ...res.data
                }
              }
            })

            Vue.prototype.$message.success({
              message: `su foto de perfil ha sido actualizada`,

            })
          })
    },
    updateEmail({ state, dispatch }, body) {
      return this.$repository.users
          .updateEmail(state.user.id, body)
          .then(() => {
            dispatch('configure', {
              data: {
                access_token: localStorage.getItem(process.env.VUE_APP_USER_TOKEN_NAME),
                user: {
                  ...state.user,
                  email: body.new_email,
                  teacher: state.user.isTeacher || state.user.isCoordinator ? {
                    ...state.user.teacher,
                    email: body.new_email
                  } : undefined,
                  student: state.user.isStudent ? {
                    ...state.user.student,
                    email: body.new_email
                  } : undefined
                }
              }
            })

            Vue.prototype.$message.success({
              message: `Su email ha sido actualizado`,

            })
          })
    },
    updatePassword({ state }, body) {
      return this.$repository.users
          .updatePassword(state.user.id, body)
    }
  },
  getters: {
    isAuthenticated: ({ user }) => localStorage.getItem(process.env.VUE_APP_USER_TOKEN_NAME) && Object.keys(user).length > 0,
    isAdmin: ({ user }) => user.type === Role.admin,
    isTeacher: ({ user }) => user.type === Role.teacher,
    isCoordinator: ({ user }) => user.type === Role.coordinator,
    isStudent: ({ user }) => user.type === Role.student,
    isLowAdmin: ({ user }) => user.type === Role.lowAdmin,
    fullName: ({ user }) => {
      if (user.isAdmin || user.isLowAdmin) {
        return user.name
      }

      if (user.isTeacher || user.isCoordinator) {
        return `${user.teacher.first_name} ${user.teacher.last_name}`
      }

      return `${user.student.first_name} ${user.student.last_name}`
    }
  }
}
