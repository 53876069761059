import SidebarLogo from './ui/layout/sidebar/SidebarLogo.vue';
import SidebarItem from './ui/layout/sidebar/SidebarItem.vue';
import Dropdown from './ui/dropdown/Dropdown.vue';
import DropdownItem from './ui/dropdown/DropdownItem.vue';
import Divider from './ui/divider/Divider.vue';
import Badge from './ui/badge/Badge.vue';
import Button from './ui/button/Button.vue';
import Input from './ui/input/Input.vue';
import Card from './ui/card/Card.vue';
import Select from './ui/select/Select.vue';
import Checkbox from './ui/checkbox/Checkbox.vue';
import Table from './ui/table/Table.vue';
import Pagination from './ui/pagination/Pagination.vue';
import List from './ui/list/List.vue';
import Switch from './ui/switch/Switch.vue';
import UploadInput from './ui/input/Upload.vue';
import Modal from './ui/modal/Modal';
import ButtonGroup from './ui/button/ButtonGroup';
import Avatar from './ui/Avatar.vue';
import SegmentedControl from './ui/segmentedControl/SegmentedControl.vue';
import Separator from './ui/Separator.vue';
import PeriodAccessor from './ui/PeriodAccesor.vue'
import PhotoSelector from './ui/PhotoSelector.vue'
import Skeleton from './ui/Skeleton';
import Icon from './ui/icon/icon';
import { Message } from './ui/dialogs'
import Loader from './ui/Loader'

import AllowOnly from './context/allow-only'
import store from '../store'

export default {
  install: (Vue) => {
    // ui
    Vue.component(`a-sidebar-logo`, SidebarLogo)
    Vue.component(`a-sidebar-item`, SidebarItem)
    Vue.component(`a-dropdown`, Dropdown)
    Vue.component(`a-dropdown-item`, DropdownItem)
    Vue.component(`a-divider`, Divider)
    Vue.component(`a-badge`, Badge)
    Vue.component(`a-button`, Button)
    Vue.component(`a-input`, Input)
    Vue.component(`a-card`, Card)
    Vue.component(`a-select`, Select)
    Vue.component(`a-checkbox`, Checkbox)
    Vue.component(`a-table`, Table)
    Vue.component(`a-paginate`, Pagination)
    Vue.component(`a-list`, List)
    Vue.component(`a-switch`, Switch)
    Vue.component(`a-upload-input`, UploadInput)
    Vue.component(`a-modal`, Modal)
    Vue.component(`a-button-group`, ButtonGroup)
    Vue.component(`a-avatar`, Avatar)
    Vue.component(`a-segmented-control`, SegmentedControl)
    Vue.component(`a-separator`, Separator)
    Vue.component(`a-period-accessor`, PeriodAccessor)
    Vue.component(`a-photo-selector`, PhotoSelector)
    Vue.component(`a-skeleton`, Skeleton)
    Vue.component(`a-icon`, Icon)
    Vue.component(`a-loader`, Loader)

    // context
    Vue.component(`allow-only`, AllowOnly)

    Vue.use(Message, { store })
  }
}