import { Authenticated, AllowedForTypes } from '@/router/middlewares';

const base = {
    index: { url: () => ({ name: 'payments' }), title: 'Listado de pagos'},
    create: { url: () => ({ name: 'create_payment' }), title: 'Crear pago'},
    edit: { url: (route) => route, title: 'Editar pago' }
}

export default ({ admin, lowAdmin }) => [
	{
        path: '/payments',
        name: 'payments',
        component: () => import(/* webpackChunkName: "payments" */ '../../views/payments/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, lowAdmin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title }
            ]
        }
    },
    {
        path: '/payments/create',
        name: 'create_payment',
        component: () => import(/* webpackChunkName: "create_payment" */ '../../views/payments/create.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
                { route: base.create.url, text: () => base.create.title }
            ]
        }
    },
]
