import router from '@/router';
import Vue from 'vue';
export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        all: [],
        current: null,
        loading: false
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET: (state, current) => state.current = current,
        SET_ALL: (state, sections) => state.all = sections,
        DROP: (state, index) => state.pagination.data.splice(index, 1),
        TOGGLE_LOADING: state => state.loading = !state.loading
    },
    actions: {
        fetchAll({ commit }, filters = {}) {
            commit('TOGGLE_LOADING')
            return this.$repository.schedules
                .index(filters)
                .then(res => {
                    commit(filters?.limit === 0 ? 'SET_ALL' : 'SET_PAGINATION', res.data)
                    return res
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        fetch({ commit }, { id, filters }) {
            return this.$repository.schedules
                .show(id, filters)
                .then(res => {
                    commit('SET', res.data)
                    return res
                })
        },
        store({ commit }, item) {
            commit('TOGGLE_LOADING')

            Object.entries(item)
                .forEach(it => {
                    if (it[1] === '') {
                        delete item[it[0]]
                    }
                })

            this.$repository.schedules
                .store(item)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado el horario con éxito`,

                    })
                    router.push({ name: 'schedules' })
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        bulkStore({ commit }, bulk) {
            commit('TOGGLE_LOADING')

            bulk.schedules = bulk.schedules.filter(e => e.class_hours_ids.length)

            return this.$repository.schedules
                .bulkStore(bulk)
                .then(res => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado el horario con éxito`,
                    })
                    return res
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        update({ commit }, item) {
            commit('TOGGLE_LOADING')

            this.$repository.schedules
                .update(item.id, item)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha actualizado el horario con éxito`,

                    })
                    router.push({ name: 'schedules' })
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        delete({ commit, state }, id) {
            if (confirm('¿Desea eliminar este horario?')) {
                this.$repository.schedules
                    .delete(id)
                    .then(() => {
                        const index = state.pagination.data.findIndex(item => item.id === id)
                        commit('DROP', index)
                    })
            }
        }
    },
    getters: {
        formattedScheduleColumns: state => {

            let classrooms = []

            state.all.forEach(it => {
                let found = classrooms.find(c => c.id === it.classroom.id)
                if (!found) {
                    classrooms.push(it.classroom)
                }
            })

            return [
                { title: 'Hora', slot: 'hour', enabled: true, style: () => ({ minWidth: '120px' }) },
                ...classrooms.map(it => ({ title: `Salón ${it.code}`, key: 'course_section.course.title', enabled: true }))
            ]
        }
    }
}
