export default ({ axios, mapQueryString }) => ({
    show: (id, filters = {}) => axios.get(`/api/lessons/${id}${mapQueryString(filters)}`),
    store: (body) => axios.post(`/api/lessons`, body),
    delete: (id) => axios.delete(`/api/lessons/${id}`),
    update: (id, body) => axios.patch(`/api/lessons/${id}`, body),
    showByCourseSection: (section_id, course_id, filters = {}) => axios.get(`/api/sections/${section_id}/courses/${course_id}/lessons${mapQueryString(filters)}`),
    /**
     * used to update student notes from a lesson as teacher/coordinator
     * @param {*} lesson_id
     * @param {*} bulk
     * @returns
     */
    bulkUpdateStudents: (lesson_id, bulk) => axios.patch(`/api/lessons/${lesson_id}/students/bulk-update`, bulk),
    generate: (body, configuration = {}) => axios.post(`/api/lessons/generate`, body, configuration),
    register: (lesson, body, configuration = {}) => axios.patch(`/api/lessons/${lesson}/register`, body, configuration),
    edit: (lesson, body, configuration = {}) => axios.patch(`/api/lessons/${lesson}`, body, configuration),
    evaluate: (lesson, body, configuration = {}) => axios.patch(`/api/lessons/${lesson}/evaluate`, body, configuration),
})
