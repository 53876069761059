import { AllowedForTypes, Authenticated } from '@/router/middlewares';

export default ({ admin }) => [
	{
        path: '/activities',
        name: 'activities',
        component: () => import(/* webpackChunkName: "activities" */ '../../views/activities/index.vue'),
        meta: {
            middleware: [Authenticated],
            breadcrumbs: [
                { route: () => ({ name: 'activities' }), text: () => 'Listado de actividades' },
            ]
        }
    },
    {
        path: '/activities/create',
        name: 'create_activity',
        component: () => import(/* webpackChunkName: "create_activity" */ '../../views/activities/create.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: () => ({ name: 'activities' }), text: () => 'Listado de actividades' },
                { route: (route) => route, text: () => 'crear actividad' }
            ]
        }
    },
    {
        path: '/activities/:id/edit',
        name: 'edit_activity',
        component: () => import(/* webpackChunkName: "edit_activity" */ '../../views/activities/edit.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: () => ({ name: 'activities' }), text: () => 'Listado de actividades' },
                { route: (route) => route, text: ({ store }) => `${store.state.activities.current?.title}`, loading: ({ store }) => store.state.activities.loading },
                { route: (route) => route, text: () => 'editar actividad' }
            ]
        }
    },
]
