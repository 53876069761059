import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    pagination: {
      data: []
    },
    all: [],
    current: null,
    loadings: { index: false, show: false, store: false, update: false, delete: false }
  },
  mutations: {
    SET_PAGINATION: (state, pagination) => state.pagination = pagination,
    SET: (state, current) => state.current = current,
    SET_ALL: (state, sections) => state.all = sections,
    DROP: (state, index) => state.pagination.data.splice(index, 1),
    TOGGLE_LOADING: (state, loading) => state.loadings[loading] = !state.loadings[loading]
  },
  actions: {
    index({ commit }, query = {}) {
      commit('TOGGLE_LOADING', 'index')
      return this.$repository.holidays
        .index(query)
        .then(items => {
          commit(query?.limit === 0 ? 'SET_ALL' : 'SET_PAGINATION', items)
          return items
        })
        .finally(() => commit('TOGGLE_LOADING', 'index'))
    },
    show({ commit }, id) {
      commit('TOGGLE_LOADING', 'show')
      return this.$repository.holidays
        .show(id)
        .then(item => {
          commit('SET', item)
          return item
        }).finally(() => commit('TOGGLE_LOADING', 'show'))
    },
    store({ commit }, item) {
      commit('TOGGLE_LOADING', 'store')

      return this.$repository.holidays
        .store(item)
        .then(item => {
            this.$message.success({
              message: `Se ha creado el día feriado con éxito`
            })

            return item
        })
        .finally(() => commit('TOGGLE_LOADING', 'store'))
    },
    update({ commit }, item) {
      commit('TOGGLE_LOADING', 'update')

      return this.$repository.holidays
        .update(item.id, item)
        .then(item => {
          this.$message.success({
            message: `Se ha actualizado el día feriado con éxito`
          })

          return item
        })
        .finally(() => commit('TOGGLE_LOADING', 'update'))
    },
    delete({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$confirm({
          message: `¿Desea eliminar este día feriado?`,
          button: {
            no: 'No',
            yes: 'Si'
          },
          callback: confirm => {
            if (confirm) {
              commit('TOGGLE_LOADING', 'delete')
              this.$repository.holidays
                .delete(id)
                .then(item => {
                    const index = state.pagination.data.findIndex(item => item.id === id)
                    commit('DROP', index)
                    resolve(item)
                })
                .catch(reject)
                .finally(() => commit('TOGGLE_LOADING', 'delete'))
            }
          }
        })
      })
    }
  }
}
