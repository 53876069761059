export default ({ axios, mapQueryString }) => ({
    index: (filters = {}, configuration = {}) => axios.get(`/api/semesters${mapQueryString(filters)}`, configuration),
    show: (id) => axios.get(`/api/semesters/${id}?with=courses,sections`),
    store: (body) => axios.post(`/api/semesters`, body),
    update: (id, body) => axios.patch(`/api/semesters/${id}`, body),
    delete: (id) => axios.delete(`/api/semesters/${id}`),
    updateCourses: (id, body) => axios.put(`/api/semesters/${id}/courses`, body), // body -> [1, 2, 3]
    courses: (semester, filters = {}) => axios.get(`/api/semesters/${semester}/courses${mapQueryString(filters)}`),
    dashboard: () => axios.get('/api/dashboard/index'),
    courseSemesterUpdate: (semester_id, course_id, body) => axios.patch(`/api/semesters/${semester_id}/courses/${course_id}`, body)
})
