<template>
<div>
  <transition name="slide-up">
    <div v-if="showNotify">
      <div v-if="isPartialPaid" :class="{
        'animate-pulse': loadings.inscriptionPayment
      }" class="py-2 pb-3 flex-wrap gap-2 flex items-center px-4 shadow-lg justify-between bg-gray-900 fixed left-4 right-8 bg-opacity-80 backdrop-filter backdrop-blur-lg rounded-xl bottom-4 z-10">
        <div class="w-full text-right text-white">
          <!--        <a-icon name="star" class="w-full text-right text-white"/>-->
          <p class="block ml-auto cursor-pointer w-4" @click="showNotify = false">X</p>
        </div>
        <div class="flex flex-col">
          <h1 class="text-lg text-white text-center md:text-left">
            <template v-if="!isAdmin">
              {{ user.student.gender === 'female' ? 'Bienvenida' : 'Bienvenido'}}, {{ user.student.first_name }} {{ user.student.last_name }}. tienes una deuda pendiente
            </template>
            <template v-else>
              Este estudiante tiene pagos pendientes.
            </template>
          </h1>
          <h2 class="text-gray-200 text-center md:text-left">
            <!--          Es necesario un pago de $ {{ missingPaymentAmount }} para poder completar su inscripción-->
            Su deuda es de: ${{ missingPaymentAmount }}
          </h2>
<!--          <h3 class="text-sm text-gray-200 text-center md:text-left" v-if="pendingPayments.length">-->
<!--            {{ pendingPayments.length }} pagos pendientes por aprobar para un total de $ {{ (pendingPaymentsAmount / 100).toFixed(2) }}-->
<!--          </h3>-->
        </div>

        <div class="space-x-2">
          <a-button class="w-full md:w-auto" @click="modals.inscriptionPayment = true">
            Reportar pago
          </a-button>

          <a-button class="w-full md:w-auto" @click="verifyInscription">
            Refrescar
          </a-button>
        </div>
      </div>
    </div>
  </transition>

  <a-modal
    :show="modals.inscriptionPayment"
    @confirm="pay"
    @cancel="modals.inscriptionPayment = false">
    <div class="space-y-4">
      <a-select
          :source="[
          { label: 'Efectivo', value: 1 },
          { label: '100% Banco', value: 2 },
          { label: 'Transferencia Bancaria', value: 3 },
          { label: 'Transferencia de Curso', value: 4 },
          { label: 'Zelle', value: 5 },
        ]"
          label="Tipo de pago"
          required
          class="mb-4"
          :error="error('type', { attribute: 'tipo de pago' })"
          v-model="form.type"/>

      <a-input
          :required="form.type !== 1"
          :disabled="form.type === 1"
          label="Factura"
          class="mb-4"
          id="receipt_number"
          :error="error('receipt_number')"
          v-model="form.receipt_number" />

      <a-input
        label="Fecha"
        class="mb-4"
        required
        id="date"
        type="date"
        :error="error('date')"
        v-model="form.date" />

      <a-input
        label="Monto"
        required
        class="mb-4"
        id="amount"
        type="number"
        :error="error('amount')"
        v-model.number="form.amount" />

    </div>
  </a-modal>
</div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  props: {
    inscription: { required: true, type: Object },
    isAdmin: { required: false, type: Boolean, default: false },
  },
  data: () => ({
    form: {},
    showNotify: true,
    modals: {
      inscriptionPayment: false
    },
    loadings: {
      inscriptionPayment: false
    },
    shouldDisplayInscriptionPayment: false
  }),
  computed: {
    ...mapGetters({
      error: 'error'
    }),
    ...mapState({
      user: ({ session }) => session.user
    }),
    isPartialPaid () {
      return this.inscription?.partial_paid ?? false
    },
    payments () {
      // return (this.user.student?.latest_inscription?.payments ?? [])
      return this.inscription?.payments ?? []
    },
    pendingPayments () {
      return this.payments
        .filter(payment => payment.status === 1)
    },
    pendingPaymentsAmount () {
      return this.pendingPayments
        .reduce((accumulator, payment) => accumulator + payment.amount, 0)
    },
    missingPaymentAmount() {
      const semester_price = this.inscription?.semester_price_e2 ?? 0
      const current_paid_amount = this.inscription?.paid_amount_e2 ?? 0

      return ((semester_price - current_paid_amount - this.pendingPaymentsAmount) / 100).toFixed(2)
    }
  },
  methods: {
    ...mapActions({
      configure: 'session/configure'
    }),
    testing () {
      console.log(this.isPartialPaid)
    },
    verifyInscription() {
      this.loadings.inscriptionPayment = !this.loadings.inscriptionPayment
      this.$repository.students
        .show(this.inscription.student_id, {
          with: `latestInscription.specialization,latestInscription.period,latestInscription.semester,latestInscription.payments`
        })
        .then(({ data }) => {
          this.$emit('check', data)
        })
        .finally(() => this.loadings.inscriptionPayment = !this.loadings.inscriptionPayment)
    },
    pay() {
      this.$repository.inscriptions
        .pay(this.inscription.id, {
          ...this.form,
          amount: (this.form.amount ?? 0) * 100
        })
        .then(() => {
          this.modals.inscriptionPayment = false

          this.verifyInscription()

          this.$message.success({
            title: 'Éxito',
            message: 'El pago ha sido registrado'
          })
        })
    },
  }
}
</script>

<style>
.slide-up-enter-active, .slide-up-leave-active {
  transition: all .5s;
}

.slide-up-enter, .slide-up-leave-to {
  opacity: 0;
  transform: translateY(100px);
}
</style>
