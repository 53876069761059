export default ({ axios, mapQueryString }) => ({
    index: (filters = {}) => axios.get(`/api/final-deliveries${mapQueryString(filters)}`),
    show: (id, filters = {}) => axios.get(`/api/final-deliveries/${id}${mapQueryString(filters)}`),
    showStudents:(id, filters = {}) => axios.get(`/api/final-deliveries/${id}/students${mapQueryString(filters)}`),
    store: (body) => axios.post(`/api/final-deliveries`, body),
    update: (id, body) => axios.patch(`/api/final-deliveries/${id}`, body),
    delete: (id) => axios.delete(`/api/final-deliveries/${id}`),
    juries: (teacher_id) => ({
        registerNote: (final_delivery_id = {}, student_id, body = {} ) => axios.patch(`/api/final-deliveries/${final_delivery_id}/teachers/${teacher_id}/students/${student_id}`, body),
        attach: (final_delivery_id = {} ) => axios.post(`/api/final-deliveries/${final_delivery_id}/teachers/${teacher_id}`),
        detach: (final_delivery_id = {}) => axios.delete(`/api/final-deliveries/${final_delivery_id}/teachers/${teacher_id}`)
    })
})
