
<template>
	<transition name="m-message-fade" mode="in-out">
		<div class="inline-block mt-2 shadow-xl border rounded-lg border-l-0" style="max-width: 550px;" v-show="show" :style="{ width: width }" :class="wrapperClassName">
			<message
				:class="[className]"
				@mouseenter.native="clearTimer"
				@mouseleave.native="startTimer"
				:content="message"
				:closable="showClose || closable"
        :buttons="buttons"
				:is-collapsed="isCollapsed"
        :is-secure="secure"
				:close-handler="close"
        :on-confirm="onConfirm"
				:title="title"
        :type="type"
				:supportHTML="supportHTML" />
		</div>
	</transition>
</template>
<script>
import Message from './message.vue'
export default {
components: { Message },
data () {
  return {
    show: false,
    type: 'info',
    iconImg: '',
    title: '',
    message: '',
    duration: 3000,
    showClose: false,
    closable: false,
    isCollapsed: false,
    width: '',
    className: '',
    wrapperClassName: '',
    supportHTML: false,
    onClose: null,
    timer: null,
    closed: false,
    asyncAction: () => {},
    buttons: null,
    secure: false,
    onConfirm: () => {}
  }
},
watch: {
  closed (val) {
    if (val) {
      this.show = false
      this.$el.addEventListener('animationend', this.destroyElement)
    }
  }
},
methods: {
  close () {
    this.closed = true
    if (typeof this.onClose === 'function') {
      this.onClose(this)
    }
  },
  clearTimer () {
    clearTimeout(this.timer)
  },
  startTimer () {
    if (this.duration > 0 && this.type !== 'loading') {
      this.timer = setTimeout(() => {
        if (!this.closed) {
          this.close()
        }
      }, this.duration)
    }
  },
  destroyElement () {
    this.$el.removeEventListener('transitionend', this.destroyElement)
    this.$destroy(true)
    this.$el.parentNode.removeChild(this.$el)
  }
},
mounted () {
  if (this.type !== 'loading') {
    this.startTimer()
    if (this.duration <= 0) {
      this.showClose = true
    }
  } else {
      if (this.asyncAction) {
          this.asyncAction(this)
      }
  }
}
}
</script>
