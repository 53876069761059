<template>
  <svg viewBox="0 0 22 22">
    <g id="Linear" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Content" transform="translate(-621.000000, -253.000000)">
        <g id="edit" transform="translate(620.000000, 252.000000)">
          <path d="M11,2 L9,2 C4,2 2,4 2,9 L2,15 C2,20 4,22 9,22 L15,22 C20,22 22,20 22,15 L22,13" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
          <path d="M8.16000068,10.9000082 C7.86000067,11.2000082 7.56000089,11.7900088 7.50000089,12.2200089 L7.07000106,15.2300081 C6.91000106,16.3200082 7.68000126,17.0800089 8.77000129,16.9300089 L11.7800007,16.5000086 C12.2000007,16.4400086 12.7900013,16.1400087 13.1000013,15.8400087 L20.9800005,7.96000862 C22.3400005,6.60000861 22.9800005,5.02000856 20.9800005,3.02000856 C18.9800005,1.02000856 17.4000019,1.66000855 16.0400019,3.02000856 L8.16000068,10.9000082 Z" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
          <path d="M14.9100342,4.14990234 C15.5800342,6.53990245 17.4500346,8.40990239 19.8500347,9.0899024" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
          <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" id="Vector" opacity="0"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "edit",
  data: () => ({})
}
</script>