<template>
  <component
    :is="to ? 'router-link' : href ? 'a' : as"
    :type="type"
    :class="[$style[mode] || $style.btn, $style[size]]"
    :to="to"
    :href="href"
    :target="target"
    @click="$emit('click', $event)">
    <a-loader class="w-5 h-5" v-if="loading" />
    <span>
      <slot />
    </span>
  </component>
</template>

<script>

const modes = ['default', 'primary', 'dangerous', 'ghost']
const sizes = ['sm', 'md', 'lg']

export default {
  props: {
    outlined: { required: false, type: Boolean, default: false },
    loading: { required: false, type: Boolean, default: false },
    /**
     * @values default, primary, dangerous, ghost
     */
    mode: { required: false, type: String, default: 'default', validate: (mode) => modes.includes(mode) },
    size: { required: false, type: String, default: 'sm', validate: (size) => sizes.includes(size) },
    as: { required: false, type: String, default: 'button' },
    type: { required: false, type: String, default: 'button' },
    href: { required: false, type: String, default: null },
    target: { required: false, type: String, default: null },
    to: { required: false, type: [String, Object], default: null }
  }
}
</script>

<style module>

.btn {
  @apply disabled:bg-gray-200 cursor-pointer inline-flex justify-center items-center gap-2 transform active:scale-95 rounded-md text-base leading-6 font-medium focus:outline-none sm:text-sm sm:leading-5 transition-all ease-in-out duration-150;
}

.btn * {
  @apply cursor-pointer;
}

.default {
  @apply btn;
  @apply border border-gray-300 bg-white text-gray-700 transition-transform hover:text-gray-500 focus:border-blue-300;
}

.primary {
  @apply btn;
  @apply border border-transparent bg-primary text-white focus:border-blue-700;
}

.dangerous {
  @apply btn;
  @apply border border-transparent bg-red-600 text-white hover:bg-red-500 focus:border-red-700;
}

.ghost {
  @apply btn;
  @apply bg-gray-800 backdrop-filter backdrop-blur-lg text-white bg-opacity-50;
}

.sm {
  @apply px-4 py-2;
}

.md {
  @apply px-4 py-3;
}

.lg {
  @apply px-4 py-4;
}
</style>