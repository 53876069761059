import {AllowedForTypes, Authenticated} from '@/router/middlewares'

export default ({ admin, lowAdmin, teacher, coordinator }) => [
  {
    path: '/lessons',
    name: 'lessons.index',
    component: () => import(/* webpackChunkName: "lessons.index" */ '../../views/lessons/index.vue'),
    meta: {
      middleware: [Authenticated, AllowedForTypes([coordinator, teacher, admin])],
    }
  },
  {
    path: '/lessons/:course_id/:section_id/section-notes',
    name: 'lessons.studentsNotes',
    component: () => import('../../views/lessons/studentsNotes.vue'),
    meta: {
      middleware: [Authenticated, AllowedForTypes([admin, lowAdmin, coordinator, teacher])],
    }
  },
  {
    path: '/lessons/:id',
    name: 'lessons.show',
    component: () => import(/* webpackChunkName: "lessons.show" */ '../../views/lessons/show.vue'),
    meta: {
      middleware: [Authenticated, AllowedForTypes([admin, lowAdmin, coordinator, teacher])],
    }
  },
  {
    path: '/lessons/:course_id/:section_id/:lesson_id?',
    name: 'lessons.unnatendances',
    component: () => import(/* webpackChunkName: "lessons.unnatendances" */ '../../views/lessons/unnatendances.vue'),
    meta: {
      middleware: [Authenticated, AllowedForTypes([coordinator, teacher])],
    }
  },
  {
    path: '/lessons/:course_id/:section_id/lesson/:lesson_id',
    name: 'lessons.detail',
    component: () => import('../../views/lessons/detail.vue'),
    meta: {
      middleware: [Authenticated, AllowedForTypes([coordinator, teacher])],
    }
  },
]
