<template>
    <!--
    Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
    Read the documentation to get started: https://tailwindui.com/documentation
    -->
    <transition enter-active-class="transition-all duration-200 ease-out" leave-active-class="transition-all duration-750 ease-in" enter-class="opacity-0 scale-75" enter-to-class="opacity-100 scale-100" leave-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-75">
        <div class="fixed z-10 inset-0 overflow-y-auto" v-if="show">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <!--
                Background overlay, show/hide based on modal state.

                Entering: "ease-out duration-300"
                    From: "opacity-0"
                    To: "opacity-100"
                Leaving: "ease-in duration-200"
                    From: "opacity-100"
                    To: "opacity-0"
                -->
                <div class="ease-out duration-300 fixed inset-0 transition-opacity">
                    <div :class="['absolute inset-0  backdrop-blur-5 dark:bg-opacity-75 bg-opacity-75', backdropBg]"></div>
                    <button @click="$emit('cancel')" v-if="!hideTopCancel" class="absolute right-5 top-5 hover:bg-white hover:bg-opacity-50 transition-colors rounded-full w-10 h-10 flex justify-center items-center">
                        <a-icon name="plus" class="text-white transform rotate-45" scale="1.3" />
                    </button>
                </div>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                <!--
                Modal panel, show/hide based on modal state.

                Entering: "ease-out duration-300"
                    From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    To: "opacity-100 translate-y-0 sm:scale-100"
                Leaving: "ease-in duration-200"
                    From: "opacity-100 translate-y-0 sm:scale-100"
                    To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                -->
                <transition enter-active-class="transition-all ease-out duration-300" leave-active-class="ease-in duration-200" enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to-class="opacity-100 translate-y-0 sm:scale-100" leave-class="opacity-100 translate-y-0 sm:scale-100" leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div :class="[contentBg, 'inline-block align-bottom rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full']" role="dialog" aria-modal="true" aria-labelledby="modal-headline" v-if="show">
                        <div :class="[contentBg, 'px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-md']">
                            <div class="sm:flex sm:items-start">
                                <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full dark:bg-red-700 dark:bg-opacity-25 bg-red-100 sm:mx-0 sm:h-10 sm:w-10" v-if="mode === 'danger'">
                                    <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                    </svg>
                                </div>
                                <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                                    <h3 class="text-lg leading-6 dark:text-white font-medium text-gray-900 mb-2" v-if="title || $slots.title" id="modal-headline">
                                        <slot name="title">{{ title }}</slot>
                                    </h3>
                                    <div>
                                        <slot />

                                        <div class="mt-8" v-if="secureAction">
                                            <div class="flex flex-col">
                                                <h2 class="text-lg dark:text-gray-300 inline">Para proceder, por favor ingresa el texto a continuación: <span class="text-gray-800 mt-2 text-md dark:text-gray-500 inline">{{ password }}</span></h2>
                                                <a-input v-model="value" class="mt-2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <slot name="footer">
                            <div :class="contentBg" class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b-lg" v-if="$listeners.confirm || $listeners.cancel">
                                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto" v-if="$listeners.confirm">
                                    <button type="button" :class="confirmClass" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed" @click="$emit('confirm')" :disabled="isDisabledConfirm">
                                        {{ confirmText }}
                                    </button>
                                </span>
                                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto" v-if="!hideCancel">
                                    <button type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5" @click="$emit('cancel')">
                                        {{ cancelText }}
                                    </button>
                                </span>
                            </div>
                        </slot>
                    </div>
                </transition>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        show: { required: false, type: Boolean, default: false },
        mode: { required: false, type: String, default: 'default' },
        title: { required: false, type: String, default: undefined },
        cancelText: { required: false, type: String, default: "Cancelar" },
        confirmText: { required: false, type: String, default: "Confirmar" },
        confirmClass: { required: false, type: String, default: 'bg-primary hover:opacity-75' }, // 'bg-red-600 hover:bg-red-500' for danger suggestion
        hideCancel: { required: false, type: Boolean, default: false },
        secureAction: { required: false, type: Boolean, default: false },
        disabledConfirm: { required: false, type: Boolean, default: false },
        backdropBg: { required: false, type: String, default: 'bg-gray-500 dark:bg-gray-900' },
        contentBg: { required: false, type: String, default: 'bg-white dark:bg-tungsten' },
        hideTopCancel: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        password: '',
        value: ''
    }),
    watch: {
        show() {
            if (this.secureAction) {
                let result           = '';
                let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                
                for (let i = 0; i < 12; i++) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }

                this.password = result
            }
        }
    },
    computed: {
        isDisabledConfirm() {

            if (this.secureAction) {
                return this.password !== this.value
            }

            return this.disabledConfirm
        }
    }
}
</script>