import {Authenticated, AllowedForTypes} from '@/router/middlewares';

export default ({ admin, coordinator, student, lowAdmin, teacher }) => [
	{
        path: '/notifications',
        name: 'notifications',
        component: () => import(/* webpackChunkName: "notifications" */ '../../views/notifications/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, coordinator, student, lowAdmin, teacher])],
            breadcrumbs: [
                { route: () => ({ name: 'notifications' }), text: () => 'Mis notificaciones' },
            ]
        }
    },
    {
        path: '/notifications/create',
        name: 'create_notification',
        component: () => import(/* webpackChunkName: "create_notifications" */ '../../views/notifications/create.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, coordinator, student, lowAdmin, teacher])],
            breadcrumbs: [
                { route: () => ({ name: 'notifications' }), text: () => 'Mis notificaciones' },
                { route: () => ({ name: 'create_notification' }), text: () => 'Crear notificación' },
            ]
        }
    }
]
