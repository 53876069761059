import router from '@/router';
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        all: [],
        current: null,
        loading: false,
        errors: []
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET_ALL: (state, all) => state.all = all,
        SET: (state, current) => state.current = current,
        SET_LOADING: (state, loading) => state.loading = loading,
        SET_FAILED: (state, failed) => state.errors = failed,
        TOGGLE_LOADING: (state) => state.loading = !state.loading,
        // SET_ERRORS: (state, errors) => state.errors =
    },
    actions: {
        fetchAll({ commit }, { course_id, section_id, filters }) {
            commit('TOGGLE_LOADING')
            return this.$repository.lessons
                .showByCourseSection(section_id, course_id, filters)
                .then(({ data }) => {
                    commit(filters?.limit === 0 ? 'SET_ALL' : 'SET_PAGINATION', data)
                    return data
                })
                .then(data => {
                    filters?.limit !== 0 && router.replace({ query: filters }).catch(() => null)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        show({ commit }, { id, filters }) {
            commit('TOGGLE_LOADING')
            return this.$repository.lessons
                .show(id, filters)
                .then(({ data }) => {
                    commit('SET', {
                        ...data,
                        students: data.students.map($0 => ({
                            ...$0,
                            attendance: data.absences_by_attendance.some($1 => $1.student_id === $0.id),
                            delayed: data.absences_by_delay.some($1 => $1.student_id === $0.id),
                            failed_by_delay: data.students_with_failed_course_by_delay.some($1 => $1.student_id === $0.id),
                            note: $0.pivot.note ? parseFloat($0.pivot.note) : null
                        }))
                    })
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        store({ dispatch }, item) {
            this.$repository.lessons
                .store(item)
                .then(({ data }) => {
                    dispatch('fetch', { lesson_id: data.id, filters: { with: 'students.absencesByAttendance,students.absencesByDelay' } })
                    Vue.prototype.$message.success({
                        message: `Se ha creado la clase con éxito`,

                    })
                })
        },
        update({ commit }, lesson) {
            this.$repository.lessons
                .update(lesson.id, lesson)
                .then(() => {
                    commit('SET', lesson)
                })
        },
        bulkUpdate({ commit }, { lesson_id, students }) {
            commit('TOGGLE_LOADING')
            students = students.map(it => ({
                ...it,
                attendance: !it.attendance
            }))

            return this.$repository.lessons
                .bulkUpdateStudents(lesson_id, students)
                .then(({ data: response }) => {
                    if (response.students_with_failed_course_by_delay.length > 0) {
                        const studentsFailed = []
                        response.students_with_failed_course_by_delay.forEach(($0) => studentsFailed.push($0))
                        commit('SET_FAILED', {
                            ...studentsFailed
                        })
                        Vue.prototype.$message.info({
                            title: 'IMPORTANTE',
                            message: `Algunos estudiantes no se pudieron editar ya que tienen la materia aplazada por inasistencias.`,
                        })
                    } else {
                        Vue.prototype.$message.success({
                            message: `Se ha actualizado la clase con éxito`,
                        })
                    }
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        delete({ state, commit }, lesson_id) {
            this.$repository.lessons
                .delete(lesson_id)
                .then(() => {
                    let foundIndex = state.pagination.data.findIndex(it => it.id === lesson_id)
                    let tmpState = {...state.pagination}
                    if (foundIndex > -1) {
                        tmpState.data.splice(foundIndex, 1)
                    }
                    commit('SET_PAGINATION', tmpState)
                    Vue.prototype.$message.success({
                        message: `Se ha eliminado la clase con éxito`
                    })
                })
        }
    }
}
//
// const initialState = () => ({
//     pagination: { data: [] },
//     all: [],
//     current: null,
//     loading: false
// })
//
// const namespaced = true
//
// const state = () => initialState()
//
// const mutations = {
//     SET_PAGINATION: (state, pagination) => state.pagination = pagination,
//     SET_ALL: (state, all) => state.all = all,
//     SET: (state, current) => state.current = current,
//     SET_LOADING: (state, loading) => state.loading = loading,
//     TOGGLE_LOADING: (state) => state.loading = !state.loading
// }
//
// const actions = {
//     fetchAll({ commit }, { course_id, section_id, filters }) {
//         commit('TOGGLE_LOADING')
//         return this.$repository.lessons
//             .showByCourseSection(section_id, course_id, filters)
//             .then(({ data }) => {
//                 commit(filters?.limit === 0 ? 'SET_ALL' : 'SET_PAGINATION', data)
//                 return data
//             })
//             .then(data => {
//                 filters?.limit !== 0 && router.replace({ query: filters }).catch(() => null)
//                 return data
//             })
//             .finally(() => commit('TOGGLE_LOADING'))
//     },
//     show({ commit }, { id, filters }) {
//         commit('TOGGLE_LOADING')
//         return this.$repository.lessons
//             .show(id, filters)
//             .then(({ data }) => {
//                 commit('SET', {
//                     ...data,
//                     students: data.students.map($0 => ({
//                         ...$0,
//                         attendance: data.absences_by_attendance.some($1 => $1.student_id === $0.id),
//                         delayed: data.absences_by_delay.some($1 => $1.student_id === $0.id),
//                         note: $0.pivot.note
//                     }))
//                 })
//
//                 return data
//             })
//             .finally(() => commit('TOGGLE_LOADING'))
//     },
//     store({ dispatch }, item) {
//         this.$repository.lessons
//             .store(item)
//             .then(({ data }) => {
//                 dispatch('fetch', { lesson_id: data.id, filters: { with: 'students.absencesByAttendance,students.absencesByDelay' } })
//                 Vue.prototype.$message.success({
//                     message: `Se ha creado la clase con éxito`,
//
//                 })
//             })
//     },
//     update({ commit }, lesson) {
//         this.$repository.lessons
//             .update(lesson.id, lesson)
//             .then(() => {
//                 commit('SET', lesson)
//             })
//     },
//     bulkUpdate({ commit }, { lesson_id, students }) {
//         commit('TOGGLE_LOADING')
//         students = students.map(it => ({
//             ...it,
//             attendance: !it.attendance
//         }))
//
//         this.$repository.lessons
//             .bulkUpdateStudents(lesson_id, students)
//             .then(() => {
//                 Vue.prototype.$message.success({
//                     message: `Se ha actualizado la clase con éxito`,
//                 })
//             })
//             .finally(() => commit('TOGGLE_LOADING'))
//     },
//     delete({ state, commit }, lesson_id) {
//         this.$repository.lessons
//             .delete(lesson_id)
//             .then(() => {
//                 let foundIndex = state.pagination.data.findIndex(it => it.id === lesson_id)
//                 let tmpState = {...state.pagination}
//                 if (foundIndex > -1) {
//                     tmpState.data.splice(foundIndex, 1)
//                 }
//                 commit('SET_PAGINATION', tmpState)
//                 Vue.prototype.$message.success({
//                     message: `Se ha eliminado la clase con éxito`
//                 })
//             })
//     }
// }
//
// export default {
//     namespaced,
//     state,
//     mutations,
//     actions
// }
