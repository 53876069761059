import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: { data: [] },
        all: [],
        current: null,
        loadings: { index: false, show: false, update: false, store: false, delete: false },
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET_ALL: (state, all) => state.all = all,
        SET: (state, current) => state.current = current,
        TOGGLE_LOADING: (state, loading) => state.loadings[loading] = !state.loadings[loading],
    },
    actions: {
        index({ commit }, query = {}) {
            return this.$repository.documentRequests
                .index(query)
                ._loading(() => commit('TOGGLE_LOADING', 'index'))
                .then(({ data }) => {
                    commit(query?.limit === 0 ? 'SET_ALL' : 'SET_PAGINATION', data)
                    return data
                })
        },
        show({ commit }, { id, query }) {
            return this.$repository.documentRequests
                .show(id, query)
                ._loading(() => commit('TOGGLE_LOADING', 'show'))
                .then(({ data }) => {
                    commit('SET_CLASSROOM', data)
                    return data
                })
        },
        store({ commit }, item) {
            return this.$repository.documentRequests
                .store(item)
                ._loading(() => commit('TOGGLE_LOADING', 'store'))
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado la solicitud con éxito`,
                        
                    })
                    
                    return data
                })
        },
        update({ commit }, item) {
            return this.$repository.documentRequests
                .update(item.id, item)
                ._loading(() => commit('TOGGLE_LOADING', 'update'))
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        message: `Se ha actualizado la solicitud con éxito`,
                        
                    })
                    
                    return data
                })
        },
        delete({ commit }, id) {
            return new Promise((resolve, reject) => {
                Vue.prototype.$confirm({
                    message: `¿Desea eliminar esta solicitud?`,
                    button: {
                        no: 'No',
                        yes: 'Si'
                    },
                    callback: confirm => {
                        if (confirm) {
                            this.$repository.documentRequests
                                .delete(id)
                                ._loading(() => commit('TOGGLE_LOADING', 'delete'))
                                .then(({ data }) => resolve(data))
                                .catch(err => reject(err))
                        }
                    }
                })
            })
        },
    }
}
