<template>
  <div class="flex space-x-2 items-center" :class="{'text-gray-400 cursor-not-allowed': disabled}">
    <span v-if="$slots.prefix">
      <slot name="prefix" />
    </span>
    <div
      class="w-14 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 cursor-pointer"
      :class="{ 'bg-primary': value, 'bg-opacity-50 cursor-not-allowed': disabled }"
      :aria-checked="value"
      @click="toggle">
      <div class="bg-white w-6 h-6 rounded-full shadow-md transform duration-300" :class="{ 'translate-x-6': value }" />
    </div>
    <span v-if="$slots.suffix">
      <slot name="suffix" />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: false },
    disabled: { required: false, type: Boolean, default: false }
  },
  methods: {
    toggle () {
      if (!this.disabled) {
        this.$emit('input', !this.value)
      }
    }
  }
};
</script>