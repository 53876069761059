import { OnlyGuest } from '@/router/middlewares'

export default () => [
	{
        path: '/',
        name: 'empty',
        alias: '/login',
        component: () => import(/* webpackChunkName: "empty" */ '../../views/auth/login.vue'),
        meta: {
            middleware: [OnlyGuest]
        }
    },
    {
        path: '/confirm-account',
        name: 'confirm-account',
        component: () => import(/* webpackChunkName: "confirm-account" */ '../../views/auth/confirm-account.vue'),
        meta: {
            middleware: [OnlyGuest]
        }
    },
    {
        path: '/confirm-password',
        name: 'confirm-password',
        component: () => import(/* webpackChunkName: "confirm-account" */ '../../views/auth/confirm-password.vue'),
        meta: {
            middleware: [OnlyGuest]
        }
    },
    {
        path: '/recover-account',
        name: 'recover-account',
        component: () => import(/* webpackChunkName: "recover-account" */ '../../views/auth/send-recovery-email.vue'),
        meta: {
            middleware: [OnlyGuest]
        }
    }
]
