<template>
    <a-select
        :label="label"
        class="w-48"
        :clearable="false"
        :value="value"
        :reduce="$0 => $0.value"
        :source="periods"
        @input="$emit('input', $event)"
        :loading="loading"
        placeholder="Período"/>
</template>

<script>
export default {
    data: () => ({
        loading: false,
        periods: []
    }),
    props: {
        value: { requried: false, type: [String, Number], default: null },
        label: { required: false, type: String, default: null }
    },
    mounted() {
        this.loading = !this.loading
        this.$repository
            .periods
            .index({ limit: 0, sort_by: 'year,desc' })
            .then(({ data }) => {
                this.periods = data.map($0 => ({ label: `${$0.year}-${$0.number}`, value: $0.id }))
                this.$emit('input', data.find($0 => $0.enabled)?.id)
            })
            .finally(() => this.loading = !this.loading)
    }
}
</script>