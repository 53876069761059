import {Authenticated, AllowedForTypes} from '@/router/middlewares';

const base = {
    index: { url: () => ({ name: 'prelations' }), title: 'Listado de prelaciones'},
    create: { url: () => ({ name: 'create_prelation' }), title: 'Crear prelación'},
}

export default ({ admin, coordinator, lowAdmin }) => [
	{
        path: '/prelations',
        name: 'prelations',
        component: () => import(/* webpackChunkName: "prelations" */ '../../views/prelations/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, coordinator, lowAdmin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title }
            ]
        }
    },
    {
        path: '/prelations/create',
        name: 'create_prelation',
        component: () => import(/* webpackChunkName: "create_prelation" */ '../../views/prelations/create.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
                { route: base.create.url, text: () => base.create.title }
            ]
        }
    },
    {
        path: '/prelations/:course/edit',
        name: 'edit_prelation',
        component: () => import(/* webpackChunkName: "edit_prelation" */ '../../views/prelations/edit.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
                { route: base.create.url, text: () => base.create.title }
            ]
        }
    }
]
