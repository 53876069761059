import { Authenticated, AllowedForTypes } from '@/router/middlewares'

const isCoordinator = route => route.path.split('/').filter($0 => $0.trim())[0] === 'coordinators'

export default ({ admin, coordinator, teacher, lowAdmin }) => [
	{
        path: '/teachers',
        name: 'teachers.index',
        alias: '/coordinators',
        component: () => import(/* webpackChunkName: "teachers.index" */ '../../views/teachers/index.vue'),
        props: route => ({
            isCoordinator: isCoordinator(route),
            router: {
                createPath: isCoordinator(route) ? { path: 'coordinators/create' } : { name: 'teachers.create' },
            }
        }),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, coordinator, lowAdmin])],
            breadcrumbs: [
                { route: () => ({ name: 'teachers.index' }), text: ({ route }) => `Listado de ${isCoordinator(route) ? 'coordinadores' : 'profesores'}` },
            ]
        }
    },
    {
        path: '/teachers/create',
        name: 'teachers.create',
        alias: '/coordinators/create',
        props: route => ({
            isCoordinator: isCoordinator(route)
        }),
        component: () => import(/* webpackChunkName: "teachers.create" */ '../../views/teachers/create.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: ({ route }) => isCoordinator(route) ? { path: '/coordinators' } : ({ name: 'teachers.index' }), text: ({ route }) => `Listado de ${isCoordinator(route) ? 'coordinadores' : 'profesores'}` },
                { route: ({ route }) => isCoordinator(route) ? { path: '/coordinators/create' } : ({ name: 'teachers.index' }), text: ({ route }) => `Crear ${isCoordinator(route) ? 'coordinador' : 'profesor'}` },
            ]
        },
    },
    {
        path: '/teachers/:id',
        alias: '/coordinators/:id',
        props: route => ({
            isCoordinator: isCoordinator(route)
        }),
        component: () => import(/* webpackChunkName: "teachers.show" */ '../../views/teachers/show/index.vue'),
        children: [
            {
                path: '',
                name: 'teachers.show',
                component: () => import(/* webpackChunkName: "teachers.show.courses" */ '../../views/teachers/show/courses.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, lowAdmin, coordinator])],
                    breadcrumbs: [
                        { route: ({ route }) => isCoordinator(route) ? { path: '/coordinators' } : ({ name: 'teachers.index' }), text: ({ route }) => `Listado de ${isCoordinator(route) ? 'coordinadores' : 'profesores'}` },
                        { route: ({ route }) => isCoordinator(route) ? { path: `/coordinators/${route.params.id}` } : ({ name: 'teachers.show' }), text: ({ store }) => `${store.state.teachers.current?.first_name} ${store.state.teachers.current?.last_name}`, loading: ({ store }) => store.state.teachers.loading },
                    ]
                },
            },
            {
                path: '/teachers/:id/lessons',
                alias: '/coordinators/:id/lessons',
                name: 'teachers.show.lessons',
                component: () => import(/* webpackChunkName: "teachers.show.lessons" */ '../../views/teachers/show/lessons.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, lowAdmin, coordinator])],
                    breadcrumbs: [
                        { route: ({ route }) => isCoordinator(route) ? { path: '/coordinators' } : ({ name: 'teachers.index' }), text: ({ route }) => `Listado de ${isCoordinator(route) ? 'coordinadores' : 'profesores'}` },
                        { route: ({ route }) => isCoordinator(route) ? { path: `/coordinators/${route.params.id}` } : ({ name: 'teachers.show' }), text: ({ store }) => `${store.state.teachers.current?.first_name} ${store.state.teachers.current?.last_name}`, loading: ({ store }) => store.state.teachers.loading },
                    ]
                },
            },
            {
                path: '/coordinators/:id/supervising',
                name: 'teachers.show.supervising',
                component: () => import(/* webpackChunkName: "teachers.show.supervising" */ '../../views/teachers/show/supervising.vue'),
                meta: {
                    middleware: [Authenticated, AllowedForTypes([admin, coordinator])],
                    breadcrumbs: [
                        { route: ({ route }) => isCoordinator(route) ? { path: '/coordinators' } : ({ name: 'teachers.index' }), text: ({ route }) => `Listado de ${isCoordinator(route) ? 'coordinadores' : 'profesores'}` },
                        { route: ({ route }) => isCoordinator(route) ? { path: `/coordinators/${route.params.id}` } : ({ name: 'teachers.show' }), text: ({ store }) => `${store.state.teachers.current?.first_name} ${store.state.teachers.current?.last_name}`, loading: ({ store }) => store.state.teachers.loading },
                    ]
                },
            },
        ]
    },
    {
        path: '/teachers/:id/edit',
        name: 'teachers.edit',
        alias: '/coordinators/:id/edit',
        props: route => ({
            isCoordinator: isCoordinator(route)
        }),
        component: () => import(/* webpackChunkName: "create_teacher" */ '../../views/teachers/edit.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: ({ route }) => isCoordinator(route) ? { path: '/coordinators' } : ({ name: 'teachers.index' }), text: ({ route }) => `Listado de ${isCoordinator(route) ? 'coordinadores' : 'profesores'}` },
                { route: ({ route }) => isCoordinator(route) ? { path: `/coordinators/${route.params.id}` } : ({ name: 'teachers.show' }), text: ({ store }) => `${store.state.teachers.current?.first_name} ${store.state.teachers.current?.last_name}`, loading: ({ store }) => store.state.teachers.loading },
                { route: (route) => route, text: () => 'Editar' }
            ]
        },
    },
    {
        path: '/teachers/:id/schedule',
        name: 'teachers.schedule',
        alias: '/coordinators/:id/schedule',
        props: route => ({
            isCoordinator: isCoordinator(route)
        }),
        component: () => import(/* webpackChunkName: "teacher_schedule" */ '../../views/teachers/schedule.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, coordinator])],
            breadcrumbs: [
                { route: () => ({ name: 'teachers.index' }), text: () => 'Listado de profesores' },
                { route: ({ params }) => ({ name: 'teachers.show', params }), text: ({ store: { state: { teachers } } }) => teachers.current && `${teachers.current?.first_name} ${teachers.current?.last_name}`, loading: ({ store }) => store.state.teachers.loading },
                { route: (route) => route, text: () => "Horario" },
            ]
        }
    },
    {
        path: '/teachers/:id/courses',
        name: 'teacher_courses',
        component: () => import(/* webpackChunkName: "teacher_courses" */ '../../views/teachers/courses.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, coordinator])],
            breadcrumbs: [
                { route: () => ({ name: 'teachers.index' }), text: () => 'Listado de profesores' },
                { route: ({ params }) => ({ name: 'teachers.show', params }), text: ({ store }) => `${store.state.teachers.current?.first_name} ${store.state.teachers.current?.last_name}`, loading: ({ store }) => store.state.teachers.loading },
                { route: (route) => route, text: () => "Clases" },
            ]
        }
    },
    {
        path: '/teachers/:id/historic',
        name: 'teacher_historic',
        alias: '/coordinators/:id/historic',
        component: () => import(/* webpackChunkName: "teacher_historic" */ '../../views/teachers/historic.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, lowAdmin, coordinator, teacher])],
            breadcrumbs: [
                { route: ({ route }) => isCoordinator(route) ? { path: '/coordinators' } : ({ name: 'teachers.index' }), text: ({ route }) => `Listado de ${isCoordinator(route) ? 'coordinadores' : 'profesores'}` },
                { route: ({ route }) => isCoordinator(route) ? { path: `/coordinators/${route.params.id}` } : ({ name: 'teachers.show' }), text: ({ store }) => `${store.state.teachers.current?.first_name} ${store.state.teachers.current?.last_name}`, loading: ({ store }) => store.state.teachers.loading },
                { route: (route) => route, text: () => 'Historial' }
            ]
        }
    },
]
