/**
 * This is the main router configuracion,
 * this handles every hook of the vue-router environment.
 */

const nextFactory = (context, middleware, index) => {
    const subsequentMiddleware = middleware[index]
    if (!subsequentMiddleware) {
        return context.next
    }

    return (...parameters) => {
        context.next(...parameters)
        const nextMiddleware = nextFactory(context, middleware, index + 1)
        subsequentMiddleware({...context, nex: nextMiddleware})
    }
}

/**
 * implemented at ~/main.js
 */
export default ({ router, store }) => {
    router.beforeEach((to, from, next) => {
        store.dispatch('vanishErrors')
        store.dispatch('cancelPendingRequest');
        // document.title = (lang.browser.title[to.meta.title] || '').replace('#match#', to.params.id || '') || lang.browser.title.default
        if (to.meta?.middleware) {
            const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
            const context = { to, from, next, router, store }
            const nextMiddleware = nextFactory(context, middleware, 1)
            return middleware[0]({ ...context, next: nextMiddleware })
        }

        return next()
    })
}
