export default ({ axios, mapQueryString, withoutInvalidValues }) => ({
    index: (filters = {}) => axios.get(`/api/preregistered-students${mapQueryString(filters)}`),
    show: (id, query) => axios.get(`/api/preregistered-students/${id}${mapQueryString(query)}`),
    store: (body) => axios.post(`/api/preregistered-students`, withoutInvalidValues(body)),
    update: (id, body) => axios.patch(`/api/preregistered-students/${id}`, withoutInvalidValues(body)),
    delete: (id) => axios.delete(`/api/preregistered-students/${id}`),
    confirm: (id) => axios.post(`/api/preregistered-students/${id}/confirm`),
    // enabledInscriptionsPeriod: () => axios.get('/api/enabled-preregisters-period')
    enabledInscriptionsPeriod: () => axios.get('/api/enabled-inscription-period')
})
