import router from '@/router';
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        all: [],
        current: null,
        loading: false,
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET_NOTIFICATION: (state, current) => state.current = current,
        SET_NOTIFICATIONS: (state, all) => state.all = all,
        DROP_NOTIFICATION: (state, index) => state.pagination.data.splice(index, 1),
        TOGGLE_LOADING: state => state.loading = !state.loading,
    },
    actions: {
        fetchAll({ commit }, filters = {}) {
            commit('TOGGLE_LOADING')
            this.$repository.notifications
                .index(filters)
                .then(res => commit(filters?.limit === 0 ? 'SET_NOTIFICATIONS' : 'SET_PAGINATION', res.data))
                .finally(() => commit('TOGGLE_LOADING'))
        },
        fetchStudentNotifications({ commit }, data = { id: null, filters: {}}) {
            commit('TOGGLE_LOADING')
            this.$repository.students
                .notifications(data.id, data.filters)
                .then(res => commit(data.filters?.limit === 0 ? 'SET_NOTIFICATIONS' : 'SET_PAGINATION', res.data))
                .finally(() => commit('TOGGLE_LOADING'))
        },
        fetch({ commit }, id) {
            this.$repository.notifications
                .show(id)
                .then(res => {
                    commit('SET_NOTIFICATION', res.data)
                    commit('SET_NAVBAR_TITLE', ['Listado de notifications', res.data.code], { root: true })
                })
        },
        store({ commit }, item) {
            commit('TOGGLE_LOADING')

            let data = new FormData()

            if (item.type !== 7) {
                delete item.receiver_user_id
            }

            Object.entries(item)
                .forEach($0 => {
                    if ($0[1] !== null && $0 !== undefined && $0 !== '') {
                        data.append($0[0], $0[1])
                    }
                })


            return this.$repository.notifications
                .store(data)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado la notificación con éxito`,
                        
                    })
                    if(!item.hasOwnProperty('back')){
                        router.push({ name: 'notifications' })
                    }
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        update({ commit }, item) {
            commit('TOGGLE_LOADING')
            this.$repository.notifications
                .update(item.id, item)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha actualizado la notificación con éxito`,
                        
                    })
                    router.push({ name: 'notifications' })
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        delete({ commit, state }, id) {
            Vue.prototype.$confirm({
                message: `¿Desea eliminar esta notificación?`,
                button: {
                    no: 'No',
                    yes: 'Si'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$repository.notifications
                            .delete(id)
                            .then(() => {
                                const index = state.pagination.data.findIndex(item => item.id === id)
                                commit('DROP_NOTIFICATION', index)
                            })
                    }
                }
            })
        },
    }
}
