<template>
  <div :class="$style.wrapper">
    <button type="button" @click="handleOpen" :class="$style.button">
      <slot>
        <span :class="$style.span" :style="title ? 'height: auto; width: auto; padding: 2px 10px;': ''">
          <span v-if="title">{{ title }}</span>
          <a-icon name="more" class="transform rotate-90" :class="$style.svg" />
        </span>
      </slot>
    </button>

    <transition enter-active-class="transition-all duration-200 ease-out" leave-active-class="transition-all duration-200 ease-in" enter-class="opacity-0 scale-75" enter-to-class="opacity-100 scale-100" leave-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-75">
      <div :class="[placement === 'right' ? 'right-0' : 'left-0', search ? 'max-h-72 overflow-y-auto' : '']" v-if="open">
        <slot name="menu" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    };
  },
  props: {
    title: { required: false, type: String, default: null },
    search: { required: false, type: Boolean, default: false },
    placement: {
      type: String,
      default: "right",
      validator: (value) => ["right", "left"].indexOf(value) !== -1,
    }
  },
  methods: {
    handleOpen() {
      this.open = !this.open
    },
    close() {
      this.open = false
    }
  },
  mounted() {

    const container = this.$el

    const handleOutsideClick = (event) => {
      const contains = container?.contains(event.target)

      if (!contains) {
        if (this.open) {
          this.open = false
        }
      }
    }

    const onEscape = (e) => {
      if (e.key === "Esc" || e.key === "Escape") {
        this.open = false;
      }
    };

    window.addEventListener('click', handleOutsideClick)
    document.addEventListener("keydown", onEscape);

    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("keydown", onEscape);
    });
  },
};
</script>

<style module>

.button {
  @apply focus:outline-none active:scale-95 transform transition-transform group;
}

.span {
  @apply inline-flex h-8 w-8 bg-white border rounded-full items-center justify-center;
}

.wrapper {
  @apply relative inline-block select-none;
}

.svg {
  @apply mr-0.5;
}

.wrapper > div {
  @apply absolute mt-1 border bg-white rounded-xl shadow-md z-10 flex flex-col;
  max-width: 24rem;
  min-width: 12rem;
}
</style>
