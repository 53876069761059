import { Authenticated, AllowedForTypes } from '@/router/middlewares';

export default ({ admin, lowAdmin }) => [
	{
        path: '/classrooms',
        name: 'classrooms',
        component: () => import(/* webpackChunkName: "classrooms" */ '../../views/classrooms/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, lowAdmin])],
            breadcrumbs: [
                { route: () => ({ name: 'classrooms' }), text: () => 'Listado de aulas' }
            ]
        }
    },
    {
        path: '/classrooms/create',
        name: 'create_classroom',
        component: () => import(/* webpackChunkName: "create_classrooms" */ '../../views/classrooms/create.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: () => ({ name: 'classrooms' }), text: () => 'Listado de aulas' },
                { route: (route) => route, text: () => "Crear aula" },
            ]
        }
    },
    {
        path: '/classrooms/:id/edit',
        name: 'edit_classroom',
        component: () => import(/* webpackChunkName: "create_classrooms" */ '../../views/classrooms/edit.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: () => ({ name: 'classrooms' }), text: () => 'Listado de aulas' },
                { route: (route) => route, text: ({ store }) => `${store.state.classrooms.classroom?.code}`, loading: ({ store: { state } }) => state.classrooms.loading },
                { route: (route) => route, text: () => "Editar aula" },
            ]
        }
    }
]
