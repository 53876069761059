var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"slide-up"}},[(_vm.showNotify)?_c('div',[(_vm.isPartialPaid)?_c('div',{staticClass:"py-2 pb-3 flex-wrap gap-2 flex items-center px-4 shadow-lg justify-between bg-gray-900 fixed left-4 right-8 bg-opacity-80 backdrop-filter backdrop-blur-lg rounded-xl bottom-4 z-10",class:{
        'animate-pulse': _vm.loadings.inscriptionPayment
      }},[_c('div',{staticClass:"w-full text-right text-white"},[_c('p',{staticClass:"block ml-auto cursor-pointer w-4",on:{"click":function($event){_vm.showNotify = false}}},[_vm._v("X")])]),_c('div',{staticClass:"flex flex-col"},[_c('h1',{staticClass:"text-lg text-white text-center md:text-left"},[(!_vm.isAdmin)?[_vm._v(" "+_vm._s(_vm.user.student.gender === 'female' ? 'Bienvenida' : 'Bienvenido')+", "+_vm._s(_vm.user.student.first_name)+" "+_vm._s(_vm.user.student.last_name)+". tienes una deuda pendiente ")]:[_vm._v(" Este estudiante tiene pagos pendientes. ")]],2),_c('h2',{staticClass:"text-gray-200 text-center md:text-left"},[_vm._v(" Su deuda es de: $"+_vm._s(_vm.missingPaymentAmount)+" ")])]),_c('div',{staticClass:"space-x-2"},[_c('a-button',{staticClass:"w-full md:w-auto",on:{"click":function($event){_vm.modals.inscriptionPayment = true}}},[_vm._v(" Reportar pago ")]),_c('a-button',{staticClass:"w-full md:w-auto",on:{"click":_vm.verifyInscription}},[_vm._v(" Refrescar ")])],1)]):_vm._e()]):_vm._e()]),_c('a-modal',{attrs:{"show":_vm.modals.inscriptionPayment},on:{"confirm":_vm.pay,"cancel":function($event){_vm.modals.inscriptionPayment = false}}},[_c('div',{staticClass:"space-y-4"},[_c('a-select',{staticClass:"mb-4",attrs:{"source":[
          { label: 'Efectivo', value: 1 },
          { label: '100% Banco', value: 2 },
          { label: 'Transferencia Bancaria', value: 3 },
          { label: 'Transferencia de Curso', value: 4 },
          { label: 'Zelle', value: 5 } ],"label":"Tipo de pago","required":"","error":_vm.error('type', { attribute: 'tipo de pago' })},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('a-input',{staticClass:"mb-4",attrs:{"required":_vm.form.type !== 1,"disabled":_vm.form.type === 1,"label":"Factura","id":"receipt_number","error":_vm.error('receipt_number')},model:{value:(_vm.form.receipt_number),callback:function ($$v) {_vm.$set(_vm.form, "receipt_number", $$v)},expression:"form.receipt_number"}}),_c('a-input',{staticClass:"mb-4",attrs:{"label":"Fecha","required":"","id":"date","type":"date","error":_vm.error('date')},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}}),_c('a-input',{staticClass:"mb-4",attrs:{"label":"Monto","required":"","id":"amount","type":"number","error":_vm.error('amount')},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", _vm._n($$v))},expression:"form.amount"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }