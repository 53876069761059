<template>
    <ul>
        <li v-for="(src, index) in source" :key="index">
            <slot v-bind="{ src, index }">
                <div>
                    <h3>{{ src.title }}</h3>
                    <p>{{ src.content }}</p>
                </div>
            </slot>
            <slot name="action" v-bind="src"/>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        source: { required: false, type: Array, default: () => [] }
    }
}
</script>

<style scoped>
    ul {
        list-style: none;
    }

    ul > li {
        margin: 15px;
        padding-bottom: 15px;
    }

    ul > li:not(:last-child):not(:only-child) {
      border-bottom: 1px solid #E8E9EC;
    }
</style>