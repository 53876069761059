export default ({ axios, mapQueryString, withoutInvalidValues }) => ({
    index: (filters = {}, configuration = {}) => axios.get(`/api/periods${mapQueryString(filters)}`, configuration),
    show: (id, query) => axios.get(`/api/periods/${id}${mapQueryString(query)}`),
    store: (body) => axios.post(`/api/periods`, withoutInvalidValues(body)),
    update: (id, body) => axios.patch(`/api/periods/${id}`, withoutInvalidValues(body)),
    delete: (id) => axios.delete(`/api/periods/${id}`),
    openInscriptions: (id, body) => axios.patch(`/api/periods/${id}/open-inscriptions`, body),
    coursesBySpecialization: (id, filters = {}) => axios.get(`/api/periods/${id}/courses-by-specialization${mapQueryString(filters)}`),
    enabled: (filters = {}, configuration = {}) => axios.get(`/api/enabled-period${mapQueryString(filters)}`, configuration),
    assignPensum: (period, pensum) => axios.put(`/api/periods/${period}/pensums/${pensum}`)
})