export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        all: [],
        current: null,
        loading: false,
        students: {
            pagination: { data: [] },
            all: []
        }
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET: (state, current) => state.current = current,
        SET_ALL: (state, sections) => state.all = sections,
        DROP: (state, index) => state.pagination.data.splice(index, 1),
        TOGGLE_LOADING: state => state.loading = !state.loading,
        SET_STUDENTS: (state, { context = 'pagination', students }) => state.students[context] = students
    },
    actions: {
        index({ commit }, filters = {}) {
            commit('TOGGLE_LOADING')
            return this.$repository.courseSections
                .index(filters)
                .then(response => {
                    commit(filters?.limit === 0 ? 'SET_ALL' : 'SET_PAGINATION', response.data)
                    return response
                }).finally(() => commit('TOGGLE_LOADING'))
        },
        students({ commit }, { context, course_id, section_id, filters }) {
            commit('TOGGLE_LOADING')
            return this.$repository.courseSections
                .students({ course_id, section_id, filters })
                .then(response => {
                    commit('SET_STUDENTS', { context, students: response.data })
                    return response
                })
                .finally(() => commit('TOGGLE_LOADING'))
        }
    }
}
