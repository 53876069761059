import {Authenticated, AllowedForTypes} from '@/router/middlewares';

export default ({ admin, student, coordinator, lowAdmin, teacher }) => [
	{
        path: '/documents',
        name: 'documents',
        component: () => import(/* webpackChunkName: "documents" */ '../../views/documents/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, coordinator, teacher, student, lowAdmin])],
            breadcrumbs: [
                { route: () => ({ name: 'documents' }), text: () => 'Biblioteca virtual' }
            ]
        }
    },
    {
        path: '/documents/create',
        name: 'create_document',
        component: () => import(/* webpackChunkName: "create_documents" */ '../../views/documents/create.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: () => ({ name: 'documents' }), text: () => 'Biblioteca virtual' },
                { route: (route) => route, text: () => "Crear documento" },
            ]
        }
    },
    {
        path: '/documents/:id/edit',
        name: 'edit_document',
        component: () => import(/* webpackChunkName: "create_documents" */ '../../views/documents/edit.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: () => ({ name: 'documents' }), text: () => 'Biblioteca virtual' },
                { route: ({ route }) => ({ name: 'documents', params: route.params }), text: ({ store }) => `${store.state.documents.current?.title}`, loading: ({ store }) => store.state.documents.loading },
                { route: ({route}) => route, text: () => "Editar documento" },
            ]
        }
    }
]
