<template>
  <svg viewBox="0 0 20 18">
    <g id="Linear" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Arrow" transform="translate(-750.000000, -255.000000)">
        <g id="arrow-swap-horizontal" transform="translate(748.000000, 252.000000)">
          <line x1="20.4999905" y1="14.9899902" x2="15.4899902" y2="20.0099907" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0"></line>
          <line x1="3.5" y1="14.9899902" x2="20.5" y2="14.9899902" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0"></line>
          <line x1="3.5" y1="9.00999069" x2="8.51000023" y2="3.98999023" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0"></line>
          <line x1="20.5" y1="9.01000977" x2="3.5" y2="9.01000977" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0"></line>
          <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" id="Vector" opacity="0" transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) "></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "exchange",
  data: () => ({})
}
</script>