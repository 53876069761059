import { Authenticated, AllowedForTypes } from '@/router/middlewares';

export default ({ admin }) => [
	{
        path: '/administrators',
        name: 'administrators',
        component: () => import(/* webpackChunkName: "administrators" */ '../../views/administrators/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: () => ({ name: 'administrators' }), text: () => 'Listado de administradores' }
            ]
        }
    },
    {
        path: '/administrators/create',
        name: 'create_administrator',
        component: () => import(/* webpackChunkName: "create_administrator" */ '../../views/administrators/create.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: () => ({ name: 'administrators' }), text: () => 'Listado de administradores' },
                { route: (route) => route, text: () => 'crear administrador' }
            ]
        },
    },
    {
        path: '/administrators/:id',
        name: 'administrator_detail',
        component: () => import(/* webpackChunkName: "create_administrator" */ '../../views/administrators/administrator.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: () => ({ name: 'administrators' }), text: () => 'Listado de administradores' },
                { route: ({ route }) => route, text: () => 'Detalles' },
            ]
        },
    }
]
