import { AllowedForTypes, Authenticated } from '@/router/middlewares';

export default ({ teacher, coordinator, student }) => [
    {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../../views/home/index.vue'),
        meta: {
            middleware: [Authenticated],
            breadcrumbs: [
                { route: () => ({ name: 'home' }), text: () => 'Dashboard', bold: true },
            ]
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '../../views/profile/index.vue'),
        meta: {
            middleware: [Authenticated],
            breadcrumbs: [
                { route: () => ({ name: 'profile' }), text: () => 'Perfil' },
            ]
        }
    },
    {
        path: '/profile/:id/historic',
        name: 'profile.historic',
        component: () => import(/* webpackChunkName: "profile.historic" */ '../../views/home/student-historic.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([student])],
            breadcrumbs: [
                { route: () => ({ name: 'profile' }), text: () => 'Perfil' },
                { route: () => ({ name: 'profile.historic' }), text: () => 'Historial académico' },
            ]
        }
    },
    {
        path: '/profile/:id/payments',
        name: 'profile.payments',
        component: () => import(/* webpackChunkName: "profile.payments" */ '../../views/home/student-payments.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([student])],
            breadcrumbs: [
                { route: () => ({ name: 'profile' }), text: () => 'Perfil' },
                { route: () => ({ name: 'profile.payments' }), text: () => 'Registro de pagos' },
            ]
        }
    },
    {
        path: '/profile/:id/inscriptions',
        name: 'profile.inscriptions',
        component: () => import(/* webpackChunkName: "profile.inscriptions" */ '../../views/home/student-inscriptions.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([student])],
            breadcrumbs: [
                { route: () => ({ name: 'profile' }), text: () => 'Perfil' },
                { route: () => ({ name: 'profile.inscriptions' }), text: () => 'Registro de inscripciones' },
            ]
        }
    },
    {
        path: '/profile/schedule',
        name: 'my_schedule',
        component: () => import(/* webpackChunkName: "my_teacher_courses" */ '../../views/profile/schedules.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([teacher, coordinator, student])],
            breadcrumbs: [
                { route: () => ({ name: 'profile' }), text: () => 'Mi perfil' },
                { route: () => ({ name: 'my_schedule' }), text: () => 'Horario' },
            ]
        }
    },
    {
        path: '/profile/courseNote/:section_id/:course_id',
        name: 'profile.show.course',
        component: () => import(/* webpackChunkName: "profile.show.course" */ '../../views/home/_user_info/student/courseNote.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([student])],
            breadcrumbs: [
                { route: () => ({ name: 'profile' }), text: () => 'Perfil' },
                { route: () => ({ name: 'profile.historic' }), text: () => 'Historial académico' },
            ]
        }
    },
    /*{
        path: '/profile/courses',
        name: 'my_teacher_courses',
        component: () => import(/!* webpackChunkName: "my_teacher_courses" *!/ '../../views/profile/courses/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([teacher, coordinator])],
            breadcrumbs: [
                { route: () => ({ name: 'profile' }), text: () => 'Mi perfil' },
                { route: () => ({ name: 'my_teacher_courses' }), text: () => 'Materias asignadas' },
            ]
        }
    },*/
    /*{
        path: '/profile/courses/:section_id/:course_id/lessons',
        name: 'my_teacher_courses_lessons',
        component: () => import(/!* webpackChunkName: "my_teacher_courses_lessons" *!/ '../../views/profile/courses/lessons/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([teacher, coordinator])],
            breadcrumbs: [
                { route: () => ({ name: 'profile' }), text: () => 'Mi perfil' },
                { route: () => ({ name: 'my_teacher_courses' }), text: () => 'Materias asignadas' },
                { route: (route) => route, text: ({ store: { state } }) => `Lecciones de ${state.sections.courseSection?.course?.title}`, loading: ({ store }) => store.state.lessons.loading },
            ]
        }
    },*/
    /*{
        path: '/profile/courses/:section_id/:course_id/absences',
        name: 'my_teacher_courses_unattendances',
        component: () => import(/!* webpackChunkName: "my_teacher_courses_unattendances" *!/ '../../views/profile/courses/lessons/attendances.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([teacher, coordinator])],
            breadcrumbs: [
                { route: () => ({ name: 'profile' }), text: () => 'Mi perfil' },
                { route: () => ({ name: 'my_teacher_courses' }), text: () => 'Materias asignadas' },
                { route: (route) => route, text: ({ store: { state } }) => `Lecciones de ${state.sections.courseSection?.course?.title}`, loading: ({ store }) => store.state.sections.loading },
                { route: (route) => route, text: () => `Inasistencias` },
            ]
        }
    },*/
    /*{
        path: '/profile/courses/:section_id/:course_id/lessons/make',
        name: 'my_teacher_courses_register',
        component: () => import(/!* webpackChunkName: "my_teacher_courses_register" *!/ '../../views/profile/courses/lessons/create.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([teacher, coordinator])],
            breadcrumbs: [
                { route: () => ({ name: 'profile' }), text: () => 'Mi perfil' },
                { route: () => ({ name: 'my_teacher_courses' }), text: () => 'Materias asignadas' },
                { route: ({route}) => ({ name: 'my_teacher_courses_lessons', params: route.params }), text: ({ store: { state } }) => `${state.sections.courseSection?.course?.title ?? 'Cargando...'}` },
                { route: (route) => route, text: () => `Crear lección` },
            ]
        }
    },*/
    /*{
        path: '/profile/courses/:section_id/:course_id/lessons/:lesson_id',
        name: 'my_teacher_courses_lessons_detail',
        component: () => import(/!* webpackChunkName: "my_teacher_courses_lessons_detail" *!/ '../../views/profile/courses/lessons/lesson.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([teacher, coordinator])],
            breadcrumbs: [
                { route: () => ({ name: 'profile' }), text: () => 'Mi perfil' },
                { route: () => ({ name: 'my_teacher_courses' }), text: () => 'Materias asignadas' },
                { route: ({route}) => ({ name: 'my_teacher_courses_lessons', params: route.params }), text: ({ store: { state } }) => `${state.lessons?.current?.course_section?.course?.title}`, loading: ({ store }) => store.state.lessons.loading },
                { route: (route) => route, text: ({ store: { state } }) => `Tema: ${state.lessons?.current?.subject}`, loading: ({ store }) => store.state.lessons.loading },
            ]
        }
    },*/
    /*{
        path: '/profile/courses/:section_id/:course_id/students/:student_id',
        name: 'my_teacher_courses_lessons_student_details',
        component: () => import(/!* webpackChunkName: "my_teacher_courses_lessons_student_details" *!/ '../../views/soon.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([teacher, coordinator])],
            breadcrumbs: [
                { route: () => ({ name: 'profile' }), text: () => 'Mi perfil' },
                { route: () => ({ name: 'my_teacher_courses' }), text: () => 'Materias asignadas' },
                // { route: (route) => route, text: ({ store: { state } }) => `${state.lessons?.current?.course_section?.course?.title ?? 'Cargando...'}` },
                // { route: (route) => route, text: ({ store: { state } }) => `Tema: ${state.lessons?.current?.subject ?? 'Cargando...'}` },
            ]
        }
    },*/
    {
        path: '/access-denied',
        name: 'access-denied',
        component: () => import(/* webpackChunkName: "access-denied" */ '../../views/AccessDenied.vue')
    }
]
