<template>
  <svg viewBox="0 0 22 22">
    <g id="Linear" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="School,-Learning" transform="translate(-109.000000, -189.000000)">
        <g id="briefcase" transform="translate(108.000000, 188.000000)">
          <path d="M15.9999485,22 C20.0199485,22 20.7399493,20.3900003 20.9499493,18.4300003 L21.6999493,10.4300003 C21.9699493,7.99000025 21.2699485,6 16.9999485,6 L6.99994898,6 C2.729949,6 2.02994873,7.99000025 2.29994874,10.4300003 L3.04994875,18.4300003 C3.25994875,20.3900003 3.979949,22 7.99994898,22 L15.9999485,22 Z" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
          <path d="M8,6 L8,5.19999981 C8,3.42999983 8,2 11.1999998,2 L12.8000002,2 C16,2 16,3.42999983 16,5.19999981 L16,6" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
          <path d="M14,14 C14,14.01 14,14.0100005 14,14.0200005 C14,15.1100005 13.99,16 12,16 C10.02,16 10,15.1199998 10,14.0299997 L10,13 C10,12 10,12 11,12 L13,12 C14,12 14,12 14,13 L14,14 Z" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
          <path d="M21.6499996,11 C19.3399997,12.6799999 16.7,13.6800005 14,14.0200005" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
          <path d="M2.61999512,11.2700195 C4.86999512,12.8100195 7.40999532,13.7400188 9.99999523,14.0300188" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
          <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" id="Vector" opacity="0" transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) "></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "briefcase",
  data: () => ({})
}
</script>