import {Authenticated, AllowedForTypes} from '@/router/middlewares';

const base = {
    index: { url: () => ({ name: 'classhours' }), title: 'Listado de horas de clase'},
    create: { url: () => ({ name: 'create_classhour' }), title: 'Crear hora de clase'},
    edit: { url: ({route}) => route, title: 'Editar hora de clase' }
}


export default ({ admin, lowAdmin }) => [
	{
        path: '/classhours',
        name: 'classhours',
        component: () => import(/* webpackChunkName: "classhours" */ '../../views/classhours/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, lowAdmin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title }
            ]
        }
    },
    {
        path: '/classhours/create',
        name: 'create_classhour',
        component: () => import(/* webpackChunkName: "create_classhour" */ '../../views/classhours/create.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
                { route: base.create.url, text: () => base.create.title }
            ],
        }
    },
    {
        path: '/classhours/:id/edit',
        name: 'edit_classhour',
        component: () => import(/* webpackChunkName: "edit_classhour" */ '../../views/classhours/edit.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
                { route: ({route}) => route, text: ({ store }) => `${store.state.classhours.current?.id }`, loading: ({ store }) => store.state.classhours.loading },
                { route: base.edit.url, text: () => base.edit.title }
            ]
        }
    }
]
