import router from '@/router';
import Vue from 'vue';

const baseRouteName = 'preinscriptions';
const singleModelName = 'inscripción';

export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        all: [],
        current: null,
        loading: false
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET: (state, current) => state.current = current,
        SET_ALL: (state, all) => state.all = all,
        DROP: (state, index) => state.pagination.data.splice(index, 1),
        TOGGLE_LOADING: state => state.loading = !state.loading
    },
    actions: {
        fetchAll({ commit }, query = {}) {
            commit('TOGGLE_LOADING')
            return this.$repository.preinscriptions
                .index(query)
                .then(res => commit(query?.limit === 0 ? 'SET_ALL' : 'SET_PAGINATION', res.data))
                .then(() => query?.limit !== 0 && router.replace({ query }).catch(() => null))
                .finally(() => commit('TOGGLE_LOADING'))
        },
        fetch({ commit }, { id, query }) {
            commit('TOGGLE_LOADING')
            return this.$repository.preinscriptions
                .show(id, query)
                .then(({ data }) => {

                    if (!data.social_networks) {
                        data.social_networks = { instagrams: '', facebook: '', twitter: '' }
                    }

                    commit('SET', data)
                    return data
                }).finally(() => commit('TOGGLE_LOADING'))
        },
        store({ commit }, item) {
            commit('TOGGLE_LOADING')
            this.$repository.preinscriptions
                .store(item)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado la ${singleModelName} con éxito`,

                    })
                    router.push({ name: baseRouteName })
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        update({ commit }, item) {
            commit('TOGGLE_LOADING')

            this.$repository.preinscriptions
                .update(item.id, item)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha actualiado la ${singleModelName} con éxito`,

                    })

                    router.back()
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        delete({ commit, state }, id) {
            if (confirm(`¿Desea eliminar esta ${singleModelName}?`)) {
                this.$repository.preinscriptions
                    .delete(id)
                    .then(() => {
                        const index = state.pagination.data.findIndex(item => item.id === id)
                        commit('DROP', index)
                    })
            }
        },
        confirm({ dispatch }, id) {
            Vue.prototype.$confirm({
                title: '¿Aprobar?',
                message: `Esta acción require confirmación`,
                button: {
                    no: 'No',
                    yes: 'Si'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$repository.preinscriptions
                            .confirm(id)
                            .then(() => {
                                Vue.prototype.$message.success({
                                    message: `Se ha aprobado con éxito`,

                                })
                                dispatch('fetchAll', router.currentRoute.query)
                            })
                    }
                }
            })
        }
    }
}
