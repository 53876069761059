import VueConfirmDialog from 'vue-confirm-dialog'
import Tooltip from 'vue-directive-tooltip';
import verte from 'verte';
import VSelect from 'vue-select'

export default {
  install: (Vue) => {
    Vue.use(VueConfirmDialog)
    Vue.use(Tooltip, {
      delay: 0,
    })
    Vue.component('v-verte', verte)
    Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
    Vue.component('v-select', VSelect);
  }
}
