<template>
  <div class="bg-gray-50 dark:bg-gray-800 w-screen">
    <div class="flex h-screen overflow-x-hidden">
      <div class="flex-grow flex bg-gray-50">
        <aside class="bg-gray-800 py-1 px-2 fixed overflow-y-auto left-0 top-0 bottom-0 z-10" :class="[{
          'w-52 hidden md:block': showSidebar,
          'w-0 overflow-x-hidden': !showSidebar
          }, 'md:block transition-all']">
          <div class="space-y-1">
            <a-sidebar-logo>
              <span class="hidden md:inline-block lg:inline-block"></span>
              <span class="inline-block md:hidden lg:hidden text-white cursor-pointer p-2" @click="toggleSidebar">
                <a-icon class="flex-shrink-0" name="bars" />
              </span>
              <idc-logo color="#fff"/>
              <span class="inline-block md:hidden lg:hidden"></span>
              <span class="hidden md:inline-block lg:inline-block"></span>
            </a-sidebar-logo>
            <a-sidebar-item :to="{ name: 'home' }">
              <a-icon class="flex-shrink-0" name="home" />
              <span>Inicio</span>
            </a-sidebar-item>
            <a-sidebar-item :to="{ name: 'my_schedule' }" v-if="!user.isAdmin && !user.isLowAdmin">
              <a-icon class="flex-shrink-0" name="home" />
              <span>Mi horario</span>
            </a-sidebar-item>
            <a-sidebar-item :to="{ name: 'lessons.index' }" v-if="user.isTeacher || user.isCoordinator">
              <a-icon class="flex-shrink-0" name="home" />
              <span>Mis clases</span>
            </a-sidebar-item>
            <template v-for="(value, key) in sidebarSections">
              <template v-if="user.isAdmin || user.isLowAdmin">
                <a-sidebar-item :key="key" v-if="value.visible">
                  <b class="text-white">{{ value.title }}</b>
                  <template #menu>
                    <template v-for="(section, index) in value.items">
                      <a-sidebar-item :key="index" :to="section.to" v-if="section.visible">
                        {{ isCurrentRoute(section) }}
                        <a-icon class="flex-shrink-0" :name="section.icon" />
                        <span class="leading-normal relative -top-1">{{ section.label }}</span>
                      </a-sidebar-item>
                    </template>
                  </template>
                </a-sidebar-item>
              </template>
              <template v-else>
                <template v-for="(section, index) in value.items">
                  <a-sidebar-item :key="`${key}_${index}`" :to="section.to" v-if="section.visible">
                    <a-icon class="flex-shrink-0" :name="section.icon"/>
                    <span class="leading-normal relative -top-1">{{ section.label }}</span>
                  </a-sidebar-item>
                </template>
              </template>
            </template>
          </div>
        </aside>

        <div class="flex-col flex w-full items-stretch transition-all" :class="{'pl-0 md:pl-52': showSidebar }">
          <idc-header :class="{'left-0 md:left-52': showSidebar }" @logout="$emit('logout')" @notification="handleNotificationOpen" />
          <main class="bg-gray-50 pt-16 flex-grow flex dark:bg-gray-800 p-4 w-full max-w-full min-w-0">
            <router-view class="flex-grow flex flex-col justify-start py-4" />
          </main>
        </div>
      </div>
    </div>

    <a-modal :show="notification !== null" @cancel="notification = null" cancel-text="Cerrar">
      <template v-if="notification">
        <div class="flex flex-col space-y-4 divide-y w-full">
          <div class="flex flex-wrap space-x-4">
            <img v-if="notification.sender" class="w-24 ring-2 shadow-lg ring-primary h-24 rounded-full" :src="notification.sender.profile_picture" alt="">
            <div class="flex flex-col justify-between items-start">
              <div class="text-left">
                <h1 class="text-2xl" v-if="!notification.sender">SISTEMA</h1>
                <p class="text-lg" v-if="notification.sender">{{ notification.sender.name }}</p>
                <p class="text-gray-700" v-if="notification.sender">{{ notification.sender.email }}</p>
              </div>
              <div class="text-left">
                <p class="text-sm text-gray-600">
                  Recibido el {{ notification.created_at | date }}, leido
                  <template v-if="notification.pivot.read_at">
                    el {{ notification.pivot.read_at | date }}
                  </template>
                  <template v-else>
                    Ahora
                  </template>
                </p>
              </div>
            </div>
          </div>
          <div class="text-left p-2 w-full">
            <p class="text-justify">
              {{notification.content}}
            </p>
            <div v-if="notification.attachment" class="mt-4">
              <p class="block text-gray-500 text-sm">Archivo adjunto</p>
              <a class="text-blue-500" target="_blank" :href="notification.attachment">{{ notification.attachment.split('/').slice(-1).join() }}</a>
            </div>
          </div>
        </div>
      </template>
    </a-modal>

    <vue-confirm-dialog />

    <report-inscription-payment-snackbar
      v-if="user.isStudent"
      @check="checkStudentInscription"
      :inscription="user.student.latest_inscription" />
  </div>
</template>

<script>
import { Role } from '@/constants'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import IdcLogo from '@/components/ui/IDCLogo.vue';
import IdcHeader from './Header';
import ReportInscriptionPaymentSnackbar from "@/layouts/Authenticated/componnets/report-inscription-payment-snackbar";
export default {
  components: {
    ReportInscriptionPaymentSnackbar,
    IdcLogo,
    IdcHeader
  },
  data: () => ({
    shouldDisplayInscriptionPayment: false,
    roles: Role,
    notification: null,
    loadings: {
      inscription: false
    },
    modals: {
      inscriptionPayment: false
    },
    inscriptionPayment: {
      date: null,
      receipt_number: null,
      amount: null,
      type: ''
    }
  }),
  computed: {
    ...mapState({
      user: state => state.session.user,
      pagination: state => state.notifications.pagination,
      showSidebar: ({ showSidebar }) => showSidebar
    }),
    ...mapGetters({
      currentLang: 'currentLang',
      isAuthenticated: 'session/isAuthenticated',
      lang: 'lang',
      sidebarSections: 'sidebarSections',
      error: 'error'
    })
  },
  methods: {
    ...mapActions({
      translate: 'translate',
      logout: 'session/logout',
      configure: 'session/configure'
    }),
    ...mapMutations({
      toggleSidebar: 'TOGGLE_SIDEBAR',
    }),
    isCurrentRoute() {},
    checkStudentInscription(student) {
      this.configure({
        data: {
          access_token: localStorage.getItem(process.env.VUE_APP_USER_TOKEN_NAME),
          user: { ...this.user, student }
        }
      })
    },
    handleNotificationOpen({ notification, reload }) {
      this.notification = notification

      if (notification.pivot.read_at === null) {
        this.$repository.notifications
          .markAsRead(notification.id)
          .then(() => {
            reload()
          })
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.shouldDisplayInscriptionPayment = this.user.isStudent
    }, 200)
  },
}
</script>