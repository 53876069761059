import {Authenticated} from '../middlewares'

export default () => [
  {
    path: '/holidays',
    name: 'holidays',
    component: () => import(/* webpackChunkName: "holidays" */ '../../views/holidays/index.vue'),
    meta: {
        middleware: [Authenticated],
        breadcrumbs: [
            { route: ({ route }) => route, text: () => 'Días feriados' }
        ]
    }
  }
]