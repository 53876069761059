import router from '@/router';
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        all: [],
        payment: null,
        loading: false
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET: (state, current) => state.current = current,
        SET_ALL: (state, all) => state.all = all,
        DROP: (state, index) => state.pagination.data.splice(index, 1),
        TOGGLE_LOADING: (state) => state.loading = !state.loading
    },
    actions: {
        fetchAll({ commit }, query) {
            commit('TOGGLE_LOADING')
            this.$repository.payments
                .index(query)
                .then(res => commit(query?.limit === 0 ? 'SET_ALL' : 'SET_PAGINATION', res.data))
                .then(() => query?.limit !== 0 && router.replace({ query }).catch(() => null))
                .finally(() => commit('TOGGLE_LOADING'))
            },
        fetch({ commit, state }, id) {
            if (!state.payment) {
                this.$repository.payments
                    .show(id)
                    .then(res => {
                        commit('SET', res.data)
                        commit('SET_NAVBAR_TITLE', ['Listado de pagos', res.data.title, 'Editar pago'], { root: true })
                    })
                }
        },
        store({ commit }, payment) {
            commit('TOGGLE_LOADING')
            this.$repository.payments
                .store(payment)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado el pago con éxito`,
                        
                    })
                    router.push({ name: 'payments' })
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        update({ commit, dispatch }, payment) {
            commit('TOGGLE_LOADING')
            this.$repository.payments
                .update(payment.id, payment)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha actualizado el pago con éxito`,
                        
                    })
                    // router.push({ name: 'payments' })
                    dispatch('fetchAll', router.currentRoute.query)
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        changePaymentStatus({ dispatch }, {id, status}) {
            dispatch('update', { id, status })
        },
        delete({ commit, state }, id) {
            Vue.prototype.$confirm({
                message: `¿Desea eliminar este pago?`,
                button: {
                    no: 'No',
                    yes: 'Si'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$repository.payments
                            .delete(id)
                            .then(() => {
                                const index = state.pagination.data.findIndex(payment => payment.id === id)
                                commit('DROP', index)
                            })
                    }
                }
            })
        }
    }
}
