<template>
  <svg viewBox="0 0 22 22">
    <g id="Linear" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Essetional" transform="translate(-749.000000, -637.000000)">
        <g id="group" transform="translate(748.000000, 636.000000)">
          <path d="M9.1000061,2 C8.4200061,2 7.45999572,2.40000489 6.97999573,2.88000488 L2.88000488,6.98001099 C2.40000489,7.46001098 2,8.4200061 2,9.1000061 L2,14.8999939 C2,15.5799939 2.40000489,16.539989 2.88000488,17.019989 L6.97999573,21.1199951 C7.45999572,21.5999951 8.4200061,22 9.1000061,22 L14.8999939,22 C15.5799939,22 16.5400043,21.5999951 17.0200043,21.1199951 L21.1199951,17.019989 C21.5999951,16.539989 22,15.5799939 22,14.8999939 L22,9.1000061 C22,8.4200061 21.5999951,7.46001098 21.1199951,6.98001099 L17.0200043,2.88000488 C16.5400043,2.40000489 15.5799939,2 14.8999939,2 L9.1000061,2 Z" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
          <line x1="4.93994141" y1="19.0799255" x2="19.0799408" y2="4.93994141" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0"></line>
          <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" id="Vector" opacity="0"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ban",
  data: () => ({})
}
</script>