import {Authenticated, AllowedForTypes} from '@/router/middlewares';

const base = {
    index: { url: () => ({ name: 'specializations' }), title: 'Listado de especialidades'},
    create: { url: () => ({ name: 'create_specialization' }), title: 'Crear especialidad'},
    edit: { url: (route) => route, title: 'Editar especialidad' }
}

export default ({ admin, lowAdmin }) => [
	{
        path: '/specializations',
        name: 'specializations',
        component: () => import(/* webpackChunkName: "specializations" */ '../../views/specializations/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, lowAdmin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title }
            ]
        }
    },
    {
        path: '/specializations/create',
        name: 'create_specialization',
        component: () => import(/* webpackChunkName: "create_specialization" */ '../../views/specializations/create.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
                { route: base.create.url, text: () => base.create.title }
            ]
        }
    },
    {
        path: '/specializations/:id/edit',
        name: 'edit_specialization',
        component: () => import(/* webpackChunkName: "create_specialization" */ '../../views/specializations/edit.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
                { route: (route) => route, text: ({ store }) => `${store.state.specializations.specialization?.code}`, loading: ({ store }) => store.state.specializations.loading },
                { route: base.edit.url, text: () => base.edit.title }
            ]
        }
    }
]
