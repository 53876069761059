<template>
  <svg viewBox="0 0 21 22">
    <g id="Linear" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Time" transform="translate(-622.000000, -189.000000)">
        <g id="calendar-search" transform="translate(620.000000, 188.000000)">
          <line x1="8" y1="2" x2="8" y2="5" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0"></line>
          <line x1="16" y1="2" x2="16" y2="5" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0"></line>
          <g id="Group" transform="translate(15.000000, 15.000000)" class="stroke-current" stroke-dasharray="0,0" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
            <path d="M3.19999695,6.3999939 C1.43268573,6.3999939 0,4.96732342 0,3.20001221 C0,1.43270099 1.43268573,0 3.19999695,0 C4.96730816,0 6.3999939,1.43270099 6.3999939,3.20001221 C6.3999939,4.96732342 4.96730816,6.3999939 3.19999695,6.3999939 Z" id="Vector"></path>
            <line x1="7" y1="7" x2="6" y2="6" id="Vector"></line>
          </g>
          <line x1="3.5" y1="9.08996582" x2="20.5" y2="9.08996582" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0"></line>
          <path d="M13.3699951,22 L8,22 C4.5,22 3,20 3,17 L3,8.5 C3,5.5 4.5,3.5 8,3.5 L16,3.5 C19.5,3.5 21,5.5 21,8.5 L21,13" id="Vector" class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
          <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" id="Vector" opacity="0"></path>
          <line x1="11.9954839" y1="13.6999512" x2="12.0044658" y2="13.6999512" id="Vector" class="stroke-current" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0"></line>
          <line x1="8.29431202" y1="13.6999512" x2="8.30329396" y2="13.6999512" id="Vector" class="stroke-current" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0"></line>
          <line x1="8.29431202" y1="16.6999512" x2="8.30329396" y2="16.6999512" id="Vector" class="stroke-current" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0"></line>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "calendar-search",
  data: () => ({})
}
</script>