import router from '@/router';
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        all: [],
        current: null,
        loading: false,
        courses: []
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET: (state, current) => state.current = current,
        SET_ALL: (state, all) => state.all = all,
        DROP: (state, index) => state.pagination.data.splice(index, 1),
        TOGGLE_LOADING: (state) => state.loading = !state.loading,
        SET_COURSES: (state, courses) => state.courses = courses
    },
    actions: {
        index({ commit }, query) {
            commit('TOGGLE_LOADING')
            return this.$repository.students
                .index(query)
                .then(({ data }) => {
                    commit(query?.limit === 0 ? 'SET_ALL' : 'SET_PAGINATION', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        show({ commit }, { id, query= {} }) {
            commit('TOGGLE_LOADING')
            return this.$repository.students
                .show(id, query)
                .then(res => {
                    commit('SET', res.data)
                    return res
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        fetchStudentByCourseSection({ commit }, { course, section, student, filters }) {
            commit('TOGGLE_LOADING')
            return this.$repository.courseSections
                .forStudent(section, course, student, filters)
                .then(res => {
                    commit('SET', res.data)
                    return res
                }).finally(() => commit('TOGGLE_LOADING'))
        },
        store({ commit }, student) {
            const data = new FormData()
            Object.entries(student).forEach(entry => {
                if (entry[1] === true) {
                    entry[1] = 1
                }

                if (entry[1] === false) {
                    entry[1] = 0
                }
                data.append(entry[0], entry[1])
            })
            commit('TOGGLE_LOADING')
            this.$repository.students
                .store(data)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado el pago con éxito`,
                        
                    })
                    router.push({ name: 'students' })
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        update({ commit }, student) {
            commit('TOGGLE_LOADING')

            let data = {}

            Object.entries(student).forEach($0 => {
                if ($0[1]) {
                    data[$0[0]] = $0[1]
                }
            })

            this.$repository.students
                .update(student.id, data)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha actualizado el pago con éxito`,
                        
                    })
                    router.back()
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        delete({ commit, state }, id) {
            if (confirm('¿Desea eliminar este pago?')) {
                this.$repository.students
                    .delete(id)
                    .then(() => {
                        const index = state.pagination.data.findIndex(student => student.id === id)
                        commit('DROP', index)
                    })
            }
        },
        reInscript({ state }, item = null) {
            let student = item || state.current
            delete student.email
            this.$repository.students
                .inscription(student.id, student)
                .then(() => {
                    Vue.prototype.$message.success({
                        title: `Resinscripcion completada con éxito`,
                        message: `El estudiante ${student.first_name} ${student.last_name} ha sido reinscrito`,
                        
                    })
                })
        },
        fetchStudentBinnacles(_, query) {
            return this.$repository.students
                .binnacles(query.id, query.filters);
        },
        changeSection({ state, commit }, { student, section, context }) {
            const isPaginationContext = context === 'pagination'
            const isAllContext = context === 'all'
            const index = context === 'pagination' ? state.pagination.data.findIndex($0 => $0.id === student.id) : state.all.findIndex($0 => $0.id === student.id)

            if (index >= 0 && (isPaginationContext || isAllContext)) {
                Vue.prototype.$confirm({
                    message: `¿Desea cambiar a este estudiante de sección?`,
                    button: {
                        no: 'No',
                        yes: 'Si'
                    },
                    callback: confirm => {
                        if (confirm) {
                            this.$repository.students
                                .changeSection(student.id, section.id)
                                .then(() => {
                                    let item = context === 'pagination' ? state[context].data[index] : state[context][index]
                                    item.latest_inscription.section = section

                                    Vue.prototype.$message.success({
                                        title: `Cambio de seccion exitoso`,
                                        message: `El estudiante ${student.first_name} ${student.last_name} ha sido cambiado a la seccion ${section.letter_code}`,
                                        
                                    })

                                    commit('REPLACE_ITEM', { context, index, item })
                                })
                        }
                    }
                })
            }
        },
        fetchStudentCourses({ commit }, { id, filters }) {
            commit('TOGGLE_LOADING')
            return this.$repository.students
                .courses(id, filters)
                .then(res => {
                    commit('SET_COURSES', res.data)
                    return res
                })
                .finally(() => commit('TOGGLE_LOADING'))
        }
    }
}
