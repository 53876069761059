import {Authenticated, AllowedForTypes} from '@/router/middlewares';

const base = {
    index: { url: () => ({ name: 'final_deliveries' }), title: 'Listado de entregas finales'},
    create: { url: () => ({ name: 'create_final_delivery' }), title: 'Crear Entrega final'},
    edit: { url: (route) => route, title: 'Editar entrega final' }
}

export default ({ admin, coordinator, teacher, student, lowAdmin }) => [
	{
        path: '/final-deliveries',
        name: 'final_deliveries',
        component: () => import(/* webpackChunkName: "final_deliveries" */ '../../views/finalDeliveries/index.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, coordinator, student, lowAdmin, teacher])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
            ]
        }
    },
    {
        path: '/final-deliveries/create',
        name: 'create_final_delivery',
        component: () => import(/* webpackChunkName: "create_final_delivery" */ '../../views/finalDeliveries/create.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, coordinator])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
                { route: base.create.url, text: () => base.create.title }
            ]
        }
    },
    {
        path: '/final-deliveries/update/:delivery_id',
        name: 'update_final_delivery',
        component: () => import(/* webpackChunkName: "update_final_delivery" */ '../../views/finalDeliveries/update.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, coordinator])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
                { route: (route) => route, text: ({ store }) => `${store.state.finalDeliveries.current?.id}`, loading: ({ store }) => store.state.finalDeliveries.loading },
                { route: base.edit.url, text: () => base.edit.title }
            ]
        }
    },
    {
        path: '/final-deliveries/:delivery_id',
        name: 'details_final_delivery',
        component: () => import(/* webpackChunkName: "update_final_delivery" */ '../../views/finalDeliveries/details.vue'),
        meta: {
            middleware: [Authenticated, AllowedForTypes([admin, coordinator, student, lowAdmin, teacher])],
            breadcrumbs: [
                { route: base.index.url, text: () => base.index.title },
                { route: (route) => route, text: ({ store }) => `${store.state.finalDeliveries.current?.id}`, loading: ({ store }) => store.state.finalDeliveries.loading },
                { route: base.edit.url, text: () => base.edit.title }
            ]
        }
    }
]
