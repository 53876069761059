import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        all: [],
        current: null,
        loading: false
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET: (state, current) => state.current = current,
        SET_ALL: (state, sections) => state.all = sections,
        DROP: (state, index) => state.pagination.data.splice(index, 1),
        TOGGLE_LOADING: state => state.loading = !state.loading
    },
    actions: {
        index({ commit }, filters = {}) {
            commit('TOGGLE_LOADING')
            this.$repository.classhours
                .index(filters)
                .then(response => {
                    commit(filters?.limit === 0 ? 'SET_ALL' : 'SET_PAGINATION', response.data)
                    return response
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        show({ commit }, id) {
            commit('TOGGLE_LOADING')
            this.$repository.classhours
                .show(id)
                .then(response => {
                    commit('SET', {
                      ...response.data,
                      start_minute: response.data.start_minute <= 9 ? `0${response.data.start_minute}` : response.data.start_minute,
                      end_minute: response.data.end_minute <= 9 ? `0${response.data.end_minute}` : response.data.end_minute,
                    })
                    return response
                }).finally(() => commit('TOGGLE_LOADING'))
        },
        store({ commit }, item) {
            commit('TOGGLE_LOADING')

            Object.entries(item)
                .forEach(it => {
                    if (it[1] === '') {
                        delete item[it[0]]
                    }
                })

            return this.$repository.classhours
                .store(item)
                .then(response => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado la hora de clase con éxito`,
                        
                    })

                    return response
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        update({ commit }, item) {
            commit('TOGGLE_LOADING')
            Object.entries(item)
                .forEach(it => {
                    if (it[1] === '') {
                        delete item[it[0]]
                    }
                })

            return this.$repository.classhours
                .update(item.id, item)
                .then(response => {
                    Vue.prototype.$message.success({
                        message: `Se ha actualizado la hora de clase con éxito`,
                        
                    })

                    return response
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        delete({ commit, state }, id) {
            Vue.prototype.$confirm({
                message: `¿Desea eliminar esta hora de clase?`,
                button: {
                    no: 'No',
                    yes: 'Si'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$repository.classhours
                            .delete(id)
                            .then(() => {
                                const index = state.pagination.data.findIndex(item => item.id === id)
                                commit('DROP', index)
                            })
                    }
                }
            })
        }
    }
}
