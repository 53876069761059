import router from '@/router';
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        all: [],
        current: null,
        loading: false
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET: (state, current) => state.current = current,
        SET_ALL: (state, sections) => state.all = sections,
        DROP: (state, index) => state.pagination.data.splice(index, 1),
        TOGGLE_LOADING: state => state.loading = !state.loading
    },
    actions: {
        fetchAll({ commit }, filters = {}) {
            commit('TOGGLE_LOADING')
            this.$repository.prelations
                .index(filters)
                .then(res => commit(filters?.limit === 0 ? 'SET_ALL' : 'SET_PAGINATION', res.data))
                // .then(() => filters?.limit !== 0 && router.push({ name: 'prelations', query: { page: filters.page } }))
                .finally(() => commit('TOGGLE_LOADING'))
        },
        fetch({ commit }, { id, filters }) {
            commit('TOGGLE_LOADING')
            return this.$repository.prelations
                .show(id, filters)
                .then(res => {
                    commit(filters?.limit === 0 ? 'SET_ALL' : 'SET_PAGINATION', res.data)
                    return res
                })
                .catch(err => {
                    throw Error(err)
                }).finally(() => commit('TOGGLE_LOADING'))
        },
        store({ commit }, item) {
            commit('TOGGLE_LOADING')

            this.$repository.prelations
                .store(item)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado la prelación con éxito`,

                    })
                    router.push({ name: 'prelations' })
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        update({ commit }, item) {
            commit('TOGGLE_LOADING')

            this.$repository.prelations
                .update(item.id, item)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha actualizado la prelación con éxito`,

                    })
                    router.push({ name: 'prelations' })
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        delete(_, bulk) {
            console.log(bulk)
            return new Promise((resolve, reject) => {
                Vue.prototype.$confirm({
                    message: `¿Desea eliminar estas prelaciones?`,
                    button: {
                        no: 'No',
                        yes: 'Si'
                    },
                    callback: confirm => {
                        if (confirm) {
                            if (bulk.type === 'partial') {
                                this.$repository.prelations
                                    .bulkDelete(bulk)
                                    .then((res) => {
                                        resolve(res)
                                    }).catch(err => reject(err))
                            } else {
                                this.$repository.prelations
                                    .delete(bulk.id)
                                    .then((res) => {
                                        resolve(res)
                                    }).catch(err => reject(err))
                            }
                        }
                    }
                })
            })
        }
    }
}
