<template>
    <div class="py-1 w-full">
        <div class="flex align-top space-x-2 w-full">
            <img :src="notification.sender.profile_picture" alt="" class="rounded-full h-12 w-12 ring-2 ring-gray-100">
            <div class="flex flex-col items-start group w-full flex-grow">
                <div class="flex w-full justify-between mb-2">
                    <div class="text-left">
                        <h5>{{ notification.sender.name }}</h5>
                        <h6 class="text-sm text-gray-600">{{ notification.sender.email }}</h6>
                    </div>
                    <a-dropdown class="text-center">
                        <button class="w-8 h-8 rounded-full group-hover:bg-white focus:bg-white hover:bg-white border focus:outline-none focus:ring-2 focus:ring-primary">
                            <a-icon name="more" class="transform rotate-90" />
                        </button>
                        <template #menu>
                            <a-dropdown-item close-on-select @click.stop="markNotificationRead(notification.id)">
                                Marcar como leida
                            </a-dropdown-item>
                        </template>
                    </a-dropdown>
                </div>
                <p class="text-left line-clamp-2 text-justify">
                    {{ notification.content }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        notification: { required: false, type: Object, default: () => ({ sender: {}, content: '' }) }
    }
}
</script>