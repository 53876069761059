import axios from 'axios'
import { mapQueryString, withoutInvalidValues, toFormData } from './utils'
import { URL as baseURL } from '@/constants'

import activities from './repositories/activities'
import authentication from './repositories/authentication'
import binnacles from './repositories/binnacles'
import classhours from './repositories/classhours'
import classrooms from './repositories/classrooms'
import courses from './repositories/courses'
import courseSections from './repositories/courseSections'
import documentRequests from './repositories/documentRequests'
import documentRequestsTypes from './repositories/documentRequestsTypes'
import documents from './repositories/documents'
import finalDeliveries from './repositories/finalDeliveries'
import holidays from './repositories/holidays'
import inscriptions from './repositories/inscriptions'
import lessons from './repositories/lessons'
import notifications from './repositories/notifications'
import payments from './repositories/payments'
import pensums from './repositories/pensums'
import periods from './repositories/periods'
import preinscriptions from './repositories/preinscriptions'
import prelations from './repositories/prelations'
import schedules from './repositories/schedules'
import sections from './repositories/sections'
import semesters from './repositories/semesters'
import specializations from './repositories/specializations'
import students from './repositories/students'
import teachers from './repositories/teachers'
import users from './repositories/users'

const context = { axios, mapQueryString, withoutInvalidValues, toFormData, baseURL }

const repository = Object.entries({
  activities,
  authentication,
  binnacles,
  classhours,
  classrooms,
  courses,
  courseSections,
  documentRequests,
  documentRequestsTypes,
  documents,
  finalDeliveries,
  holidays,
  inscriptions,
  lessons,
  notifications,
  payments,
  pensums,
  periods,
  preinscriptions,
  prelations,
  schedules,
  sections,
  semesters,
  specializations,
  students,
  teachers,
  users,
})
.reduce((accumulator, [name, configuration]) => ({
  ...accumulator,
  [name]: configuration(context)
}), {})

export default {
  install: (Vue, options = {}) => {
    const repositoryUtils = { mapQueryString, withoutInvalidValues, toFormData }
    Vue.prototype.$repository = repository
    Vue.prototype.$repositoryUtils = repositoryUtils

    if (options.store) {
      options.store.$repository = repository
      options.store.$repositoryUtils = repositoryUtils
    }
  }
}
