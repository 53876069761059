export const withoutInvalidValues = body => {

    Object.entries(body)
        .forEach($0 => {
            const value = $0[1], attribute = $0[0]

            if (value === '' || value === undefined || value === null) {
                delete body[$0[0]]

            } else if (typeof value === 'object') {

                if (!Array.isArray(value)) {
                    withoutInvalidValues(value)
                } else {
                    if (!value.length) {
                        delete body[attribute]
                    }
                }
            }
        })

    return body
}

export const toFormData = body => {
    let data = new FormData()
    Object.entries(body)
        .forEach($0 => {
            if (Array.isArray($0[1])) return $0[1].forEach(($1, key) => data.append(`${$0[0]}[${key}]`, $1))

            if ($0[1] === true) return data.append($0[0], 1)

            if ($0[1] === false) return data.append($0[0], 0)

            // if (Object.isObject($0[1])) return toFormData($0[1]) // EXPERIMENTAL

            data.append($0[0], $0[1])
        })

    return data
}

export const mapQueryString = filters => {
  // console.log(encodeURI('aaa bbb ccc sss ddd'))

    return Object.entries(filters)
        .filter($0 => $0[1] !== '')
        .map(($0, index) => {
          return `${index === 0 ? '?' : '&'}${$0[0]}=${$0[1]}`
              // .replaceAll(/\s+/gm, '%20')
              // .replaceAll(/\s+/gm, '') // removes all new lines, tabs and spaces
              // .trim()
        }).join('')
}