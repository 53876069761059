import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        all: [],
        current: null,
        loading: false
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET: (state, current) => state.current = current,
        SET_ALL: (state, sections) => state.all = sections,
        DROP: (state, index) => state.pagination.data.splice(index, 1),
        TOGGLE_LOADING: state => state.loading = !state.loading
    },
    actions: {
        index({ commit }, query = {}) {
            commit('TOGGLE_LOADING')
            return this.$repository.documents
                .index(query)
                .then(({ data }) => {
                    commit(query?.limit === 0 ? 'SET_ALL' : 'SET_PAGINATION', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        show({ commit }, { id, query }) {
            commit('TOGGLE_LOADING')
            return this.$repository.documents
                .show(id, query)
                .then(({ data }) => {
                    commit('SET', data)
                    return data
                }).finally(() => commit('TOGGLE_LOADING'))
        },
        store({ commit }, item) {
            commit('TOGGLE_LOADING')
            let data = new FormData()
            Object.entries(item).forEach(it => data.append(it[0], it[1]))
            return this.$repository.documents
                .store(data)
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        title: 'Éxito',
                        message: `El documento ha sido creado`,
                    })

                    return data
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        update({ commit }, item) {
            commit('TOGGLE_LOADING')
            return this.$repository.documents
                .update(item.id, item)
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        title: 'Éxito',
                        message: `El documento ha sido actualizado`,
                    })

                    return data
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        delete({ commit, state }, id) {
            Vue.prototype.$confirm({
                message: `¿Desea eliminar este documento?`,
                button: {
                    no: 'No',
                    yes: 'Si'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$repository.documents
                            .delete(id)
                            .then(() => {
                                const index = state.pagination.data.findIndex(item => item.id === id)
                                commit('DROP', index)
                            })
                    }
                }
            })
        }
    }
}
