<template>
  <component ref="icon" :is="name" :class="className" />
</template>

<script>
import bell from './icons/bell'
import home from './icons/home'
import arrowDown from './icons/arrow-down'
import user from './icons/user'
import userShield from './icons/user-shield'
import briefcase from "./icons/briefcase"
import teacher from "./icons/teacher"
import grid from './icons/grid'
import book from './icons/book'
import bookmark from './icons/bookmark'
import calendar from './icons/calendar'
import calendarSearch from './icons/calendar-search'
import award from './icons/award'
import arrowUpDouble from './icons/arrow-up-double'
import clock from './icons/clock'
import bars from './icons/bars'
import note from './icons/note'
import books from './icons/books'
import creditCardsExchange from './icons/credit-cards-exchange'
import tasksSquare from './icons/tasks-square'
import timer from './icons/timer'
import ban from './icons/ban'
import rowHorizontal from './icons/row-horizontal'
import note2 from './icons/note-2'
import more from './icons/more'
import check from './icons/check'
import plus from './icons/plus'
import eye from './icons/eye'
import camera from './icons/camera'
import paintRoller from './icons/paint-roller'
import eyeSlash from './icons/eye-slash'
import search from './icons/search'
import refresh from './icons/refresh'
import trash from './icons/trash'
import upload from './icons/upload'
import exchange from './icons/exchange'
import edit from './icons/edit'
import font from './icons/font'
import facebook from './icons/facebook'
import twitter from './icons/twitter'
import instagram from './icons/instagram'
import star from './icons/star'

export default {
  components: {
    star,
    instagram,
    twitter,
    bell,
    home,
    arrowDown,
    user,
    userShield,
    briefcase,
    teacher,
    grid,
    book,
    bookmark,
    calendar,
    calendarSearch,
    award,
    arrowUpDouble,
    clock,
    bars,
    note,
    books,
    creditCardsExchange,
    tasksSquare,
    timer,
    ban,
    rowHorizontal,
    note2,
    more,
    check,
    plus,
    eye,
    eyeSlash,
    camera,
    paintRoller,
    search,
    refresh,
    trash,
    upload,
    exchange,
    edit,
    font,
    facebook
  },
  props: {
    name: { required: true, type: String }
  },
  computed: {
    className() {
      const classes = ['h-4', 'w-4']
      // const hasWidth = this.class.split(' ').some(css => {
      //   console.log(css)
      //   return css.startsWith('w-')
      // })
      // const hasHeight = this.class.split(' ').some(css => css.startsWith('h-'))
      //
      // if (hasWidth) {
      //   classes.splice(classes.indexOf('w-4'), 1)
      // }
      //
      // if (hasHeight) {
      //   classes.splice(classes.indexOf('h-4'), 1)
      // }

      return [...classes, this.class].join(' ')
    }
  }
}
</script>