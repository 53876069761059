<template>
  <div>
    <template v-if="canAccess">
      <slot />
    </template>
    <slot v-else name="fallback">
      <component :is="fallbackComponent" v-if="fallbackComponent" />
      <a-modal show v-else title="Acceso denegado" hide-top-cancel>
        <div class="space-y-4">
          <h1 class="text-2xl text-gray-800">
            No puedes acceder a esta vista
          </h1>
          <a-button @click="$router.back()">
            Regresar
          </a-button>
        </div>
      </a-modal>
    </slot>
  </div>
</template>

<script>
import { Role } from '@/constants'
import { mapState } from 'vuex'
export default {
  props: {
    roles: {
      required: false,
      type: Function,
      /**
       *
       * @param {Role} _
       * @returns {*[]}
       */
      default: (_) => [] // eslint-disable-line no-unused-vars
    },
    fallbackComponent: {
      required: false,
      type: String,
      default: null
    }
  },
  data: () => ({}),
  computed: {
    ...mapState({
      user: ({ session }) => session.user
    }),
    canAccess() {
      const roles = this.roles(Role)

      if (roles.length === 0) {
        return true
      }

      return roles.some(role => role === this.user.type)
    }
  },
  mounted () {
      this.$emit(this.canAccess ? 'mounted' : 'fallback-mounted')
  }
}
</script>