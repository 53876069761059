<template>
    <div class="flex justify-center p-4">
        <slot />
    </div>
</template>

<script>
import { prefix } from '@/components/utils';
export default {
    name: `sidebar-logo`,
    props: {},
    data: () => ({
        prefix
    })
}
</script>