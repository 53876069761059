import router from '@/router';
import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        all: [],
        semester: null,
        loading: false
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET_SEMESTER: (state, semester) => state.semester = semester,
        SET_SEMESTERS: (state, sections) => state.all = sections,
        DROP_SEMESTER: (state, index) => state.pagination.data.splice(index, 1),
        TOGGLE_LOADING: state => state.loading = !state.loading
    },
    actions: {
        fetchSemesters({ commit }, query) {
            commit('TOGGLE_LOADING')
            this.$repository.semesters
                .index(query)
                .then(res => commit(query?.limit === 0 ? 'SET_SEMESTERS' : 'SET_PAGINATION', res.data))
                .then(() => query?.limit !== 0 && router.replace({ query }).catch(() => null))
                .finally(() => commit('TOGGLE_LOADING'))
            },
        fetchSemester({ commit, state }, id) {
            if (!state.semester) {
                this.$repository.semesters
                    .show(id)
                    .then(res => {
                        commit('SET_SEMESTER', res.data)
                        commit('SET_NAVBAR_TITLE', ['Listado de semestres', res.data.code, 'Editar semestre'], { root: true })
                    })
                }
        },
        store({ commit }, {semester, completion}) {
            commit('TOGGLE_LOADING')
            this.$repository.semesters
                .store(semester)
                .then((res) => completion(res.data.id, res.data))
                .finally(() => commit('TOGGLE_LOADING'))
        },
        update({ commit }, semester) {
            commit('TOGGLE_LOADING')
            this.$repository.semesters
                .update(semester.id, {...semester, code: 'a'})
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado el semestre con éxito`
                    })
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        updateSemesterCourses(_, semester) {
            this.$repository.semesters
                .updateCourses(semester.id, semester.courses_ids)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: 'Materias actualizadas con éxito'
                    })
                })
        },
        delete({ commit, state }, id) {
            if (confirm('¿Desea eliminar este semestre?')) {
                this.$repository.semesters
                    .delete(id)
                    .then(() => {
                        const index = state.pagination.data.findIndex(semester => semester.id === id)
                        commit('DROP_SEMESTER', index)
                    })
            }
        }
    },
    getters: {
        semestersBySpecialization: state => (specialization = null) => {
            return state.all
                .filter(it => it.specialization_id === specialization)
        }
    }
}
