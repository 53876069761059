<template>
    <div :class="[`sim-divider`, { 'is-vertical': vertical }]"></div>
</template>

<script>
export default {
    name: `divider`,
    props: {
        vertical: { required: false, type: Boolean, default: false }
    }
}
</script>